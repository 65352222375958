import React, { useEffect, useState, useCallback, useRef } from 'react';

import { Tree } from 'antd';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import { loadMenuAsync, loadArticleAsync } from 'src/app/api/resources';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';

import styles from './Resources.module.css';

export const Resources = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { articleId } = useParams();
  const { hash } = useLocation();

  const [selectedKey, setSelectedKey] = useState(['0-0']);
  const [expandedKeys, setExpandedKeys] = useState(['0-0']);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [menu, setMenu] = useState();
  const [currentArticle, setCurrentArticle] = useState({
    id: '',
    text: ''
  });
  const articleContainerRef = useRef();
  useEffect(() => {
    if (menu && hash) {
      const ahash = hash.split('#')[1];
      const el = menu[0].children.find((el) => el.anchor === ahash);
      const treeMenu = document.querySelector(`[role='tree']`);
      const menuElement = document.querySelector(`[title='${el.title}']`);
      setSelectedKey([el.key]);
      if (menuElement) {
        const headerOffset = 105;
        const elementPosition = menuElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition - headerOffset;
        treeMenu.scrollTo({
          top: offsetPosition
        });
      } else {
        treeMenu.scrollTo({
          top: 0
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);

  useEffect(() => {
    dispatch(loadMenuAsync())
      .then((menuItems) => {
        setMenu(completeMenu(menuItems));
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const completeMenu = useCallback((menuItems, j = 0) => {
    return menuItems.map((menuItem, i) => ({
      children: menuItem.articles ? menuItem.articles && completeMenu(menuItem.articles, `${j}-${i}`) : null,
      articleId: menuItem.link.split('#')[0].split('/')[2],
      anchor: menuItem.link.split('#')[1] || '',
      link: menuItem.link,
      title: menuItem.articles ? <span onClick={() => expandNode(`${j}-${i}`)}>{menuItem.title}</span> : menuItem.title,
      key: `${j}-${i}`
    }));
  }, []);

  const expandNode = (key) => {
    setAutoExpandParent((prev) => !prev);
    setExpandedKeys((prev) => {
      const outArr = [];
      if (prev.includes(key)) {
        for (let i = 0; i < prev.length; i++) {
          if (prev[i] !== key) {
            outArr.push(prev[i]);
          }
        }
        return outArr;
      } else {
        prev.push(key);
        return prev;
      }
    });
  };

  useEffect(() => {
    if (!articleId) {
      return;
    }

    if (articleId === currentArticle.id) {
      scrollToAnchor(hash.slice(1));
      return;
    }

    dispatch(loadArticleAsync(articleId))
      .then((article) => {
        setCurrentArticle({ id: articleId, text: article });

        if (hash) {
          setTimeout(() => {
            scrollToAnchor(hash.slice(1));
          });
        }
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId, hash]);

  const clickToAnchor = useCallback(
    (key, event) => {
      setSelectedKey(key);
      if (articleId === event.node.articleId) {
        navigate(`#${event.node.anchor}`);
      } else {
        navigate(`/${event.node.link}`);
      }
      const targetElement = document.getElementsByName(event.node.anchor)[0];
      if (targetElement) {
        const headerOffset = 100;
        const elementPosition = targetElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - headerOffset;
        window.scrollTo({
          top: offsetPosition
        });
      }
    },
    [navigate, articleId]
  );

  const scrollToAnchor = useCallback((anchor) => {
    const targetElement = document.getElementsByName(anchor)[0];

    if (targetElement) {
      const headerOffset = 100;
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;
      window.scrollTo({
        top: offsetPosition
      });
    }
  }, []);

  const onLinkClick = useCallback(
    (event) => {
      const name = event.target.innerText;
      event.preventDefault();
      const anchor = event.target.href?.split('#')[1];
      const menuElement = document.querySelector(`[title='${name}']`);
      const positionMenu = document.querySelector(`[role='tree']`).scrollTop;
      const treeMenu = document.querySelector(`[role='tree']`);
      const el = menu[0].children.find((el) => el.title === name);
      if (el) {
        setSelectedKey([el.key]);
      } else {
        setSelectedKey([]);
      }
      if (`#${anchor}` === hash) {
        scrollToAnchor(anchor);
      } else {
        navigate(`#${anchor}`);
      }
      if (menuElement) {
        const headerOffset = 120;
        const elementPosition = menuElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + positionMenu - headerOffset;
        treeMenu.scrollTo({
          top: offsetPosition
        });
      } else {
        treeMenu.scrollTo({
          top: 0
        });
      }
    },
    [hash, menu, navigate, scrollToAnchor]
  );

  useEffect(() => {
    const links = articleContainerRef.current?.querySelectorAll('a');

    links.forEach((link) => {
      link.addEventListener('click', onLinkClick);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener('click', onLinkClick);
      });
    };
  }, [currentArticle.text, onLinkClick]);

  return (
    <div className={styles.page}>
      <div className={styles.menu}>
        {menu && menu.length > 0 && (
          <Tree
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            className={styles.treeData}
            defaultExpandAll={true}
            selectedKeys={selectedKey}
            treeData={menu}
            onSelect={clickToAnchor}
          />
        )}
      </div>
      <div
        className={styles.items}
        ref={articleContainerRef}
        dangerouslySetInnerHTML={{ __html: currentArticle.text }}
      ></div>
    </div>
  );
};
