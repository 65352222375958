export class NotificationModel {
  id = '';
  text = '';
  creator = '';
  createdAt = '';
  membersAmount = 0;

  constructor(data) {
    if (data) {
      this.id = data.id || '';
      this.text = data.text || '';
      this.creator = data.creator || '';
      this.createdAt = data.createdAt || '';
      this.membersAmount = data.membersAmount || 0;
    }
  }
}
