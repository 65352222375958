export const getProductOrder = (productName) => {
  switch (productName.toLowerCase()) {
    case 'full':
      return 1;
    case 'standard':
      return 2;
    case 'free':
      return 3;
    default:
      return 4;
  }
};
