import React, { useState, useEffect } from 'react';

import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AppInput from 'src/app/components/form/input';
import { AppInputPassword } from 'src/app/components/form/input-password';
import { selectIsShowErrorMessageAuth } from 'src/app/slices/authSlice';
import { ReactComponent as FacebookSVG } from 'src/assets/svg/header/facebook.svg';
import { ReactComponent as GoogleSVG } from 'src/assets/svg/header/google.svg';

import styles from './SignInForm.module.css';

export const SignInForm = ({ onSubmit, needResetFields = false }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [disabledSave, setDisabledSave] = useState(false);
  const signinInitialValues = {
    email: '',
    password: ''
  };
  const errorMessageAuth = useSelector(selectIsShowErrorMessageAuth);

  useEffect(() => {
    if (needResetFields) {
      form.resetFields();
    }
  }, [form, needResetFields]);

  useEffect(() => {
    if (errorMessageAuth) {
      form.setFields([
        {
          name: 'email',
          value: form.getFieldValue('email')
        },
        {
          name: 'password',
          value: ''
        }
      ]);
      setDisabledSave(false);
    }
  }, [errorMessageAuth, form]);

  const handleFormChange = () => {
    const hasErrors =
      !form.getFieldError('email').length &&
      !!form.getFieldValue('email') &&
      !form.getFieldError('password').length &&
      !!form.getFieldValue('password');
    setDisabledSave(hasErrors);
  };

  return (
    <Form
      name="signin"
      form={form}
      initialValues={signinInitialValues}
      onFinish={onSubmit}
      autoComplete="off"
      onFieldsChange={handleFormChange}
      className={styles.form}
    >
      <Form.Item
        className={styles.formItem}
        name="email"
        rules={[
          {
            type: 'email',
            message: t('invalid_input')
          },
          {
            required: true,
            message: t('required_input')
          }
        ]}
      >
        <AppInput data-test="signin_email" type="email" placeholder={t('email')}></AppInput>
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name="password"
        rules={[
          {
            required: true,
            message: t('required_input')
          }
        ]}
      >
        <AppInputPassword data-test="signin_password" placeholder={t('password')} />
      </Form.Item>
      <Form.Item className={styles.formItem}>
        <Button data-test="header_signin_enter" htmlType="submit" className="mg-btn" disabled={!disabledSave}>
          {t('enter')}
        </Button>
      </Form.Item>
      <div className={styles.buttonsWrap}>
        <span>{t('login_social_networks')}</span>
        <a
          data-test="header_google_login"
          href={process.env.REACT_APP_API_ENDPOINT + '/account/oauth/google'}
          rel="noreferrer"
          className={styles.googleLink}
        >
          <GoogleSVG />
        </a>
        <a
          data-test="header_facebook_login"
          href={process.env.REACT_APP_API_ENDPOINT + '/account/oauth/facebook'}
          rel="noreferrer"
          className={styles.facebookLink}
        >
          <FacebookSVG />
        </a>
      </div>
    </Form>
  );
};
