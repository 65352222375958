import React from 'react';

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { formatString } from 'src/app/helpers/strings';

const link = '/account/subscription';

/**
 * @param {object} props
 * @param {boolean} props.isVisible
 * @param {() => void} props.setInvisible
 * @param {Errors.RequestError} props.e
 * @returns {JSX.Element}
 */

// linkText: 'choose_another_subscription'
export const ErrorModal = ({ isVisible, setInvisible, e }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const text = formatString(t(e.message), e.data);

  let title = t('error_modal_title');
  let navigateBack = true;
  let linkText;
  if (
    e?.message &&
    (e.message.includes('restriction') ||
      e.message.includes('Wiki') ||
      e.message.includes('tax') ||
      e.message.includes('FinancialController_saveForm_forbiden'))
  ) {
    if (e.message.includes('restriction_limit_members_reached')) {
      title = t('restriction_modal_title');
      navigateBack = false;
    } else if (e.message.includes('tax_regime_exists')) {
      title = t('tax_modal_title');
      navigateBack = false;
    } else {
      title = t('restriction_modal_title');
      linkText = t('choose_another_subscription');
      navigateBack = false;
    }
  }
  if (e?.data?.fatal === false) {
    navigateBack = false;
  }
  if (e?.data?.fatal === true) {
    navigateBack = true;
  }
  return (
    <Modal
      data-test="error-modal"
      title={t(title)}
      centered
      visible={isVisible}
      forceRender
      closable={false}
      className={`mg-modal _info`}
      okButtonProps={{ className: `mg-btn`, style: { display: undefined } }}
      cancelButtonProps={{
        style: {
          display: 'none'
        }
      }}
      // onCancel={() => {
      //   setInvisible();
      // }}
      onOk={() => {
        if (navigateBack === true) {
          navigate(-1);
        }
        setInvisible();
      }}
    >
      <span>{text}</span>
      {linkText && <Link to={link}>{linkText}</Link>}
    </Modal>
  );
};
