import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Divider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../SearchColumnModal.module.css';
import { SearchForm } from '../searchForm';
import { SearchResults } from '../searchResults';
import { searchIndustryAsync } from 'src/app/api/screen';
import { selectIndustry, setIndustry } from 'src/app/slices/screeningSlice';

export const SearchIndustry = ({ visible, onSelectValues, selectedValues }) => {
  const dispatch = useDispatch();
  const [results, setResults] = useState();
  const [search, setSearch] = useState();
  const { t } = useTranslation();
  const industryNames = useSelector(selectIndustry);

  const selectedResults = useRef();

  useEffect(() => {
    selectedResults.current = selectedValues !== undefined ? selectedValues : [];
  }, [selectedValues]);

  const handleSearch = useCallback(
    (values) => {
      if (industryNames.length === 0) {
        dispatch(searchIndustryAsync(values)).then((res) => {
          dispatch(setIndustry(res));
          const preparedSearchResults = res.map((ind) => {
            if (selectedResults.current?.find((r) => r === ind.value)) {
              return {
                ...ind,
                key: ind.value,
                checked: true
              };
            }
            return {
              ...ind,
              key: ind.value,
              checked: false
            };
          });
          preparedSearchResults.sort((a, b) => {
            return b.checked - a.checked;
          });
          setResults(preparedSearchResults);
        });
      } else if (values !== '') {
        dispatch(searchIndustryAsync(values)).then((res) => {
          const preparedSearchResults = res.map((ind) => {
            if (selectedResults.current?.find((r) => r === ind.value)) {
              return {
                ...ind,
                key: ind.value,
                checked: true
              };
            }
            return {
              ...ind,
              key: ind.value,
              checked: false
            };
          });
          preparedSearchResults.sort((a, b) => {
            return b.checked - a.checked;
          });
          setResults(preparedSearchResults);
        });
      } else {
        const preparedSearchResults = industryNames.map((ind) => {
          if (selectedResults.current?.find((r) => r === ind.value)) {
            return {
              ...ind,
              key: ind.value,
              checked: true
            };
          }
          return {
            ...ind,
            key: ind.value,
            checked: false
          };
        });
        preparedSearchResults.sort((a, b) => {
          return b.checked - a.checked;
        });
        setResults(preparedSearchResults);
      }
    },
    [dispatch, industryNames]
  );

  const handleChangeSearch = useCallback((val) => {
    setSearch(val);
  }, []);

  const handleOk = () => {
    setSearch('');
    setResults([]);
    onSelectValues(selectedResults.current);
  };

  const handleCancel = () => {
    setSearch('');
    setResults([]);
    onSelectValues(null);
  };

  const handleChange = (element) => {
    setResults((prev) =>
      prev.map((el) => {
        if (el.key === element.key) {
          return { ...el, checked: element.checked };
        }
        return el;
      })
    );

    if (element.checked) {
      selectedResults.current.push(element.value);
    } else {
      const r = selectedResults.current.filter((res) => res !== element.value);
      selectedResults.current = r;
    }
  };

  const handleClick = useCallback(() => {
    setSearch('');
    setResults([]);
    onSelectValues([]);
  }, [onSelectValues]);

  useEffect(() => {
    if (visible) {
      handleSearch('');
    }
  }, [handleSearch, visible]);

  return (
    <Modal
      title={''}
      centered
      width={800}
      visible={visible}
      forceRender
      className={`mg-modal`}
      okText={t('add')}
      cancelText={t('Cancel')}
      getContainer={'main'}
      okButtonProps={{
        className: `mg-btn ${styles.button}`,
        'data-test': 'custom_screen_page_search_column_modal_add'
      }}
      cancelButtonProps={{
        className: `mg-btn _green-border ${styles.button}`,
        'data-test': 'custom_screen_page_search_column_modal_cancel'
      }}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p className={styles.titleModal}>{t('table_search_industry_title')}</p>
      <Divider className={styles.divider} />
      <SearchForm onSearch={handleSearch} value={search} onChange={handleChangeSearch} />
      <p className={styles.clearButton} onClick={handleClick}>
        Clear selected industries
      </p>
      <SearchResults results={results} onChange={handleChange} />
    </Modal>
  );
};
