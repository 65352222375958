import { axiosGet, axiosErrors, axiosPut, axiosPost, axiosDelete } from '../helpers/axios';
import { setIsShowErrorMessageProducts } from '../slices/productSlice';

export const loadProductsAsync = (active) => (dispatch) => {
  const loadProducts = axiosGet(`payment/products${active ? `?where[active]=${active}` : ''}`, {
    isNeedSpinner: false
  });
  return loadProducts()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const updateProductAsync = (id, data) => (dispatch) => {
  const updateProduct = axiosPut(`payment/products/${id}`);
  return updateProduct(data)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const createProductAsync = (newProduct) => (dispatch) => {
  const createProduct = axiosPost('payment/products');
  return createProduct(newProduct)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const deleteProductAsync = (id) => (dispatch) => {
  const deleteProduct = axiosDelete(`payment/products/${id}`);
  return deleteProduct()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

const generateSortString = (sort) => {
  return sort.reduce((result, next, index) => {
    return (result += `&order[${index}]=${next.column}&order[${index}]=${next.direction}`);
  }, '');
};

export const loadProductPricesAsync =
  (productId, active, scope, index, limit, queryTemplate, filter, sort) => (dispatch) => {
    const loadProductPrices = axiosGet(
      `payment/prices?scope=${scope}&index=${index || 0}&limit=${limit || 10}${
        productId ? `&where[productId]=${productId}` : ''
      }${filter ? `&where[active]=${filter === 'active'}` : ''}${
        queryTemplate ? `&where[name][_iLike]=%${queryTemplate}%` : ''
      }${sort ? generateSortString(sort) : ''}${active ? `&where[active]=${active}` : ''}`,
      {
        isNeedSpinner: false
      }
    );
    return loadProductPrices()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
        throw axiosErrors(e);
      });
  };

export const loadPriceInfoAsync = (priceId) => (dispatch) => {
  const loadPriceInfo = axiosGet(`payment/prices/${priceId}`);
  return loadPriceInfo()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const loadPriceSubscribersAsync = (priceId, search) => (dispatch) => {
  const loadPriceSubscribers = axiosGet(
    `payment/subscriptions?scope=defaultScope${priceId ? `&where[priceId]=${priceId}` : ''}${
      search ? `&where[$customer.account.email$][_iLike]=%${search}%` : ''
    }`
  );
  return loadPriceSubscribers()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const createProductPriceAsync = (id, data) => (dispatch) => {
  const createProductPrice = axiosPost(`payment/products/${id}/prices`);
  return createProductPrice(data)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const updateProductPriceAsync = (id, data) => (dispatch) => {
  const updateProductPrice = axiosPut(`payment/prices/${id}`);
  return updateProductPrice(data)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const subscribeToPriceAsync = (id, couponId) => (dispatch) => {
  const subscribeToPrice = axiosPost(`payment/prices/${id}/subscribe`);
  if (couponId) {
    return subscribeToPrice({ couponId })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
        throw axiosErrors(e);
      });
  } else {
    return subscribeToPrice()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
        throw axiosErrors(e);
      });
  }
};

export const updateSubscriptionAsync = (priceId, cancelAtPeriodEnd) => (dispatch) => {
  const updateSubscription = axiosPut(`payment/subscription`);
  return updateSubscription({ priceId, cancelAtPeriodEnd })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const cancelSubscriptionAsync = (id) => (dispatch) => {
  const cancelSubscription = axiosDelete(`payment/subscriptions/${id}`);
  return cancelSubscription()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProducts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};
