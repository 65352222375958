import React, { useCallback, useEffect, useState } from 'react';

import { Radio } from 'antd';
import { useSelector } from 'react-redux';

import { AppRadio } from 'src/app/components/form/radio';
import { selectProfile } from 'src/app/slices/profileSlice';

import styles from './PeriodSubscription.module.css';

const getPricePerMonthWithCurrency = (price, currency, interval, intervalCount) => {
  const countOfMonths = interval === 'year' ? intervalCount * 12 : intervalCount;
  const resultPrice = Math.ceil(price / countOfMonths) / 100;

  switch (currency) {
    case 'eur':
      return `€ ${resultPrice} / month`;
    case 'usd':
      return `$ ${resultPrice} / month`;
  }
};

export const ListOfPrices = ({ productPrices, onChange }) => {
  const [prices, setPrices] = useState(productPrices);

  const profile = useSelector(selectProfile);

  useEffect(() => {
    setPrices(productPrices);
  }, [productPrices]);

  const handleChange = useCallback(
    (selectedPrice, checked) => {
      const result = prices.map((el) => {
        if (el.id === selectedPrice) {
          return { ...el, isSelected: checked };
        } else {
          return { ...el, isSelected: false };
        }
      });
      setPrices(result);
      onChange(result);
    },
    [onChange, prices]
  );

  return (
    <Radio.Group
      onChange={(e) => handleChange(e.target.value, e.target.checked)}
      value={
        prices.find((el) => el.isSelected)?.id ||
        prices.find((el) => el.id === profile?.customer?.subscription?.priceId)?.id
      }
    >
      {prices.map((price) => {
        return (
          <div key={price.id} className={styles.priceItem} onClick={() => handleChange(price.id, !price.isSelected)}>
            <div className={styles.priceDescription}>
              <span className={styles.pricePaidPeriod}>{price.paidPeriod}</span>
              <span className={styles.priceCurrency}>
                {getPricePerMonthWithCurrency(price.price, price.currency, price.interval, price.interval_count)}
              </span>
            </div>
            <AppRadio
              value={price.id}
              checked={price.isSelected || price.id === profile?.customer?.subscription?.priceId}
            />
          </div>
        );
      })}
    </Radio.Group>
  );
};
