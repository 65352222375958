import React from 'react';

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectProfile } from 'src/app/slices/profileSlice';
/**
 * @param {object} param0
 * @param {boolean} param0.isVisible
 * @param {() => void} [param0.onOk]
 * @param {() => void} [param0.onCancel]
 * @param {string} param0.text
 * @param {boolean} [param0.isUnclosable]
 * @param {boolean} [param0.isUpgradeModal]
 */
export const RestrictionModal = ({
  isVisible,
  onOk = null,
  onCancel,
  text,
  isUnclosable = null,
  isUpgradeModal = null
}) => {
  const { t } = useTranslation();

  const profile = useSelector(selectProfile);

  return (
    <Modal
      title={
        profile?.hasNoSubscriptionAccess && !isUpgradeModal
          ? t('choose_subscription_modal_title')
          : t('upgrade_subscription_modal_title')
      }
      centered
      visible={isVisible}
      forceRender
      closable={!isUnclosable}
      className={`mg-modal _info`}
      okButtonProps={{ className: `mg-btn`, style: { display: isUnclosable ? 'none' : undefined } }}
      cancelButtonProps={{
        style: {
          display: 'none'
        }
      }}
      onOk={onOk || onCancel}
      onCancel={onCancel}
    >
      <span>{text}</span>
      <span>{t('restriction_subscribe_text_1')}</span>
      <Link to="/account/subscription">
        {profile?.hasNoSubscriptionAccess
          ? t('restriction_subscribe_text_2_choose')
          : t('restriction_subscribe_text_2_upgrade')}
      </Link>
      <span>{t('restriction_subscribe_text_3')}</span>
    </Modal>
  );
};
