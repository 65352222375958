import React, { Suspense, lazy } from 'react';

export function generateLazy(importCompnent) {
  const Content = lazy(importCompnent);
  return function LazyComponent(props) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Content {...props} />
      </Suspense>
    );
  };
}

export function generatePromise(path, componentName) {
  return () =>
    new Promise((resolve, reject) => {
      path()
        .then((result) => {
          resolve(result.default ? result : { default: result[componentName] });
        })
        .catch(reject);
    });
}
