import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import './styles.css';

export const AppTableCell = ({
  title,
  chartData,
  children,
  dataIndex,
  record,
  handleSave,
  editingDefault = false,
  EditableCellComponent,
  AdditionalEventsComponent,
  onChangeCell,
  onPressEnterCell,
  onBlurCell,
  onFocusCell,
  onClickCell,
  coloredRowConditions,
  cellClassConditions,
  onChangeAdditionalEvent,
  disableEditingConditions,
  ...restProps
}) => {
  const [editing, setEditing] = useState(editingDefault);
  const [value, setValue] = useState(EditableCellComponent ? record[dataIndex] : null);
  const [color, setColor] = useState('inherit');
  const [cellClass, setCellClass] = useState('');
  const ref = useRef(null);
  // const inputRef = useRef(null);

  const toggleEdit = () => {
    setEditing(true);
  };

  // useEffect(() => {
  //   setValue(EditableCellComponent ? record[dataIndex] : null);

  //   const handleClickOutside = (event) => {
  //     if (ref.current && !ref.current.contains(event.target) && !editingDefault) {
  //       setEditing(false);
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, [EditableCellComponent, dataIndex, editingDefault, record]);

  useEffect(() => {
    setValue(EditableCellComponent ? record[dataIndex] : null);
    const handleEscapePressed = (event) => {
      if (event.key === 'Escape' && ref.current && !editingDefault) {
        setEditing(false);
      }
    };
    document.addEventListener('keydown', handleEscapePressed, true);
    return () => {
      document.removeEventListener('keydown', handleEscapePressed, true);
    };
  }, [EditableCellComponent, dataIndex, editingDefault, record]);

  const changeHandler = (val) => {
    return onChangeCell(val, dataIndex, record);
  };
  const pressEnterHandler = (val) => {
    // setEditing(!editing);
    onPressEnterCell(val, dataIndex, record.id);
  };
  const blurCellHandler = useCallback(
    (val) => {
      if (
        dataIndex === 'currencyId' ||
        dataIndex === 'transactionDate' ||
        dataIndex === 'transactionType' ||
        dataIndex === 'executionDate' ||
        dataIndex === 'incomeType'
      ) {
        setEditing(!editing);
        onBlurCell(val, dataIndex, record.id);
      } else {
        setEditing(!editing);
        const currentIsNan = isNaN(parseFloat(val));
        const lastIsNan = isNaN(parseFloat(value));
        const current = currentIsNan ? 0 : parseFloat(val);
        const last = lastIsNan ? 0 : parseFloat(value);
        if (current !== last) {
          onBlurCell(val, dataIndex, record?.id);
        }
      }
    },
    [dataIndex, onBlurCell, record?.id, value, editing]
  );
  const focusCellHandler = (event) => {
    onFocusCell(event, ref.current, dataIndex, record.id);
  };
  const clickCellHandler = (val) => {
    onClickCell(val, dataIndex, record.id);
  };

  useLayoutEffect(() => {
    coloredRowConditions?.forEach((item) => {
      const { field, value } = item.condition;
      if (record[field] === value) {
        setColor(item.color);
      } else {
        setColor('inherit');
      }
    });
    cellClassConditions?.forEach((item) => {
      const { field, value } = item.condition;
      if (record[field] === value) {
        setCellClass(item.class);
      }
    });
  }, [cellClassConditions, coloredRowConditions, record]);

  return EditableCellComponent && record && record[dataIndex] !== undefined ? (
    <td
      data-test={`table-editable-cell-${dataIndex}`}
      {...restProps}
      ref={ref}
      className={`ant-table-cell ${value === true ? 'ant-table-cell__green' : ''}`}
    >
      {editing ? (
        <div className="editable-field">
          <EditableCellComponent
            {...(onChangeCell && { onChange: changeHandler })}
            {...(onPressEnterCell && { onPressEnter: pressEnterHandler })}
            {...(onBlurCell && { onBlur: blurCellHandler })}
            {...(onFocusCell && { onFocus: focusCellHandler })}
            {...(onClickCell && { onClick: clickCellHandler })}
            {...(disableEditingConditions && { readOnly: disableEditingConditions(record) })}
            value={value}
            data-key={`${record.id}${dataIndex}`}
            // ref={inputRef}
          />
        </div>
      ) : record[dataIndex] ? (
        <p className="editable-value" onClick={toggleEdit}>
          {record[dataIndex]}
        </p>
      ) : (
        <div className="clickable-area" onClick={toggleEdit}></div>
      )}
    </td>
  ) : AdditionalEventsComponent && record && record[dataIndex] !== undefined ? (
    <td data-test="table_cell_additional" {...restProps} className="ant-table-cell mg-table__additioanal-cell">
      <AdditionalEventsComponent onClickElement={(type) => onChangeAdditionalEvent(type, dataIndex, record.id)} />
    </td>
  ) : (
    <td data-test="table_cell" {...restProps} className={`ant-table-cell ${cellClass || ''}`}>
      {coloredRowConditions ? <span style={{ color: color }}>{children}</span> : children}
    </td>
  );
};
