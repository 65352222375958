import React, { useCallback, useState, useEffect } from 'react';

import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { createProductPriceAsync } from 'src/app/api/product';
import AppInput from 'src/app/components/form/input';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { Price } from 'src/app/modules/products/components/price';
import { getObjectWithInterval } from 'src/app/modules/products/helpers';
import { setIsNeedReload } from 'src/app/slices/productSlice';
import { ReactComponent as BackArrow } from 'src/assets/svg/admin/back-arrow.svg';

import styles from './TariffCreate.module.css';
import './style.css';

export const TarrifCreate = ({ currentProductName }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productId } = useParams();

  const initialValuesTariffCreate = {
    name: ''
  };
  const [createTariffForm] = Form.useForm();
  const [newTarrif, setNewTariff] = useState(null);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);

  const checkFieldsAreFilled = useCallback(() => {
    if (
      createTariffForm.getFieldValue('name') &&
      newTarrif?.currency &&
      newTarrif?.interval &&
      newTarrif?.interval_count &&
      (newTarrif?.price || newTarrif?.price === 0) &&
      (newTarrif?.trial_period_days || newTarrif?.trial_period_days === 0)
    ) {
      setDisabledSaveButton(false);
    } else {
      setDisabledSaveButton(true);
    }
  }, [createTariffForm, newTarrif]);

  useEffect(() => {
    if (newTarrif) {
      checkFieldsAreFilled();
    }
  }, [checkFieldsAreFilled, newTarrif]);

  const getNewDataFromPrice = useCallback((formData) => {
    const preparedData = getObjectWithInterval(formData);
    setNewTariff(preparedData);
  }, []);

  const handleOnSaveButton = useCallback(() => {
    dispatch(
      createProductPriceAsync(productId, {
        name: createTariffForm.getFieldValue('name'),
        price: newTarrif.price * 100,
        currency: newTarrif.currency,
        interval: newTarrif.interval,
        interval_count: Number(newTarrif.interval_count),
        trial_period_days: newTarrif.trial_period_days,
        active: newTarrif.active || false
      })
    )
      .then(() => {
        setDisabledSaveButton(true);
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [createTariffForm, dispatch, newTarrif, productId]);

  return (
    <div>
      <div className={styles.wrapHeader}>
        <div className={styles.header}>
          <div className={styles.headerLeftPart}>
            <Button
              onClick={() => {
                dispatch(setIsNeedReload(true));
                navigate(`/products/#${currentProductName || 'free'}`);
              }}
              className={`mg-btn _text ${styles.backBtn}`}
            >
              <BackArrow /> {t('all_products_btn')}
            </Button>
            <Form
              name="createTariff"
              form={createTariffForm}
              autoComplete="off"
              className="createTariffForm"
              initialValues={initialValuesTariffCreate}
              onValuesChange={() => checkFieldsAreFilled()}
            >
              <Form.Item
                className={styles.fieldName}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('required_input')
                  }
                ]}
              >
                <AppInput data-test="tariff_edit_page_title" placeholder={t('tariff_name_placeholder')}></AppInput>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <div className={styles.prices}>
            <Price sendData={getNewDataFromPrice} />
          </div>
          <Button
            onClick={handleOnSaveButton}
            className={`${styles.saveBtn} mg-btn _blue`}
            disabled={disabledSaveButton}
          >
            {t('save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
