export class ProductModel {
  id = '';
  name = '';
  description = '';
  active = false;
  prices = [];
  order = null;

  constructor(data) {
    if (data) {
      this.id = data.id || '';
      this.name = data.name || '';
      this.description = data.description || '';
      this.active = data.active || false;
      this.prices = data.prices || [];
      this.order = data.order || null;
    }
  }
}
