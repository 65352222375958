import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import './styles.css';

const antIcon = <LoadingOutlined style={{ fontSize: 40, color: 'var(--green)' }} spin />;
export const AppSpinner = () => {
  return (
    <div className="mg-spinner__wrap">
      <Spin indicator={antIcon} delay={500} className="spinner" />
    </div>
  );
};
