import React, { useCallback, useMemo } from 'react';

import { Button } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { updateSubscriptionAsync } from 'src/app/api/product';
import { getProfileAsync } from 'src/app/api/profile';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { roles } from 'src/app/models/role';
import { setProfile, selectProfile } from 'src/app/slices/profileSlice';

import styles from './AccountSubscriptions.module.css';
import { DefineSvg } from './helpers';

export const AccountSubscriptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile = useSelector(selectProfile);

  const subscription = useMemo(() => profile?.customer?.subscription, [profile?.customer?.subscription]);
  const subscrEndDate = useMemo(
    () => moment(subscription?.convertInfo?.period?.end).format('MMM D YYYY'),
    [subscription]
  );

  const navigateToSubscriptions = useCallback(() => {
    if (profile?.roleId !== roles.Influencer) {
      navigate('/account/subscription');
    }
  }, [navigate, profile?.roleId]);

  const handleOnUpdateSubscription = useCallback(() => {
    dispatch(updateSubscriptionAsync(subscription?.priceId, !subscription?.cancelAtPeriodEnd))
      .then(() => {
        dispatch(getProfileAsync())
          .then((profile) => {
            dispatch(setProfile(profile));
          })
          .catch((e) => {
            appMessage(typeMessage.ERROR, e.message);
          });
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch, subscription?.cancelAtPeriodEnd, subscription?.priceId]);

  return profile ? (
    <>
      <h3 className="page__subtitle">{t('account_my_subs_subtitle')}</h3>
      <hr />
      <div className={styles.section}>
        <div className={`${styles.subscriptionsSection} ${styles.email}`}>
          <span>{profile.email}</span>
        </div>

        <div className={styles.subscriptionsSection}>
          <div className={styles.aboutSubscription}>
            {subscription ? (
              <div>
                <div className={styles.typeSubscription}>
                  <DefineSvg title={subscription?.price?.product?.name} />
                  <span className={styles.nameSubscription}>{subscription?.price?.product?.name}</span>
                </div>

                {subscription?.cancelAtPeriodEnd ? (
                  <p className={styles.dateSubscription}>
                    {t('subscribe_cancelled')} {subscrEndDate}
                  </p>
                ) : (
                  <p className={styles.dateSubscription}>
                    {t('subscribe_renewed')} {subscrEndDate}
                  </p>
                )}
              </div>
            ) : (
              <p className={styles.withoutSubscription}>{t('account_text_without_subscription')}</p>
            )}
          </div>
          <div className={styles.manageSubscription} styles={subscription ? { marginBottom: '-46px' } : {}}>
            <Button
              data-test="profile_page_upgrade_subs"
              onClick={navigateToSubscriptions}
              className="mg-btn"
              disabled={profile?.roleId === roles.Influencer}
            >
              {t('btn_upgrade_subscription')}
            </Button>
            {subscription ? (
              <Button
                onClick={handleOnUpdateSubscription}
                data-test="profile_page_manage_subs"
                className={`mg-btn _text ${styles.manageBtn}`}
              >
                {subscription?.cancelAtPeriodEnd ? t('link_restore') : t('link_unsub')}
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    ''
  );
};
