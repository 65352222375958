export const getObjectWithPaidPeriod = (object) => {
  let paidPeriod = '';
  if (object.interval && object.interval_count) {
    if (object.interval_count !== 1) {
      paidPeriod = `${object.interval_count} ${object.interval}s`;
    } else {
      paidPeriod = `${object.interval_count} ${object.interval}`;
    }
  }
  return {
    ...object,
    paidPeriod: paidPeriod
  };
};

export const getObjectWithInterval = (object) => {
  if (object.paidPeriod) {
    let [intervalCount, interval] = object.paidPeriod.split(' ');
    if (interval === 'months') {
      interval = 'month';
    }
    return {
      ...object,
      interval: interval,
      interval_count: intervalCount
    };
  } else {
    return object;
  }
};
