import React, { useCallback, useEffect, useState } from 'react';

import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from '../../SystemSettings.module.css';
import { AppCheckbox } from 'src/app/components/form/checkbox';
import AppInput from 'src/app/components/form/input';
import { AppInputNumber } from 'src/app/components/form/input-number';
import { AppTimePicker } from 'src/app/components/form/time-picker/index';

import { parse12hFormatToCronTime, parseCronTimeTo12hFormat } from './helpers';

const cronKey = 'cronPeriod';
const alertServiceCronKey = 'alert.service-cronPeriod';

const TabContentComp = ({ settings, tabKeys, onSaveSettings }) => {
  const { t } = useTranslation();
  const [settingsFiltered, setSettingsFiltered] = useState(null);
  const [form] = Form.useForm();
  const [saveDisabledButton, setSaveDisabledButton] = useState(true);

  useEffect(() => {
    if (settings) {
      const filtered = settings.filter((el) => tabKeys.includes(el.type));
      setSettingsFiltered(filtered);
    }
  }, [settings, tabKeys]);

  const getInnerObjectValue = useCallback((key, el) => {
    return Object.keys(el).map((innerKey) => {
      if (typeof el[innerKey] === typeof {}) {
        return getInnerObjectValue(innerKey, el[innerKey]);
      }
      return { name: `${innerKey}-${key}`, value: el[innerKey] };
    });
  }, []);

  useEffect(() => {
    if (settingsFiltered) {
      const res = settingsFiltered?.map((el) => {
        return Object.keys(el.data).map((key) => {
          if (typeof el.data[key] === typeof {}) {
            return getInnerObjectValue(key, el.data[key]);
          }
          return { name: `${el.type}-${key}`, value: el.data[key] };
        });
      });

      const flattenedResult = res.flat(Infinity);

      const indexOfCronService = flattenedResult.findIndex((el) => el.name === alertServiceCronKey);
      if (indexOfCronService !== -1) {
        flattenedResult[indexOfCronService] = {
          name: alertServiceCronKey,
          value: parseCronTimeTo12hFormat(flattenedResult[indexOfCronService].value)
        };
      }

      form.setFields(flattenedResult);
    }
  }, [form, settingsFiltered, getInnerObjectValue]);

  const getFiled = (key, value) => {
    if (key === cronKey) {
      return <AppTimePicker placeholder={key} />;
    } else if (typeof value === typeof '') {
      return <AppInput placeholder={key} />;
    } else if (typeof value === typeof 1) {
      return <AppInputNumber placeholder={key} />;
    } else if (typeof value === typeof false) {
      return <AppCheckbox placeholder={key} />;
    }
  };

  const renderInnerField = (key, el) => {
    return (
      <div key={key} className={styles.subGroup}>
        <p className={styles.subGroupName}>{key}</p>
        {Object.keys(el).map((innerKey) => {
          if (typeof el[innerKey] === typeof {} && !Array.isArray(el)) {
            return renderInnerField(innerKey, el[innerKey]);
          }
          return (
            <div key={innerKey} className={styles.formItemWrap}>
              {Array.isArray(el) ? '' : <span className={styles.label}>{t(`tab_field_${innerKey}`)}</span>}

              <Form.Item
                name={`${innerKey}-${key}`}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t('required_input')
                  }
                ]}
              >
                {getFiled(innerKey, el[innerKey])}
              </Form.Item>
            </div>
          );
        })}
      </div>
    );
  };

  const getInnerObject = (obj, key, data) => {
    let res = {};
    Object.keys(obj).forEach((innerKey) => {
      if (typeof obj[innerKey] === typeof {}) {
        res = { ...res, [innerKey]: getInnerObject(obj[innerKey], innerKey, data) };
      } else {
        const dataKey = Object.keys(data).find((keyData) => keyData === `${innerKey}-${key}`);
        res = { ...res, [innerKey]: data[dataKey] };
      }
    });
    return res;
  };

  const parseArray = (key, data) => {
    const res = [];
    Object.keys(data).forEach((dataKey) => {
      if (dataKey.includes(key)) {
        res.push(data[dataKey]);
      }
    });
    return res;
  };

  const convertData = (data) => {
    let result = JSON.parse(JSON.stringify(settingsFiltered));
    result = result.map((elem) => {
      let obj = elem.data;
      Object.keys(obj).forEach((key) => {
        const dataKey = Object.keys(data).find((keyData) => keyData.includes(elem.type) && keyData.includes(key));
        if (!dataKey && typeof obj[key] === typeof {}) {
          obj = {
            ...obj,
            [key]: Array.isArray(obj[key]) ? parseArray(key, data) : getInnerObject(obj[key], key, data)
          };
        } else {
          obj = { ...obj, [key]: data[dataKey] };
        }
      });
      return { ...elem, data: obj };
    });
    return result;
  };

  const handleSave = () => {
    const formValues = form.getFieldsValue();

    if (alertServiceCronKey in formValues) {
      formValues[alertServiceCronKey] = parse12hFormatToCronTime(formValues[alertServiceCronKey]);
    }

    const result = convertData(formValues);
    onSaveSettings(result);
  };

  const handleOnFieldsChange = () => {
    setSaveDisabledButton(form.getFieldsError().some((field) => field.errors.length > 0));
  };

  return (
    <>
      {settingsFiltered?.map((el) => {
        return (
          <div key={el.type} className={styles.fieldsGroup}>
            <p className={styles.groupName}>{el.title}</p>
            <Form
              name={`edit-settings-${el.type}`}
              form={form}
              autoComplete="off"
              className="form"
              onFieldsChange={handleOnFieldsChange}
            >
              {Object.keys(el.data).map((key) => {
                if (typeof el.data[key] === typeof {}) {
                  return renderInnerField(key, el.data[key]);
                }
                return (
                  <div key={key} className={styles.formItemWrap}>
                    {Array.isArray(el.data[key]) ? '' : <span className={styles.label}>{t(`tab_field_${key}`)}</span>}
                    <Form.Item
                      name={`${el.type}-${key}`}
                      className={styles.formItem}
                      rules={[
                        {
                          required: true,
                          message: t('required_input')
                        }
                      ]}
                    >
                      {getFiled(key, el.data[key])}
                    </Form.Item>
                  </div>
                );
              })}
            </Form>
          </div>
        );
      })}
      <Button
        className={`mg-btn ${styles.saveSettingBtn}`}
        type="submit"
        onClick={handleSave}
        disabled={saveDisabledButton}
      >
        {t('save_settings')}
      </Button>
    </>
  );
};

function tabPropsAreEqual(prevTab, nextTab) {
  return JSON.stringify(prevTab) === JSON.stringify(nextTab);
}

export const TabContent = React.memo(TabContentComp, tabPropsAreEqual);
