import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from '../../WhyMg.module.css';

export const WhyMgTextBlock = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.textBlock}>
      <h2 className={`${styles.lightGreenTitle}`} id="title-1">
        {t('why_mg_title')}
      </h2>
      <p> {t('why_mg_description')}</p>
      <ul>
        <li>{t('why_mg_goal_1')}</li>
        <li>{t('why_mg_goal_2')}</li>
      </ul>
      <p>{t('why_mg_conclusion')}</p>
      {/* <h3 className={styles.lightGreenSubTitle}>
      As we have some experience in financial analysis, we are aware how resource-consuming investment analysis
      could be. Our goal:
    </h3> */}
    </div>
  );
};
