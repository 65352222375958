import React, { useEffect, useCallback, useState } from 'react';

import { Divider, Tabs, Button, Grid } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { loadProductsAsync, loadProductPricesAsync, updateProductAsync } from 'src/app/api/product';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { AppSimpleTable } from 'src/app/components/table';
import { ProductModel } from 'src/app/models/product';
import { setSpinnerVisible } from 'src/app/slices/commonSlice';
import { selectIsNeedReload, setIsNeedReload } from 'src/app/slices/productSlice';

import { ProductDescriptionModal } from './components/product-description-modal';
import { TableFilterPanel } from './components/table-filter-panel';
import { TarrifCreate } from './components/tariff-create';
import { TariffEdit } from './components/tariff-edit';
import { columns } from './consts';
import styles from './Products.module.css';

import './styles.css';

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

export const Products = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [productPrices, setProductPrices] = useState([]);
  const [isEditDescription, setIsEditDescription] = useState(false);
  const isNeedReload = useSelector(selectIsNeedReload);

  const [currentTab, setCurrentTab] = useState('free');
  const [selectedFilter, setSelectedFilter] = useState('');
  const [sort, setSort] = useState({ column: 'created', direction: 'DESC' });
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    limit: 10,
    index: 1,
    total: 0
  });

  const screens = useBreakpoint();

  useEffect(() => {
    dispatch(setSpinnerVisible(true));
    dispatch(loadProductsAsync())
      .then((data) => {
        const { items } = data;
        setProducts(
          items.map((item) => {
            return new ProductModel(item);
          })
        );
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch]);

  const loadProductPrices = useCallback(() => {
    if (currentProduct) {
      dispatch(setSpinnerVisible(true));
      dispatch(
        loadProductPricesAsync(
          currentProduct.id,
          false,
          'withSubscriptions',
          pagination.index - 1,
          pagination.limit,
          search,
          selectedFilter,
          [{ column: sort.column, direction: sort.direction }]
        )
      )
        .then((data) => {
          let { items, total } = data;
          if (!items) {
            items = [];
          }
          setPagination((prevState) => ({
            ...prevState,
            total
          }));
          setProductPrices(
            items.map((item) => {
              return {
                id: item.id,
                name: item.name,
                active: item.active,
                created: moment(item.created).format('MMM D YYYY'),
                subscriptionsCount: item.subscriptionsCount
              };
            })
          );
          dispatch(setSpinnerVisible(false));
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    }
  }, [currentProduct, dispatch, pagination.index, pagination.limit, search, selectedFilter, sort]);

  useEffect(() => {
    if (isNeedReload) {
      loadProductPrices();
      dispatch(setIsNeedReload(false));
    }
  }, [dispatch, isNeedReload, loadProductPrices]);

  useEffect(() => {
    loadProductPrices();
  }, [loadProductPrices]);

  useEffect(() => {
    if (location.hash) {
      if (products) {
        setCurrentProduct(products.find((product) => product.name.toLowerCase() === location.hash.slice(1)));
      }
    } else {
      setCurrentProduct(products.find((product) => product.name.toLowerCase() === 'free'));
    }
  }, [location, products]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, index: 1 }));
    setSearch('');
  }, [location]);

  const editTariff = useCallback(
    (tariff) => {
      navigate(`${currentProduct?.id}/edit-tariff/${tariff.id}/#${currentProduct?.name.toLowerCase()}`);
    },
    [currentProduct?.id, currentProduct?.name, navigate]
  );

  const handleChangePagination = useCallback((page, pageSize) => {
    setPagination({
      index: page,
      limit: pageSize
    });
  }, []);

  const handleSort = useCallback((sort) => {
    setSort(sort);
  }, []);

  const handleSeacrh = useCallback((val) => {
    setSearch(val);
  }, []);

  const handleSelectFilter = useCallback(
    (val) => {
      if (val !== selectedFilter) {
        setPagination((prev) => ({ ...prev, index: 1 }));
      }
      setSelectedFilter((prev) => (val === prev ? '' : val));
    },
    [selectedFilter]
  );

  const onCancelEditDescriptionModal = useCallback(() => {
    setIsEditDescription(false);
  }, []);

  const onSaveEditDescriptionModal = useCallback(
    (formData) => {
      if (currentProduct) {
        dispatch(updateProductAsync(currentProduct.id, { ...formData, name: currentProduct?.name }))
          .then(() => {
            setProducts(
              products.map((item) => {
                if (item.id === currentProduct.id) {
                  return {
                    ...currentProduct,
                    description: formData.description
                  };
                }
                return item;
              })
            );
          })
          .catch((e) => {
            appMessage(typeMessage.ERROR, e.message);
          });
      }
      setIsEditDescription(false);
    },
    [currentProduct, dispatch, products]
  );
  if (Object.keys(screens).length === 0) {
    return <></>;
  }
  return (
    <div>
      <div className={styles.paddings}>
        <h1 className={`${styles.title} page__title-uppercase`}>{t('subscribers_title')}</h1>
      </div>

      <div className={`${styles.tabsWrap} tabs-wrap-products`}>
        <Button onClick={() => setIsEditDescription(true)} className={`mg-btn _text ${styles.editDescriptionBtn}`}>
          {t('edit_description_btn')}
        </Button>
        <Tabs
          defaultActiveKey={location.hash ? location.hash.slice(1) : 'free'}
          activeKey={location.hash ? location.hash.slice(1) : 'free'}
          onChange={(key) => {
            setCurrentTab(key);
            navigate(`/products/#${key}`);
          }}
          className={styles.tabsContainer}
        >
          {products.map((prd, index) => {
            return (
              <TabPane forceRender className={styles.tab} tab={prd.name} key={prd.name.toLowerCase()}>
                {location.hash.slice(1) === prd.name.toLowerCase() ? (
                  <Routes>
                    <Route
                      index
                      element={
                        <>
                          <TableFilterPanel
                            selectedFilter={selectedFilter}
                            onSelectFilter={handleSelectFilter}
                            onSearch={handleSeacrh}
                            product={currentProduct}
                          ></TableFilterPanel>
                          <AppSimpleTable
                            columns={columns}
                            padding={
                              screens.lg || screens.md ? '0 106px 0 106px' : screens.sm ? '0 60px 0 60px' : '0 0 0 0'
                            }
                            sort={sort}
                            onSort={handleSort}
                            listOfData={productPrices}
                            className={'_white-row'}
                            pagination={pagination}
                            onSelectRow={editTariff}
                            onChangePagination={handleChangePagination}
                          ></AppSimpleTable>
                        </>
                      }
                    />
                    <Route
                      path={`:productId/edit-tariff/:tariffId`}
                      element={<TariffEdit currentProductName={currentTab} />}
                    />
                    <Route
                      path={`create-tariff/:productId`}
                      element={<TarrifCreate currentProductName={currentTab} />}
                    />
                  </Routes>
                ) : (
                  ''
                )}
              </TabPane>
            );
          })}
        </Tabs>

        {currentProduct ? (
          <ProductDescriptionModal
            visible={isEditDescription}
            onCancel={onCancelEditDescriptionModal}
            onSave={onSaveEditDescriptionModal}
            product={currentProduct}
          />
        ) : (
          ''
        )}

        <Divider className={`${styles.divider} ${styles.dividerTabs}`} />
        <div style={{ paddingBottom: pagination.limit >= pagination.total ? '72px' : '' }}></div>
      </div>
    </div>
  );
};
