import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'product',
  initialState: {
    products: [],
    isShowErrorMessageProducts: false,
    isNeedReload: false
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setIsShowErrorMessageProducts: (state, action) => {
      state.isShowErrorMessageProducts = action.payload;
    },
    setIsNeedReload: (state, action) => {
      state.isNeedReload = action.payload;
    }
  }
});

export const { setProducts, setIsShowErrorMessageProducts, setIsNeedReload } = productSlice.actions;

export const selectProducts = (state) => state.product.products;
export const selectisShowErrorMessageProducts = (state) => state.product.isShowErrorMessageProducts;
export const selectIsNeedReload = (state) => state.product.isNeedReload;

export default productSlice.reducer;
