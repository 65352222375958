import React, { useEffect, useMemo, useState } from 'react';

import { Input } from 'antd';

import './styles.css';

/**
 * @param {object} props
 * @param {string} [props.type]
 * @param {string} [props.placeholder]
 * @param {string} [props.label]
 * @param {string} [props.value]
 * @param {string} props.className
 * @param {(value: string) => void} [props.onChange]
 * @param {(target: EventTarget) => void} [props.onPressEnter]
 * @param {(value: string) => void} [props.onBlur]
 * @param {any} inputRef
 */
const AppInput = (
  { type, placeholder, label, value, onChange, onPressEnter, onBlur, className, ...rest },
  inputRef
) => {
  const componentId = useMemo(() => Date.now().toString(16), []);
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.currentTarget.value);
    }
    setVal(e.currentTarget.value);
  };
  return (
    <div className={'mg-input__wrap' + ` ${className || ''}`}>
      <label htmlFor={componentId} className="mg-input__label">
        {label}
      </label>
      <Input
        id={componentId}
        value={val}
        className="mg-input"
        placeholder={placeholder}
        type={type}
        onChange={handleChange}
        // @ts-ignore
        onPressEnter={onPressEnter ? (e) => onPressEnter(e.target.value) : null}
        onBlur={onBlur ? (e) => onBlur(e.target.value) : null}
        ref={inputRef}
        {...rest}
      />
    </div>
  );
};
export default React.forwardRef(AppInput);
