import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import { isNumber } from 'chart.js/helpers';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';

// import { gridPlugin } from './helpers/grid';
import styles from './Chart.module.css';
import './styles.css';
import { eventYAxisPlugin } from './helpers/eventYAxis';
import { htmlLegendPlugin } from './helpers/legend';
import { defaultOptions } from './helpers/options';
import { prepareNums, getValueScale } from './helpers/prepareNums';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin
);

/**
 * @param {object} props
 * @param {string} [props.chartId]
 * @param {object} props.options
 * @param {AppChartTypes.AppChartData} props.data
 * @param {boolean} [props.displayLegend]
 * @param {boolean} [props.customLegend]
 * @param {boolean} [props.isNeedXLine]
 * @param {boolean} [props.order]
 * @param {boolean} [props.isNeedDatasetText]
 * @param {object} [props.colors]
 * @param {boolean} [props.inTable]
 * @param {object[]} [props.plugins]
 * @param {string} [props.classNameWrap]
 * @param {number} [props.group]
 * @param {number} [props.borderWidth]
 * @param {{value: string; background: string}[]} [props.fills]
 * @param {boolean|string} [props.stepped]
 * @param {any} [props.classNameLegend]
 * @param {string[]} [props.xLabels]
 * @param {string[]} [props.borderDash]
 * @param {string} [props.yTicksStepSize]
 * @param {number} [props.maxYTicksWidth]
 * @param {number} [props.setMaxYTicksWidth]
 * @param {any} chartRef
 */
const AppChart = (
  {
    chartId,
    options,
    data,
    displayLegend = true,
    isNeedXLine = true,
    isNeedDatasetText = false,
    colors,
    inTable = false,
    plugins = [],
    classNameWrap,
    borderDash,
    order,
    group,
    fills,
    stepped,
    classNameLegend,
    xLabels,
    borderWidth,
    yTicksStepSize,
    maxYTicksWidth,
    setMaxYTicksWidth
  },
  chartRef
) => {
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState(null);
  const id = useMemo(() => (Math.random() + 1).toString(36).substring(7), []);

  const generateLabels = useCallback(() => {
    if (xLabels) {
      setLabels(xLabels);
      return;
    }
    const labels = [];
    for (let i = data.start; i <= data.end; i++) {
      labels.push(i);
    }
    setLabels(labels);
  }, [data.end, data.start, xLabels]);

  const generateDataSets = useCallback(() => {
    /** @type {AppChartTypes.AppChartDataset[]} */
    const datasets = [];
    const colorByName = {};
    const fillByName = {};
    data.options.forEach((opt, i) => {
      if ((group && opt.group === group) || group === undefined) {
        colorByName[opt.name] = colors[i];
      }
    });
    Object.keys(data.graphic).forEach((key, i) => {
      if (fills) {
        fillByName[key] = fills[i];
      }
    });

    const relevantKeys = data.options.filter((el) => {
      if ((group && el.group === group) || group === undefined) {
        if (!!el.secondYaxis === false) {
          return true;
        }
      }
      return false;
    });
    const relevantKeysWithSecondAxis = data.options.filter((el) => {
      if (el.secondYaxis === true) {
        return true;
      }
      return false;
    });
    const hiddenKeys = data.options
      .filter((el) => (group ? el.group === group && el.hidden : false))
      .map((el) => el.name);

    let minValue = null;
    let secondItem = null;
    relevantKeysWithSecondAxis.forEach((option) => {
      const arr = Object.values(data.graphic[option.name])
        .filter((el) => isNumber(el))
        .sort();
      const val = arr[arr.length - 1] || arr[0];
      minValue = val < minValue || !minValue ? val : minValue;
      secondItem = getValueScale([minValue]);
    });

    minValue = null;
    let item = null;
    relevantKeys.forEach((option) => {
      const arr = Object.values(data.graphic[option.name])
        .filter((el) => isNumber(el))
        .sort();
      const val = arr[arr.length - 1] || arr[0];
      minValue = val < minValue || !minValue ? val : minValue;
      item = getValueScale([minValue]);
    });
    let orderIndex = Object.keys(data.options).length;
    relevantKeys.forEach((option) => {
      const key = option.name;
      orderIndex--;
      const dataset = Object.values(data.graphic[key]);
      datasets.push({
        label: option?.title,
        data: prepareNums(dataset, item),
        borderColor: colorByName[option.name],
        hidden: false,
        showLine: !hiddenKeys.includes(key),
        pointHoverBackgroundColor: 'transparent',
        spanGaps: true,
        stepped: stepped || false,
        borderWidth: borderWidth || 3,
        borderDash: (option?.title === 'ROE modified, % (trend)' && borderDash) || [],
        fill: fillByName[key] ? fillByName[key].value : null,
        backgroundColor: fillByName[key] ? fillByName[key].background : null,
        order: order ? orderIndex : 0
      });
    });

    relevantKeysWithSecondAxis.forEach((option) => {
      const key = option.name;
      const dataset = Object.values(data.graphic[key]);

      datasets.push({
        label: option?.title,
        data: prepareNums(dataset, secondItem),
        borderColor: colorByName[option.name],
        hidden: false,
        showLine: !hiddenKeys.includes(key),
        pointHoverBackgroundColor: 'transparent',
        spanGaps: true,
        stepped: stepped || false,
        borderWidth: 3,
        fill: fillByName[key] ? fillByName[key].value : null,
        backgroundColor: fillByName[key] ? fillByName[key].background : null,
        yAxisID: 'y1'
      });
    });
    setDatasets(datasets);
  }, [colors, data.graphic, data.options, group, stepped, fills, borderWidth, borderDash, order]);

  useEffect(() => {
    if (!data.datasets) {
      generateLabels();
      generateDataSets();
    } else {
      setDatasets(data.datasets);
      setLabels(data.labels);
    }
  }, [data.datasets, data.labels, generateDataSets, generateLabels]);

  const configuredDefaultOptions = useMemo(
    () =>
      defaultOptions(
        labels.length,
        id,
        isNeedDatasetText,
        inTable,
        false,
        yTicksStepSize,
        maxYTicksWidth,
        setMaxYTicksWidth
      ),
    [id, inTable, isNeedDatasetText, labels.length, maxYTicksWidth, setMaxYTicksWidth, yTicksStepSize]
  );
  return (
    id &&
    datasets &&
    labels.length !== 0 && (
      <div className={`${styles.mgChartWrap} ${classNameWrap}`}>
        <Line
          id={chartId}
          ref={chartRef}
          options={{
            ...configuredDefaultOptions,
            ...options,
            plugins: {
              ...configuredDefaultOptions.plugins,
              ...options.plugins
            }
          }}
          data={{ labels: labels, datasets: datasets }}
          plugins={[!inTable ? eventYAxisPlugin(id) : {}, displayLegend ? htmlLegendPlugin : {}, ...plugins]}
        />
        <div id={'legend-container-' + id} className={`${styles.legendWrap} ${classNameLegend}`}></div>
        <span className={styles.yAxisLine} id={'y-axis-line-' + id}></span>
        {isNeedXLine && <span className={styles.xAxisLine} id={'x-axis-line-' + id}></span>}
        <span className={styles.tooltip} id={'tooltip-' + id}>
          <table className={styles.tooltipTable}></table>
        </span>
      </div>
    )
  );
};

export default React.forwardRef(AppChart);
