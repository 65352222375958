import { axiosDelete, axiosErrors, axiosGet, axiosPost, axiosPut } from '../helpers/axios';
import { setIsShowErrorMessageAlerts } from '../slices/alertsSlice';

export const loadAlertTypesAsync = () => (dispatch) => {
  const loadAlertTypes = axiosGet(`dictionaries/alerts`);
  return loadAlertTypes()
    .then((res) => {
      dispatch(setIsShowErrorMessageAlerts(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAlerts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const loadAlertsListByViewAsync = (view, index, limit, order, field, value) => (dispatch) => {
  const alertsListByView = axiosGet(
    `alerts/views/${view}?index=${index || 0}&limit=${limit || 10}&order=${order.column}&order=${order.direction}${
      order.sortValue !== undefined ? '&order=' + order.sortValue : ''
    }${value ? '&search=' + field + '&search=' + value : ''}`
  );
  return alertsListByView()
    .then((res) => {
      dispatch(setIsShowErrorMessageAlerts(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAlerts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const searchCompanyByNameAsync = (name) => (dispatch) => {
  const searchCompanyByName = axiosGet(`company/search_alerts?value=${name}%`, {
    isNeedSpinner: false
  });
  return searchCompanyByName()
    .then((res) => {
      dispatch(setIsShowErrorMessageAlerts(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAlerts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const searchTickerByNameAsync = (name) => (dispatch) => {
  const searchTickerByName = axiosGet(
    `dictionaries/tickers?scope=tickerWithCompanyScope&index=0&limit=50&where[ticker][_iLike]=%${name}%`,
    {
      isNeedSpinner: false
    }
  );
  return searchTickerByName()
    .then((res) => {
      dispatch(setIsShowErrorMessageAlerts(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAlerts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const searchOfficerByNameAsync = (name, cik) => (dispatch) => {
  const searchOfficerByName = axiosGet(
    `insiders?scope=insiderFullScope&index=0&limit=50` +
      (name ? `&where[$person.person_name$][_iLike]=%${name}%` : '') +
      (cik ? `&where[cik]=${cik}` : ''),
    {
      isNeedSpinner: false
    }
  );
  return searchOfficerByName()
    .then((res) => {
      dispatch(setIsShowErrorMessageAlerts(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAlerts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const loadAlertSchemaAsync = (alertType, operationType) => (dispatch) => {
  const loadAlertSchema = axiosGet(`alerts/${alertType}/schema?operationType=${operationType}`);
  return loadAlertSchema()
    .then((res) => {
      dispatch(setIsShowErrorMessageAlerts(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAlerts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const updateAlertAsync = (id, data) => (dispatch) => {
  const updateAlert = axiosPut(`alerts/${id}`);
  return updateAlert({ data })
    .then((res) => {
      dispatch(setIsShowErrorMessageAlerts(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAlerts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const createAlertAsync = (type, data) => (dispatch) => {
  const createAlert = axiosPost('alerts');
  return createAlert({ type, data })
    .then((res) => {
      dispatch(setIsShowErrorMessageAlerts(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAlerts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const deleteAlertAsync = (id) => (dispatch) => {
  const deleteAlert = axiosDelete(`alerts/${id}`);
  return deleteAlert()
    .then((res) => {
      dispatch(setIsShowErrorMessageAlerts(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAlerts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const createAlertAsyncModal = (type, data) => (dispatch) => {
  const createAlert = axiosPost('alerts', {
    isNeedSpinner: false
  });
  return createAlert({ type, data })
    .then((res) => {
      dispatch(setIsShowErrorMessageAlerts(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAlerts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const deleteAlertAsyncModal = (id) => (dispatch) => {
  const deleteAlert = axiosDelete(`alerts/${id}`, {
    isNeedSpinner: false
  });
  return deleteAlert()
    .then((res) => {
      dispatch(setIsShowErrorMessageAlerts(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAlerts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const loadInsidersByCompanyAsync = (cik) => (dispatch) => {
  const loadInsidersByCompany = axiosGet(`insiders?scope=insiderFullScope&index=0&limit=50&where[cik]=${cik}`);
  return loadInsidersByCompany()
    .then((res) => {
      dispatch(setIsShowErrorMessageAlerts(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAlerts(axiosErrors(e)));
      throw axiosErrors(e);
    });
};
