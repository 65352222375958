export const backgroundPlugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const { ctx, chartArea, scales } = chart;
    ctx.clearRect(0, 0, chart.width, chart.height);
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';

    const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);

    for (let i = 0; i < scales.x.ticks.length - 1; i++) {
      const p = (scales.x.getPixelForTick(i) - chartArea.left) / chartArea.width;
      const color = '#' + (options.backgroundColors[scales.x.ticks[i].label] || 'fff');
      gradient.addColorStop(p, color);

      if (i) {
        const middle =
          scales.x.getPixelForTick(i) - (scales.x.getPixelForTick(i) - scales.x.getPixelForTick(i - 1)) / 2;
        const prevMiddle = (middle - chartArea.left) / chartArea.width;
        gradient.addColorStop(prevMiddle, color);
      }

      if (i !== scales.x.ticks.length - 1) {
        const middle =
          scales.x.getPixelForTick(i) + (scales.x.getPixelForTick(i + 1) - scales.x.getPixelForTick(i)) / 2;
        const nextMiddle = (middle - chartArea.left) / chartArea.width;
        gradient.addColorStop(nextMiddle, color);
      }
    }

    ctx.fillStyle = gradient;
    ctx.fillRect(chartArea.left, chartArea.top, chartArea.width, chartArea.height);
    ctx.restore();
  }
};
