import React, { useCallback, useState, useEffect } from 'react';

import { Steps, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { requestInvitationAsync } from 'src/app/api/notification';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { CustomDot } from 'src/app/components/steps/customDot';

import { SuccessStep } from './components/inviteSuccess';
import { RequestInvitationForm } from './components/requestInvitationForm';
import styles from './Invite.module.css';

const { Step } = Steps;

export const RequestInvitation = () => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    message.destroy();
  }, []);

  const handleSubmit = useCallback(
    (val) => {
      dispatch(requestInvitationAsync(val.email, val.message))
        .then(() => {
          setCurrent((prev) => prev + 1);
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    },
    [dispatch]
  );

  const steps = [
    {
      id: 'Form step',
      content: <RequestInvitationForm onSubmit={handleSubmit} />
    },
    {
      id: 'Success step',
      content: <SuccessStep />
    }
  ];

  const handleChange = (current) => {
    if (current === 0) {
      setCurrent(current);
    }
  };

  return (
    <div className="page">
      <h1 className="page__title-uppercase">{t('title_invitation_request')}</h1>
      <Steps progressDot={CustomDot} current={current} onChange={handleChange}>
        {steps.map((item) => (
          <Step key={item.id} />
        ))}
      </Steps>
      <div className={styles.stepsContent}>{steps[current].content}</div>
    </div>
  );
};
