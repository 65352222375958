import { axiosErrors, axiosPost } from '../helpers/axios';

const invite = axiosPost('account/invite');
const requestInvitation = axiosPost('notification/invite-request');
const changePassword = axiosPost('account/change-password');
const recoverPassword = axiosPost('account/recover-password');
export const inviteAsync = (email, recipient, extraInformation, roleId) => (dispatch) => {
  return invite({
    to: email,
    recipient,
    message: extraInformation,
    roleId
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const sendNoificationChangePasswordAsync = () => (dispatch) => {
  return changePassword({})
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const requestInvitationAsync = (email, message) => (dispatch) => {
  return requestInvitation({
    email,
    message
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const recoverPasswordLinkAsync = (email) => (dispatch) => {
  return recoverPassword({
    email
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};
