import React, { useEffect, useState, useCallback } from 'react';

import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { getObjectWithInterval } from '../../helpers';
import { Price } from '../price';
import { loadPriceInfoAsync, updateProductPriceAsync, loadPriceSubscribersAsync } from 'src/app/api/product';
import AppInput from 'src/app/components/form/input';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import './style.css';
import { setSpinnerVisible } from 'src/app/slices/commonSlice';
import { setIsNeedReload } from 'src/app/slices/productSlice';
import { ReactComponent as BackArrow } from 'src/assets/svg/admin/back-arrow.svg';

import styles from './TariffEdit.module.css';

export const TariffEdit = ({ currentProductName }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tariffId } = useParams();

  const [editTariffForm] = Form.useForm();
  const [fields, setFields] = useState(null);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);

  const [currentTariff, setCurrentTariff] = useState(null);
  const [newPrice, setNewPrice] = useState(null);
  const [subscribers, setSubscribers] = useState([]);
  const [searchSubscriber, setSearchSubscriber] = useState('');

  const loadPriceInfo = useCallback(() => {
    dispatch(setSpinnerVisible(true));
    dispatch(loadPriceInfoAsync(tariffId))
      .then((data) => {
        setCurrentTariff(data);
        dispatch(setSpinnerVisible(false));
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch, tariffId]);

  useEffect(() => {
    loadPriceInfo();
  }, [loadPriceInfo]);

  useEffect(() => {
    if (currentTariff) {
      setFields(currentTariff);
    }
    if (fields) {
      editTariffForm.setFields(
        Object.keys(fields).map((elem) => {
          return { name: elem, value: fields[elem] };
        })
      );
    }
  }, [fields, currentTariff, editTariffForm, dispatch]);

  const checkFieldsAreFilled = useCallback(() => {
    if (newPrice) {
      if (
        editTariffForm.getFieldValue('name') &&
        newPrice?.trial_period_days !== '' &&
        newPrice?.trial_period_days !== null
      ) {
        setDisabledSaveButton(false);
      } else {
        setDisabledSaveButton(true);
      }
    }
  }, [editTariffForm, newPrice]);

  useEffect(() => {
    if (newPrice) {
      checkFieldsAreFilled();
    }
  }, [checkFieldsAreFilled, newPrice]);

  useEffect(() => {
    dispatch(loadPriceSubscribersAsync(tariffId, searchSubscriber))
      .then((data) => {
        const { items } = data;
        setSubscribers(items);
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch, searchSubscriber, tariffId]);

  const setNamePrice = useCallback(() => {
    if (newPrice) {
      setNewPrice({
        ...newPrice,
        name: editTariffForm.getFieldValue('name')
      });
    } else {
      setNewPrice({
        ...currentTariff,
        name: editTariffForm.getFieldValue('name')
      });
    }
    checkFieldsAreFilled();
  }, [checkFieldsAreFilled, currentTariff, editTariffForm, newPrice]);

  const getNewDataFromPrice = useCallback((formData) => {
    const preparedData = getObjectWithInterval(formData);
    setNewPrice(preparedData);
  }, []);

  const updatePrice = useCallback(
    (id, data) => {
      dispatch(updateProductPriceAsync(id, data))
        .then(() => {})
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    },
    [dispatch]
  );

  const handleSeacrh = useCallback((val) => {
    setSearchSubscriber(val);
  }, []);

  const handleOnSaveButton = useCallback(() => {
    if (newPrice) {
      updatePrice(newPrice.id, {
        name: editTariffForm.getFieldValue('name'),
        active: newPrice.active,
        trial_period_days: newPrice.trial_period_days
      });
      setDisabledSaveButton(true);
    }
  }, [editTariffForm, newPrice, updatePrice]);

  return (
    <div className="subscribes-edit">
      <div className={styles.wrapHeader}>
        <div className={styles.header}>
          <div className={styles.headerLeftPart}>
            <Button
              onClick={() => {
                dispatch(setIsNeedReload(true));
                navigate(`/products/#${currentProductName || 'free'}`);
              }}
              className={`mg-btn _text ${styles.backBtn}`}
            >
              <BackArrow /> {t('all_products_btn')}
            </Button>
            <Form
              name="editNameTariff"
              form={editTariffForm}
              autoComplete="off"
              className="editTariffForm"
              onValuesChange={() => setNamePrice()}
            >
              <Form.Item
                className={styles.fieldName}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('required_input')
                  }
                ]}
              >
                <AppInput data-test="tariff_edit_page_title"></AppInput>
              </Form.Item>
            </Form>
          </div>
          <span className={styles.totalSubscribers}>
            {t('total_subscribers')}: {subscribers.length}
          </span>
        </div>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <div className={styles.prices}>
            <Price sendData={getNewDataFromPrice} values={currentTariff} edit={true} />
          </div>
          <Button
            onClick={handleOnSaveButton}
            className={`${styles.saveBtn} mg-btn _blue`}
            disabled={disabledSaveButton}
          >
            {t('save')}
          </Button>
        </div>

        <div className={styles.subscribers}>
          <AppInput
            className={styles.searchSubscribers}
            data-test="products_edit_page_search_subscribers"
            placeholder={t('search_subscribers_placeholder')}
            onPressEnter={handleSeacrh}
            onBlur={handleSeacrh}
          />
          <div className={styles.subscribersContent}>
            <PerfectScrollbar>
              <div className={styles.subscribers}>
                {subscribers.map((item, idx) => (
                  <p key={idx}>{item?.customer?.account?.email}</p>
                ))}
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};
