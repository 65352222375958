import React, { useEffect, useState } from 'react';

import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { getObjectWithPaidPeriod } from '../../helpers';
import { AppCheckbox } from 'src/app/components/form/checkbox';
import { AppInputNumber } from 'src/app/components/form/input-number';
import { AppSelect } from 'src/app/components/form/select';

import styles from './AddPrice.module.css';
import { currencyOptions, intervalOptions } from './constants';

import './style.css';

export const Price = ({ values, sendData, edit = false }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [fields, setFields] = useState(null);
  const priceInitialValues = {
    price: '',
    currency: '',
    paidPeriod: '',
    trial_period_days: ''
  };

  useEffect(() => {
    if (values) {
      const preparedData = getObjectWithPaidPeriod(values);
      setFields(preparedData);
    }
  }, [values]);

  useEffect(() => {
    if (fields) {
      form.setFields(
        Object.keys(fields).map((elem) => {
          return { name: elem, value: fields[elem] };
        })
      );
    }
  }, [fields, form]);

  const handleOnFieldsChange = () => {
    sendData({ id: values?.id, ...form.getFieldsValue() });
  };

  return (
    <>
      <div className={styles.header}>
        <span>{t('price')}</span>
      </div>
      <Form
        name="priceForm"
        form={form}
        initialValues={priceInitialValues}
        autoComplete="off"
        className="priceForm"
        onValuesChange={handleOnFieldsChange}
      >
        <Form.Item className="form-item" name="active" valuePropName="checked">
          <AppCheckbox data-test="products_page_add_price_active" label={t('active_product_label')} />
        </Form.Item>
        <Form.Item
          className={`formItem ${styles.field}`}
          name="paidPeriod"
          rules={[
            {
              required: true,
              message: t('required_input')
            }
          ]}
        >
          <AppSelect
            data-test="products_page_add_price_paid_period"
            options={intervalOptions}
            label={t('add_price_label_paid_period')}
            disabled={edit}
          />
        </Form.Item>
        <Form.Item
          className={`formItem ${styles.field}`}
          name="price"
          rules={[
            {
              required: true,
              message: t('required_input')
            }
          ]}
        >
          <AppInputNumber
            data-test="products_page_add_price_price"
            label={t('add_price_label_price')}
            disabled={edit}
          />
        </Form.Item>
        <Form.Item
          className={`formItem ${styles.field}`}
          name="currency"
          rules={[
            {
              required: true,
              message: t('required_input')
            }
          ]}
        >
          <AppSelect
            data-test="products_page_add_price_currency"
            options={currencyOptions}
            label={t('add_price_label_currency')}
            disabled={edit}
          />
        </Form.Item>
        <Form.Item
          className={`formItem ${styles.field}`}
          name="trial_period_days"
          rules={[
            {
              required: true,
              message: t('required_input')
            }
          ]}
        >
          <AppInputNumber data-test="products_page_add_price_trial_period" label={t('add_price_label_trial_period')} />
        </Form.Item>
      </Form>
    </>
  );
};
