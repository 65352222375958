import { axiosDelete, axiosErrors, axiosGet, axiosPost, axiosPut } from '../helpers/axios';

export const loadAccountsAsync = (index, limit, order, queryTemplate, selectedFilter) => () => {
  const accounts = axiosGet(
    `account/list?index=${index || 0}&limit=${limit || 10}${selectedFilter ? `&where[roleId]=${selectedFilter}` : ''}${
      queryTemplate ? `&where[email][_iLike]=%${queryTemplate}%` : ''
    }${
      order
        ? `&scope=accountInfo&order=${
            order.column === 'role'
              ? `${order.column}&order=title`
              : order.column === 'subscription'
              ? `customer&order=subscription&order=price&order=product&order=name`
              : order?.column
          }&order=${order.direction}`
        : ''
    }`
  );
  return accounts()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const setAccountRoleAsync = (id, roleId) => () => {
  const accounts = axiosPut(`account/${id}/role`);
  return accounts({ id, roleId })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const setInviteLimitAsync = (id, inviteLimit) => () => {
  const accounts = axiosPut(`account/${id}/config`);
  return accounts({ inviteLimit })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const setStatusBlockAsync = (id, blocked) => () => {
  const accounts = axiosPut(`account/${id}/blocked`);
  return accounts({ blocked })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const deleteUserAsync = (id) => () => {
  const accounts = axiosDelete(`account/${id}`);
  return accounts()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadGroupsListAsync = (index, limit, sort) => (dispatch) => {
  const loadGroupsList = axiosGet(
    `group?scope=listOfAccountGroup&index=${index || 0}&limit=${limit || 10}${
      sort
        ? `&order[0]=${
            sort.column === 'moderator'
              ? 'owner&order[0]=email'
              : sort.column === 'membersAmount'
              ? 'total'
              : sort.column
          }&order[0]=${sort.direction}`
        : ''
    }`
  );
  return loadGroupsList()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadGroupMembersAsync = (groupId, index, limit, sort) => (dispatch) => {
  const loadGroupMembers = axiosGet(
    `group/members?where[groupId]=${groupId}&index=${index || 0}&limit=${limit || 10}${
      sort
        ? `&order[0]=${sort.column !== 'email' ? `profile&order[0]=${sort.column}` : sort.column}&order[0]=${
            sort.direction
          }`
        : ''
    }`
  );
  return loadGroupMembers()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const deleteGroupAsync = (id) => (dispatch) => {
  const deleteGroup = axiosDelete(`group/${id}`);
  return deleteGroup()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const updateGroupAsync = (id, updatedGroup) => (dispatch) => {
  const updateGroup = axiosPut(`group/${id}`);
  return updateGroup({
    title: updatedGroup
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const addGroupAsync = (newGroup) => (dispatch) => {
  const addGroup = axiosPost(`group`);
  return addGroup(newGroup)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const changeGroupOwnerAsync = (groupId, ownerId) => (dispatch) => {
  const setGroupOwner = axiosPut(`group/${groupId}/set-owner`);
  return setGroupOwner({
    accountId: ownerId
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const excludeMemberFromGroupAsync = (memberId, id) => (dispatch) => {
  const excludeMemberFromGroup = axiosPut(`group/${id}/exclude`);
  return excludeMemberFromGroup({
    memberId: memberId
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadGroupInformationAsync = (id) => (dispatch) => {
  const loadGroupInformation = axiosGet(`group/${id}`);
  return loadGroupInformation()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const sendInviteInGroupAsync = (id, newMember) => (dispatch) => {
  const sendInviteInGroup = axiosPost(`group/${id}/invite`);
  return sendInviteInGroup({
    email: newMember.email,
    recipient: newMember.text
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const joinToGroupAsync = (hash, email) => (dispatch) => {
  const joinToGroup = axiosGet(`group/join/${hash}/${email}`);
  return joinToGroup()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};
