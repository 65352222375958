export const getRangeFilterValueWithUnit = (values, unit) => {
  const result = structuredClone(values);
  result[0] = Number.isInteger(unit[0]) && values[0] !== null ? values[0] * unit[0] : values[0];
  result[1] = Number.isInteger(unit[1]) && values[1] !== null ? values[1] * unit[1] : values[1];

  return result;
};

export const getValueFromRangeInputNumber = (number) => {
  if (number === undefined || number === null) {
    return null;
  }

  if (number % 1e9 === 0) {
    return number / 1e9;
  }

  if (number % 1e6 === 0) {
    return number / 1e6;
  }

  if (number % 1e3 === 0) {
    return number / 1e3;
  }

  return number;
};

export const getUnitFromRangeInputNumber = (number) => {
  if (number === undefined || number === null) {
    return null;
  }

  if (number === 0) {
    return null;
  }

  if (number % 1e9 === 0) {
    return 1e9;
  }

  if (number % 1e6 === 0) {
    return 1e6;
  }

  if (number % 1e3 === 0) {
    return 1e3;
  }

  return null;
};

export const roundNumberWithAbbrev = (number, decPlaces) => {
  decPlaces = 2;
  decPlaces = Math.pow(10, decPlaces);
  const abbrev = ['k', 'M', 'B', 'T'];
  for (let i = abbrev.length - 1; i >= 0; i--) {
    const size = Math.pow(10, (i + 1) * 3);
    if (number === 1) {
      return null;
    }
    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;
      if (number === 1000 && i < abbrev.length - 1) {
        number = '';
        i++;
      }
      number = abbrev[i];
      break;
    }
  }
  return number;
};
