import React, { useEffect, useState } from 'react';

import { TimePicker } from 'antd';
import './styles.css';

export const AppTimePicker = ({ value, onChange, ...rest }) => {
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleChange = (val) => {
    if (onChange) {
      onChange(val);
    }
    setVal(val);
  };

  return (
    <div className="mg-input-time__wrap mg-input__wrap">
      <TimePicker use12Hours className="mg-input" format="h:mm a" value={val} {...rest} onChange={handleChange} />
    </div>
  );
};
