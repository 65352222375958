import { createSlice } from '@reduxjs/toolkit';

import { LocalStorageHelper } from '../helpers/localstore';

const prepareAuth = () => {
  const token = LocalStorageHelper.getData();
  return {
    isAuthenticated: !!token,
    token
  };
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    code: 0,
    ...prepareAuth(),
    isShowErrorMessageAuth: false
  },
  reducers: {
    setAuthToken: (state, action) => {
      LocalStorageHelper.setData(action.payload);
      state.isAuthenticated = !!action.payload;
      state.token = action.payload;
    },
    signout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      LocalStorageHelper.clear();
    },
    setIsShowErrorMessageAuth: (state, action) => {
      state.isShowErrorMessageAuth = action.payload;
    },
    setStatusCode: (state, action) => {
      state.code = action.payload;
    }
  }
});

export const { setAuthToken, signout, setIsShowErrorMessageAuth, setStatusCode } = authSlice.actions;

export const selectToken = (state) => state.auth.token;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectIsShowErrorMessageAuth = (state) => state.auth.isShowErrorMessageAuth;
export const selectStatusCode = (state) => state.auth.code;

export default authSlice.reducer;
