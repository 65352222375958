import React, { useState, useCallback } from 'react';

import { Menu, Dropdown, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as VerticalDots } from 'src/assets/svg/vertical-dots.svg';

import styles from './RowMenu.module.css';

export const RowMenuScreenIdeas = ({ onClickElement }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const handleChange = useCallback((visible) => {
    setVisible(visible);
  }, []);

  return (
    <Dropdown
      onVisibleChange={handleChange}
      overlay={
        <Menu className={styles.dropWown}>
          <Menu.Item key="0">
            <Button
              data-test="ideas_page_add_to_portfolio"
              className={`mg-btn _text ${styles.buttonAlert}`}
              onClick={() => onClickElement('add_to_portfolio')}
            >
              {t('add_to_portfolio')}
            </Button>
          </Menu.Item>
          <Menu.Item key="1">
            <Button
              data-test="ideas_page_delete"
              className={`mg-btn _text ${styles.buttonAlert}`}
              onClick={() => onClickElement('delete_idea')}
            >
              {t('delete_idea')}
            </Button>
          </Menu.Item>
        </Menu>
      }
      trigger={['click']}
    >
      <a
        data-test="custom_page_screen_vertical_dots"
        className={`${styles.button} mg-btn _text ${visible ? styles.visible : ''}`}
        onClick={(e) => e.preventDefault()}
      >
        <VerticalDots />
      </a>
    </Dropdown>
  );
};
