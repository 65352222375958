import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './Sidebar.module.css';

export const SideBar = ({ items, onClick, selectedId }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <span>{t('sidebar_title')}</span>
      <ul className={styles.menu}>
        {items.map((item) => {
          if (item.submenu) {
            return (
              <li key={item.id} className={selectedId === item.id ? styles.selected : ''}>
                <span onClick={() => onClick(item.id)}>{t(t(item.title))}</span>
                <ul>
                  {item.submenu.map((elem) => (
                    <li key={elem.id} className={selectedId === elem.id ? styles.selected : ''}>
                      <span onClick={() => onClick(elem.id)}>{t(elem.title)}</span>
                    </li>
                  ))}
                </ul>
              </li>
            );
          } else {
            return (
              <li
                key={item.id}
                onClick={() => onClick(item.id)}
                className={selectedId === item.id ? styles.selected : ''}
              >
                <span>{t(item.title)}</span>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};
