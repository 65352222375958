import React, { useState, useEffect, useCallback } from 'react';

import { Modal, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import styles from '../../AccountAlerts.module.css';
import { positionColumns as positionColumnsInitial, alertView } from '../../consts';
import { definePersonPosition, defineTitleAlertColumn, colorResult } from '../../helpers';
import { RowMenuPostion } from '../table-row-menu-position/rowMenu';
import { InsiderCompanyAlertModel } from 'src/app//models/alerts';
import { loadAlertsListByViewAsync, createAlertAsync, deleteAlertAsync } from 'src/app/api/alerts';
import { AppSwitch } from 'src/app/components/form/switch';
import { AppSimpleTable } from 'src/app/components/table';
import { selectAlerts, selectInsidersAlerts, setInsidersAlerts } from 'src/app/slices/alertsSlice';

const { useBreakpoint } = Grid;

export const AccountInsidersTab = ({ queryField, queryTemplate, onShowModal, onLoad, handleError }) => {
  const [sort, setSort] = useState({ column: 'companyName', direction: 'ASC' });
  const [pagination, setPagination] = useState({
    limit: 10,
    index: 1,
    total: 0
  });
  const screens = useBreakpoint();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [positionColumns, setPositionColumns] = useState(positionColumnsInitial);
  const [tableData, setTableData] = useState([]);
  const alerts = useSelector(selectAlerts);
  const insidersAlerts = useSelector(selectInsidersAlerts);

  const createPreparedData = useCallback(() => {
    const preparedData = insidersAlerts.map((el) => {
      const newEl = { ...el, alerts: [...el.alerts], events: 'text' };
      alerts.forEach((alert) => {
        const isHasAlert = !!el.alerts.find((accAlert) => accAlert.type === alert);
        if (isHasAlert) {
          newEl[alert] = true;
        } else {
          newEl[alert] = false;
        }
      });
      return newEl;
    });
    return preparedData;
  }, [alerts, insidersAlerts]);

  const loadAlertsAsync = useCallback(
    (index) => {
      dispatch(
        loadAlertsListByViewAsync(
          alertView.insider,
          index === undefined ? pagination.index - 1 : index,
          pagination.limit,
          sort,
          queryField,
          queryTemplate
        )
      )
        .then((data) => {
          setPagination((prev) => ({
            ...prev,
            total: data.total
          }));
          const res = [
            ...data.items.map(
              (elem) =>
                new InsiderCompanyAlertModel({
                  ...elem,
                  personPosition: i18n.t(definePersonPosition(elem.is_director, elem.is_officer))
                })
            )
          ];
          dispatch(setInsidersAlerts({ alerts: res, total: data.totalAlerts }));
        })
        .catch((e) => {
          handleError(e);
        });
    },
    [dispatch, pagination.index, pagination.limit, queryField, queryTemplate, sort, i18n, handleError]
  );

  const sendDeleteRequest = useCallback(
    (id, index) => {
      dispatch(deleteAlertAsync(id))
        .then(() => {
          loadAlertsAsync(index);
        })
        .catch((e) => {
          handleError(e);
        });
    },
    [dispatch, loadAlertsAsync, handleError]
  );

  const deleteAlert = useCallback(
    (alertId, alertType) => {
      const company = insidersAlerts.find((el) => el.id === alertId);
      const deletedAlertId = company?.alerts.find((alert) => alert.type === alertType)?.id;
      if (company.alerts.length === 1) {
        Modal.confirm({
          title: t('alert_delete_warning_title'),
          content: t('alert_delete_warning_content'),
          className: `mg-modal _confirm`,
          getContainer: 'main',
          okButtonProps: { className: `mg-btn _green-border ${styles.button}` },
          cancelButtonProps: { className: `mg-btn ${styles.button}` },
          onOk: () => {
            const index = insidersAlerts.length === 1 ? pagination.index - 2 || 0 : pagination.index - 1;
            sendDeleteRequest(deletedAlertId, index);
          },
          onCancel: () => {
            loadAlertsAsync();
          }
        });
      } else {
        sendDeleteRequest(deletedAlertId, pagination.index - 1);
      }
    },
    [insidersAlerts, loadAlertsAsync, pagination.index, sendDeleteRequest, t]
  );

  const addAlert = useCallback(
    (alertType, alert) => {
      const schema = {
        companyCik: alert.companyCik,
        personId: alert.personId
      };
      dispatch(createAlertAsync(alertType, schema))
        .then((newAlert) => {
          loadAlertsAsync();
        })
        .catch((e) => {
          handleError(e);
        });
    },
    [dispatch, loadAlertsAsync, handleError]
  );

  const handleChangeEvent = useCallback(
    (val, alertType, alertId) => {
      switch (val) {
        case 'delete':
          Modal.confirm({
            title: t('alert_delete_warning_title'),
            content: t('alert_delete_warning_content'),
            className: `mg-modal _confirm`,
            getContainer: 'main',
            okButtonProps: { className: `mg-btn _green-border ${styles.button}` },
            cancelButtonProps: { className: `mg-btn ${styles.button}` },
            onOk: () => {
              const index = insidersAlerts.length === 1 ? pagination.index - 2 || 0 : pagination.index - 1;
              const company = insidersAlerts.find((el) => el.id === alertId);
              company?.alerts.forEach((alert) => {
                sendDeleteRequest(alert?.id, index);
              });
            }
          });
          break;
        case 'addCompany': {
          const company = insidersAlerts.find((el) => el.id === alertId);
          onShowModal('1', company.companyName, company.companyCik, company.tickers);
        }
      }
    },
    [insidersAlerts, onShowModal, pagination.index, sendDeleteRequest, t]
  );

  const handleChangeCheckboxEvent = useCallback(
    (val, alertType, alert) => {
      if (!val) {
        deleteAlert(alert.id, alertType);
      } else {
        addAlert(alertType, alert);
      }
    },
    [addAlert, deleteAlert]
  );

  const switchCol = useCallback(
    (alert) => {
      return {
        title: t(defineTitleAlertColumn(alert)),
        key: alert,
        dataIndex: alert,
        sorted: true,
        filtered: false,
        sortTitle: `alerts.type.${alert}`,
        editingDefault: true,
        editableCellComponent: AppSwitch,
        onChangeCell: handleChangeCheckboxEvent,
        width: '10%'
      };
    },
    [handleChangeCheckboxEvent, t]
  );

  const companyNameCol = useCallback(() => {
    return {
      title: t('company_label'),
      key: 'companyName',
      dataIndex: 'companyName',
      sorted: true,
      filtered: false,
      width: '30%',
      render: (text) => <span dangerouslySetInnerHTML={{ __html: colorResult(text) }}></span>
    };
  }, [t]);

  useEffect(() => {
    const resultCols = [companyNameCol(), ...positionColumnsInitial];
    for (let i = 1; i < alerts.length; i++) {
      if (i === alerts.length - 1) {
        resultCols.push(switchCol(alerts[i]), {
          title: '',
          key: 'events',
          dataIndex: 'events',
          sorted: false,
          filtered: false,
          width: '5%',
          additionalEventsComponent: RowMenuPostion,
          onChangeAdditionalEvent: handleChangeEvent
        });
      } else {
        resultCols.push(switchCol(alerts[i]));
      }
    }
    setPositionColumns(resultCols);
  }, [alerts, companyNameCol, handleChangeCheckboxEvent, handleChangeEvent, switchCol]);

  useEffect(() => {
    const preparedData = createPreparedData();
    setTableData(preparedData);
  }, [createPreparedData]);

  useEffect(() => {
    loadAlertsAsync();
  }, [loadAlertsAsync, onLoad]);

  const handleSort = useCallback((sort) => {
    setSort(sort);
  }, []);

  const handleChangePagination = useCallback((page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      index: page,
      limit: pageSize
    }));
  }, []);
  if (Object.keys(screens).length === 0) {
    return <></>;
  }
  return (
    <>
      <span className={styles.tableColumnsText}>{t('notify_me')}</span>
      <AppSimpleTable
        padding={screens.lg || screens.md ? '60px 106px 0 106px' : screens.sm ? '30px 60px 0 60px' : '0 0 0 0'}
        pagination={pagination}
        sort={sort}
        listOfData={tableData}
        onSort={handleSort}
        columns={positionColumns}
        onChangePagination={handleChangePagination}
      />
      {pagination.limit >= pagination.total ? <div style={{ paddingBottom: '71px' }}></div> : ''}
    </>
  );
};
