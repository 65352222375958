import React from 'react';

import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import 'moment/locale/ru';
import { App } from './App';
import { History, Store } from './app/store';
import './index.css';
import * as serviceWorker from './serviceWorker';
import './i18nextInit';

moment.locale('en');
ReactDOM.render(
  <Provider store={Store}>
    <Router history={History}>
      <ConfigProvider locale={enUS}>
        <App history={History} />
      </ConfigProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
