import React, { useCallback, useEffect, useState } from 'react';

import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import styles from '../AccountAlerts.module.css';
import { AppAutocomplete } from 'src/app/components/form/autocomplete';
import { useDebouse } from 'src/app/hooks/debounse';

export const AlertsForm = ({ value, onSubmit, onSearch, result }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const location = useLocation();
  const setAlertInitialValues = {
    company: ''
  };

  const [searchCompany, setSearchCompany] = useState('');
  const debouncedSearchCompany = useDebouse(searchCompany, 500);

  useEffect(() => {
    form.setFields([{ name: 'company', value: value }]);
  }, [form, value]);

  useEffect(() => {
    if (debouncedSearchCompany) {
      const value = form.getFieldsValue();
      onSearch(value.company);
    }
  }, [debouncedSearchCompany, form, onSearch]);

  const handleClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  const handleSelect = useCallback(
    (value, option) => {
      onSubmit(option.value, option.label);
      form.setFields([{ name: 'company', value: option.label }]);
    },
    [form, onSubmit]
  );

  const handleOnChange = useCallback(() => {
    const value = form.getFieldsValue();
    setSearchCompany(value.company);
  }, [form]);

  return (
    <div className={styles.formWrap}>
      <Form
        name="setAlert"
        form={form}
        initialValues={setAlertInitialValues}
        onFinish={onSubmit}
        autoComplete="off"
        className={styles.form}
      >
        <Form.Item className={styles.formItem} name="company">
          <AppAutocomplete
            data-test="alerts_page_company"
            result={result}
            placeholder={t('company_or_officer')}
            onChange={handleOnChange}
            onSelect={handleSelect}
            onClear={handleClear}
          ></AppAutocomplete>
        </Form.Item>
      </Form>
      <p className={styles.formText}>{location.hash === '#2' ? t('price_change_text') : t('position_change_text')}</p>
    </div>
  );
};
