import React from 'react';

import { Slider } from 'antd';

import './styles.css';

export const AppSlider = ({
  min,
  max,
  onChange,
  value,
  step = 1,
  showTooltip = true,
  leftLabel,
  rightLabel,
  ...rest
}) => {
  return (
    <div className="mg-slider_wrap">
      <span>{leftLabel}</span>
      <Slider
        min={min}
        max={max}
        onChange={onChange}
        value={value}
        step={step}
        tipFormatter={!showTooltip ? null : undefined}
        {...rest}
      />
      <span>{rightLabel}</span>
    </div>
  );
};
