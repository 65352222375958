export const positionColumns = [
  {
    title: 'name_label',
    key: 'personName',
    dataIndex: 'personName',
    sorted: true,
    filtered: false
  },
  {
    title: 'officer_director_label',
    key: 'personPosition',
    dataIndex: 'personPosition',
    sorted: true,
    sortTitle: 'is_officer',
    filtered: false,
    width: '10%'
  }
];

export const positionColumnsModal = [
  {
    title: '',
    key: 'personName',
    dataIndex: 'personName',
    sorted: false,
    filtered: false,
    width: '30%'
  },
  {
    title: 'officer_director_label',
    key: 'personPosition',
    dataIndex: 'personPosition',
    sorted: false,
    filtered: false
  }
];

export const priceColumns = [
  {
    title: 'company_label',
    key: 'companyName',
    dataIndex: 'companyName',
    sorted: true,
    filtered: false,
    width: '40%'
  },
  {
    title: 'ticker_label',
    key: 'tickerName',
    dataIndex: 'tickerName',
    sorted: true,
    filtered: false
  },
  {
    title: 'price_1_label',
    key: 'rangeStart',
    dataIndex: 'rangeStart',
    sorted: true,
    editingDefault: false,
    filtered: false,
    width: '20%'
  },
  {
    title: 'price_2_label',
    key: 'rangeEnd',
    dataIndex: 'rangeEnd',
    sorted: true,
    editingDefault: false,
    filtered: false,
    width: '20%'
  },
  {
    title: '',
    key: 'events',
    dataIndex: 'events',
    sorted: false,
    filtered: false,
    width: '1%'
  }
];

export const priceColumnsModal = [
  {
    title: 'ticker_label',
    key: 'tickerName',
    dataIndex: 'tickerName',
    sorted: false,
    filtered: false,
    width: '34%'
  },
  {
    title: 'price_one_label',
    key: 'rangeStart',
    dataIndex: 'rangeStart',
    sorted: false,
    editingDefault: false,
    filtered: false,
    width: '33%'
  },
  {
    title: 'price_two_label',
    key: 'rangeEnd',
    dataIndex: 'rangeEnd',
    sorted: false,
    editingDefault: false,
    filtered: false,
    width: '33%'
  }
];

export const alertView = {
  price: 'priceRange',
  insider: 'insider'
};

export const alertColumnTitlePositionTable = {
  insiderAcquiringOpenMarket: 'insider_acquiring_market_label',
  insiderSelling: 'insider_selling_label'
};

export const operationTypeSchema = {
  create: 'create',
  update: 'update'
};

export const typeSchema = {
  object: 'object'
};
