import React, { useEffect, useState } from 'react';

import { ReactComponent as DefaultIcon } from 'src/assets/svg/messages/default.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/svg/messages/error.svg';
import { ReactComponent as InfoIcon } from 'src/assets/svg/messages/info.svg';
import { ReactComponent as SuccessIcon } from 'src/assets/svg/messages/success.svg';
import { ReactComponent as WarningIcon } from 'src/assets/svg/messages/warning.svg';

import { typeMessage } from './constants';

export const DefineSvg = ({ type }) => {
  const [svgIcon, setSvgIcon] = useState(null);
  useEffect(() => {
    switch (type) {
      case typeMessage.DEFAULT:
        setSvgIcon(<DefaultIcon />);
        break;
      case typeMessage.ERROR:
        setSvgIcon(<ErrorIcon />);
        break;
      case typeMessage.INFO:
        setSvgIcon(<InfoIcon />);
        break;
      case typeMessage.SUCCESS:
        setSvgIcon(<SuccessIcon />);
        break;
      case typeMessage.WARNING:
        setSvgIcon(<WarningIcon />);
        break;
      default:
        setSvgIcon(<DefaultIcon />);
    }
  }, [type]);

  return svgIcon;
};
