import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SupportForm } from '../account/components/contactSupport/components/form';
import { loadSupportTopicsAsync } from 'src/app/api/support';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';

import styles from './Support.module.css';

export const ContactSupport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [topics, setTopics] = useState([]);

  useEffect(() => {
    dispatch(loadSupportTopicsAsync())
      .then((data) => {
        setTopics(
          data.map((item) => {
            return {
              label: item,
              value: item
            };
          })
        );
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch]);

  return (
    <div className="page">
      <h1 className="page__title-uppercase">{t('contact_support_title')}</h1>
      <div className={styles.supportForm}>
        <SupportForm topics={topics} />
      </div>
    </div>
  );
};
