import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isSpinnerVisible: false,
    systemSettings: null
  },
  reducers: {
    setSpinnerVisible: (state, action) => {
      state.isSpinnerVisible = action.payload;
    },
    setSystemSettings: (state, action) => {
      state.systemSettings = action.payload;
    }
  }
});

export const { setSpinnerVisible, setSystemSettings } = commonSlice.actions;
export const selectSpinnerVisible = (state) => state.common.isSpinnerVisible;
export const selectSystemSettings = (state) => state.common.systemSettings;

export default commonSlice.reducer;
