import React from 'react';

import { Routes, Route } from 'react-router-dom';

import { NotFound } from '../modules/not-found';

import { ProtectedRoute } from './protectedRoute';
import { PublicRoute } from './publicRoute';
import { routes } from './routes';

export const AppRoutes = ({ history }) => {
  history.listen((location, action) => {
    window.scrollTo(0, 0);
  });

  return (
    <Routes>
      {routes.map((route) =>
        route.isProtected ? (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            element={
              <ProtectedRoute isNeedHeader={route.isNeedHeader}>
                <route.component />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            element={
              <PublicRoute isNeedHeader={route.isNeedHeader}>
                <route.component />
              </PublicRoute>
            }
          />
        )
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
