export const getGraphicGradient = (datasetIndex) => {
  let chartLeft, chartRight, colors, savedGradient;

  return (context) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;

    if (!chartArea) {
      return;
    }

    const points = chart.getDatasetMeta(datasetIndex).data.filter((point) => !point.skip);
    const pointsColors = points.map((p) => p.$context?.raw.color);

    const left = points[0]?.x;
    const right = points[points.length - 1]?.x;

    if (!left || !right) {
      return;
    }

    if (
      savedGradient &&
      chartLeft === left &&
      chartRight === right &&
      colors.length === pointsColors.length &&
      colors.every((color, i) => color === pointsColors[i])
    ) {
      return savedGradient;
    }

    const gradient = ctx.createLinearGradient(left, 0, right, 0);

    for (let i = 0; i < points.length; i++) {
      const currentCoord = points[i].x;
      const p = (currentCoord - left) / (right - left);
      const color = pointsColors[i];
      gradient.addColorStop(p, color);
      if (i) {
        const middleCoord = currentCoord - (currentCoord - points[i - 1].x) / 2;
        const p = (middleCoord - left) / (right - left);
        gradient.addColorStop(p, color);
      }
      if (i !== points.length - 1) {
        const middleCoord = currentCoord + (points[i + 1].x - currentCoord) / 2;
        const p = (middleCoord - left) / (right - left);
        gradient.addColorStop(p, color);
      }
    }

    savedGradient = gradient;
    colors = [...pointsColors];
    chartLeft = left;
    chartRight = right;

    return gradient;
  };
};
