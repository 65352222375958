import React from 'react';

import './styles.css';
import { ReactComponent as ArrowSort } from 'src/assets/svg/arrowSort.svg';

export const AppTableSort = ({ sort, isActive }) => {
  return (
    <div className="table-sort-wrap">
      <ArrowSort className={`${isActive && sort.direction === 'ASC' ? '_active' : ''}`} />{' '}
      <ArrowSort className={`table-sort-down ${isActive && sort.direction === 'DESC' ? '_active' : ''}`} />
    </div>
  );
};
