import React, { useCallback, useRef, useState } from 'react';

import { Form, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { loadArticleListItemAsync, putArticleListItemAsync, putArticlePreviewAsync } from 'src/app/api/resources';
import { AppSelect } from 'src/app/components/form/select';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';

import styles from './Form.module.css';
import './style.css';

const { TextArea } = Input;

export const Editor = ({ topics }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [currentArticle, setCurrentArticle] = useState({
    id: '',
    text: '',
    name: '',
    word: ''
  });
  const [preview, setPreview] = useState();
  const previewRef = useRef();
  previewRef.current = preview;

  const handleSubmit = useCallback(
    (val) => {
      currentArticle.text = val.text;
      dispatch(putArticleListItemAsync(currentArticle))
        // @ts-ignore
        .then((item) => {
          setCurrentArticle({ id: item.id, text: item.definition, name: item.name, word: item.word });
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    },
    [currentArticle, dispatch]
  );

  const handlePreview = useCallback(
    (changed, item) => {
      if (changed === false) {
        // @ts-ignore
        dispatch(putArticlePreviewAsync(item))
          // @ts-ignore
          .then((definition) => {
            setPreview(definition);
          })
          .catch((e) => {
            appMessage(typeMessage.ERROR, e.message);
          });
      } else {
        // @ts-ignore
        const text = form.getFieldValue().text;
        dispatch(putArticlePreviewAsync(text))
          // @ts-ignore
          .then((definition) => {
            setPreview(definition);
            setCurrentArticle({ ...currentArticle, text: text });
          })
          .catch((e) => {
            appMessage(typeMessage.ERROR, e.message);
          });
      }
    },
    [dispatch, currentArticle, form]
  );

  const handleChange = useCallback(
    (val) => {
      const id = topics.find((el) => el.value === val).id;
      dispatch(loadArticleListItemAsync(id))
        // @ts-ignore
        .then((item) => {
          setCurrentArticle({ id: item.id, text: item.definition, name: item.name, word: item.word });
          handlePreview(false, item.definition);
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    },
    [dispatch, topics, handlePreview]
  );

  return (
    <Form
      name="requestSupport"
      form={form}
      onFinish={handleSubmit}
      fields={[
        {
          name: ['text'],
          value: currentArticle.text
        }
      ]}
      autoComplete="off"
      className={`${styles.supportForm} support`}
    >
      <Form.Item
        name="id"
        className={styles.formItem}
        rules={[
          {
            required: true,
            message: t('required_input')
          }
        ]}
      >
        <AppSelect
          data-test="profile_page_select_topics"
          placeholder={t('resources_select_placeholder')}
          onChange={handleChange}
          options={topics}
        />
      </Form.Item>

      <div className={styles.inline}>
        <Form.Item
          name="text"
          className={styles.formTextArea}
          rules={[
            {
              required: true,
              message: t('required_input')
            }
          ]}
        >
          <TextArea
            className="mg-input-textarea"
            data-test="profile_page_message"
            placeholder={'HTML format'}
            rows={8}
          />
        </Form.Item>
        <div className={styles.preview} dangerouslySetInnerHTML={{ __html: previewRef.current }}></div>
      </div>
      <Form.Item shouldUpdate className={styles.formItem}>
        {() => (
          <>
            <div className={styles.inline}>
              <Button
                data-test="profile_page_send_support"
                htmlType="submit"
                className={`mg-btn`}
                disabled={
                  !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                }
              >
                Save
              </Button>
              <br></br>
              <Button onClick={handlePreview} htmlType="button" className={`mg-btn ${styles.btn}`}>
                Preview
              </Button>
            </div>
          </>
        )}
      </Form.Item>
    </Form>
  );
};
