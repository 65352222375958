import Axios from 'axios';

import { setupAxiosInterceptors } from './interceptors';
import { LocalStorageHelper } from './localstore';

setupAxiosInterceptors();

const makeUrl = (path) => {
  const parts = [process.env.REACT_APP_API_ENDPOINT];
  if (path) {
    parts.push(path.trim().replace(/(^\/+)|(\/+$)/g, ''));
  }
  return parts.join('/');
};

const refreshAuthToken = () => {
  const token = LocalStorageHelper.getData();
  const headers = {};
  if (token) {
    headers['x-auth-token'] = `${token}`;
  }
  Axios.defaults.headers.common = headers;
};

/** @type {(e: object) => Errors.RequestError} */
export const axiosErrors = (e) => {
  let { message } = e;
  const { response } = e;
  if (!response) {
    return { status: 400, message: message || 'Error', data: {} };
  }

  const { data } = response;
  const { status } = response;
  if (data) {
    const { detail } = data;
    if (detail?.data) {
      message = '';
      const keyErrors = Object.keys(detail?.data);
      keyErrors.forEach((key) => {
        message = detail.data[key].join();
      });
    } else {
      message = data.message;
    }
  }
  return { status, message: message || 'Error', data: data?.detail || {} };
};

export const axiosGet = (path, options = { isNeedSpinner: true }) => {
  const url = makeUrl(path);
  return (params) => {
    refreshAuthToken();
    return Axios.get(url, { params, ...options });
  };
};

export const axiosPost = (path, options = { isNeedSpinner: true }) => {
  const url = makeUrl(path);
  return (body, reqOptions = {}) => {
    return Axios.post(url, body, { ...options, ...reqOptions });
  };
};

export const axiosPut = (path, options = { isNeedSpinner: true }) => {
  const url = makeUrl(path);
  return (body) => {
    return Axios.put(url, body, { ...options });
  };
};

export const axiosPatch = (path, options = { isNeedSpinner: true }) => {
  const url = makeUrl(path);
  return (body) => {
    return Axios.patch(url, body, { ...options });
  };
};

export const axiosDelete = (path, options = { isNeedSpinner: true }) => {
  const url = makeUrl(path);
  return (params) => {
    return Axios.delete(url, { params, ...options });
  };
};
