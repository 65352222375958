import React, { useCallback, useState, useEffect } from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';

import styles from '../../Products.module.css';
import AppInput from 'src/app/components/form/input';

export const TableFilterPanel = ({ selectedFilter, onSearch, onSelectFilter, product }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchValue('');
  }, [location]);

  const handleOnCreateProduct = useCallback(() => {
    if (product) {
      navigate(`create-tariff/${product.id}/#${product?.name.toLowerCase()}`);
    }
  }, [navigate, product]);

  const handleOnChangeSearchInput = useCallback((value) => {
    setSearchValue(value);
  }, []);

  return (
    <div className={`${styles.filterPanel} filterPanel`}>
      <div className={styles.filterPanelLeftPart}>
        <Button onClick={handleOnCreateProduct} className={`mg-btn _text ${styles.addProductBtn}`}>
          {t('add_new_tariff_btn')}
        </Button>
        <div className={styles.search}>
          <AppInput
            placeholder={t('search_product_placeholder')}
            value={searchValue}
            onChange={handleOnChangeSearchInput}
            onPressEnter={onSearch}
            onBlur={onSearch}
          ></AppInput>
        </div>
      </div>

      <div className={styles.showLines}>
        <span className={styles.filtersLineTitle}>{t('filter_line_title')} </span>
        <div className={styles.filtersLine}>
          <Button
            className={`mg-btn _text ${styles.filterBtn} ${
              selectedFilter === 'active' ? styles.filterBtnSelected : ''
            }`}
            onClick={() => onSelectFilter('active')}
          >
            {t('subscribe_filter_active')}
          </Button>
          <Button
            className={`mg-btn _text ${styles.filterBtn} ${
              selectedFilter === 'archive' ? styles.filterBtnSelected : ''
            }`}
            onClick={() => onSelectFilter('archive')}
          >
            {t('subscribe_filter_archive')}
          </Button>
        </div>
      </div>
    </div>
  );
};
