import React, { useEffect, useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { signupAsync, signinAsync } from '../../api/auth';
import { getProfileAsync } from 'src/app/api/profile';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { setAuthToken } from 'src/app/slices/authSlice';
import { setProfile } from 'src/app/slices/profileSlice';

import { SignUpForm } from './components/form/index';

export const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const { hash } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    setEmail(params.get('to'));
  }, []);

  const loadProfile = useCallback(() => {
    dispatch(getProfileAsync())
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch(() => {
        dispatch(setProfile(null));
      });
  }, [dispatch]);

  const handleSubmit = useCallback(
    (formData) => {
      dispatch(signupAsync(hash, formData))
        .then((data) => {
          dispatch(signinAsync(formData.email, formData.password))
            .then((res) => {
              dispatch(setAuthToken(res.token));
              loadProfile();
              navigate('/');
            })
            .catch((e) => {
              appMessage(typeMessage.ERROR, e.message);
            });
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    },
    [dispatch, hash, loadProfile, navigate]
  );

  return (
    <div className="page">
      <h1 className="page__title-uppercase">{t('title_sign_up')}</h1>
      <SignUpForm email={email} onSubmit={handleSubmit} />
    </div>
  );
};
