import React from 'react';

import { Checkbox } from 'antd';
import './styles.css';

export const AppCheckbox = ({ label, value, onChange, className, ...rest }) => {
  const handleChange = (val) => {
    if (onChange) {
      onChange(val);
    }
  };
  return (
    <div className="mg-input-checkbox__wrap">
      <Checkbox
        checked={value}
        className={`mg-checkbox-input ${className}`}
        onChange={(e) => handleChange(e.target.checked)}
        {...rest}
      >
        {label}
      </Checkbox>
    </div>
  );
};
