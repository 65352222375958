import { createSlice } from '@reduxjs/toolkit';

const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    insidersAlerts: [],
    insidersAlertsTotal: 0,
    priceAlerts: [],
    priceAlertsTotal: 0,
    alerts: [],
    isShowErrorMessageAlerts: false,
    notifictaions: [],
    polls: []
  },
  reducers: {
    setInsidersAlerts: (state, action) => {
      state.insidersAlerts = action.payload.alerts;
      state.insidersAlertsTotal = action.payload.total;
    },
    setPriceCompanyAlerts: (state, action) => {
      state.priceAlerts = action.payload.alerts;
      state.priceAlertsTotal = action.payload.total;
    },
    setAlerts: (state, action) => {
      state.alerts = action.payload;
    },
    setIsShowErrorMessageAlerts: (state, action) => {
      state.isShowErrorMessageAlerts = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifictaions = action.payload;
    },
    setPolls: (state, action) => {
      state.notifictaions = action.payload;
    }
  }
});

export const { setInsidersAlerts, setIsShowErrorMessageAlerts, setAlerts, setPriceCompanyAlerts } = alertsSlice.actions;

export const selectInsidersAlerts = (state) => state.alerts.insidersAlerts;
export const selectInsidersAlertsTotal = (state) => state.alerts.insidersAlertsTotal;
export const selectPriceAlerts = (state) => state.alerts.priceAlerts;
export const selectPriceAlertsTotal = (state) => state.alerts.priceAlertsTotal;
export const selectAlerts = (state) => state.alerts.alerts;
export const selectNotifications = (state) => state.alerts.notifictaions;
export const selectPolls = (state) => state.alerts.polls;
export const selectIsShowErrorMessageAlerts = (state) => state.alerts.isShowErrorMessageAlerts;

export default alertsSlice.reducer;
