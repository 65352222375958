/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Modal, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { SetPositionForm } from './components/set-position-form/index';
import { SetPriceForm } from './components/set-price-form/index';
import styles from './SetAlertModal.module.css';

const { TabPane } = Tabs;

export const SetAlert = ({
  visible,
  loadAlerts,
  companyName,
  companyCik,
  defaultActiveKey,
  alertsNumber,
  handleError
}) => {
  const { t } = useTranslation();

  const [restAlertsCount, setRestAlertsCount] = useState(alertsNumber);
  const restAlertsCountRef = useRef();
  restAlertsCountRef.current = restAlertsCount;

  useEffect(() => {
    setRestAlertsCount(alertsNumber);
  }, [alertsNumber]);

  const handleUpdateAlertsList = useCallback((type) => {
    switch (type) {
      case 'delete':
        setRestAlertsCount((prev) => prev + 1);
        break;
      default:
        setRestAlertsCount((prev) => prev - 1);
    }
  }, []);

  const handleUpdatePriceAlertsList = useCallback((type) => {
    switch (type) {
      case 'add':
        setRestAlertsCount((prev) => prev - 1);
        break;
      case 'delete':
        setRestAlertsCount((prev) => prev + 1);
        break;
    }
  }, []);

  return (
    <Modal
      title={t('set_alert_btn')}
      centered
      width={800}
      visible={visible}
      forceRender
      className={`mg-modal`}
      okText={t('ok')}
      cancelText={t('cancel')}
      getContainer={'main'}
      okButtonProps={{
        className: `mg-btn ${styles.button}`
      }}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={loadAlerts}
      onCancel={loadAlerts}
    >
      <p className={styles.companyName}>{companyName}</p>
      <div className={`${styles.tabsWrap} tabs-alerts-modal`}>
        <p className={styles.tabsText}>
          {t('set_alerts_text')}
          <b>&nbsp;{restAlertsCount}</b>
        </p>
        {defaultActiveKey && visible ? (
          <Tabs defaultActiveKey={defaultActiveKey}>
            <TabPane className={styles.tab} tab={t('alerts_tab_position_title')} key="1">
              <SetPositionForm
                handleUpdateAlertsList={handleUpdateAlertsList}
                companyCik={companyCik}
                handleError={handleError}
              />
            </TabPane>
            <TabPane className={styles.tab} tab={t('alerts_tab_price_title')} key="2">
              <SetPriceForm
                onUpdatePriceAlertsList={handleUpdatePriceAlertsList}
                companyCik={companyCik}
                handleError={handleError}
              />
            </TabPane>
          </Tabs>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};
