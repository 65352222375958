export const sidebarMenu = [
  {
    id: 'title-1',
    title: 'why_mg_title'
  },
  {
    id: 'title-2',
    title: 'machine_grading_title',
    submenu: [
      {
        id: 'subtitle-1',
        title: 'side_bar_investment_ideas'
      },
      {
        id: 'subtitle-2',
        title: 'side_bar_use_profits'
      },
      {
        id: 'subtitle-3',
        title: 'side_bar_features'
      }
    ]
  }
];
