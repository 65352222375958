import i18n, { use, init } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import translationENAccount from './assets/locales/en/account.json';
import translationENAdmin from './assets/locales/en/admin.json';
import translationENAlerts from './assets/locales/en/alerts.json';
import translationENBackMessages from './assets/locales/en/back-messages.json';
import translationENButtons from './assets/locales/en/buttons.json';
import translationENCompany from './assets/locales/en/company.json';
import translationENFooter from './assets/locales/en/footer.json';
import translationENForm from './assets/locales/en/form.json';
import translationENHome from './assets/locales/en/home.json';
import translationENIdeas from './assets/locales/en/ideas.json';
import translationENMenu from './assets/locales/en/menu.json';
import translationENNotifications from './assets/locales/en/polls-and-notifications.json';
import translationENPortfolio from './assets/locales/en/portfolio.json';
import translationENPrivacyPolicy from './assets/locales/en/privacy-policy.json';
import translationENProduct from './assets/locales/en/product.json';
import translationENScreenings from './assets/locales/en/screenings.json';
import translationENSystem from './assets/locales/en/system-setting.json';
import translationENTermsAndConditions from './assets/locales/en/terms-and-conditions.json';
import translationEN from './assets/locales/en/translation.json';
import translationENWhyMG from './assets/locales/en/why-mg.json';

const fallbackLng = ['en'];
const availableLanguages = ['en'];

const resources = {
  en: {
    translation: {
      ...translationEN,
      ...translationENButtons,
      ...translationENMenu,
      ...translationENAccount,
      ...translationENCompany,
      ...translationENSystem,
      ...translationENHome,
      ...translationENIdeas,
      ...translationENForm,
      ...translationENAdmin,
      ...translationENProduct,
      ...translationENScreenings,
      ...translationENAlerts,
      ...translationENPortfolio,
      ...translationENPrivacyPolicy,
      ...translationENTermsAndConditions,
      ...translationENFooter,
      ...translationENWhyMG,
      ...translationENNotifications,
      ...translationENBackMessages
    }
  }
};

use(Backend);
use(LanguageDetector);
use(initReactI18next);
init({
  resources,
  fallbackLng,

  detection: {
    checkWhitelist: true
  },

  debug: false,

  whitelist: availableLanguages,

  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: false
  }
});

export default i18n;
