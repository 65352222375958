import { createSlice } from '@reduxjs/toolkit';

import { roles, subscriptions, subscriptionsIdMapping } from 'src/app/models/role';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    profile: null,
    isShowErrorMessageProfile: false
  },
  reducers: {
    setProfile: (state, action) => {
      const profile = action.payload;
      const hasSubscription = profile?.customer?.subscription;
      const hasRoleWithFullAccess = [roles.Administrator, roles.Influencer].includes(profile?.roleId);
      const subscriptionPriceId = profile?.customer?.subscription?.price?.product?.id;
      const subscription = subscriptionsIdMapping[subscriptionPriceId];
      const isSubscriptionFree = subscription === subscriptions.Free;
      const isSubscriptionFull = subscription === subscriptions.Full;
      state.profile = profile
        ? {
            ...profile,
            hasAccessToFinancialModel: hasRoleWithFullAccess || isSubscriptionFull,
            hasFreeSubscriptionAccess: !hasRoleWithFullAccess && hasSubscription ? isSubscriptionFree : false,
            hasNoSubscriptionAccess: !hasRoleWithFullAccess && (profile?.roleId === roles.Guest || !hasSubscription)
          }
        : null;
    },
    logout: (state) => {
      state.profile = null;
    },
    setIsShowErrorMessageProfile: (state, action) => {
      state.isShowErrorMessageProfile = action.payload;
    }
  }
});

export const { setProfile, setIsShowErrorMessageProfile, logout } = profileSlice.actions;
export const selectProfile = (state) => state.profile.profile;
export default profileSlice.reducer;
