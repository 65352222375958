import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { Header } from '../../../header';
import { FloatingWindow } from '../floatingWindow';
import { MessageModal } from '../messageModal';
import { loadSavedModelAsync, interactModelAsync } from 'src/app/api/financial-model';
import { ModelChart } from 'src/app/components/modelChart/indexTest';
import { AppSpinner } from 'src/app/components/spinner/spinner-component';
import {
  setModel,
  setSavedUserData,
  setSavedDepth,
  setInteractions,
  setMessage,
  selectDrawing,
  selectInteractions,
  selectModel,
  selectMessage,
  resetFinancialModel
} from 'src/app/slices/financialModelSlice';

import styles from './advanceMode.module.css';

const minPointsDistance = 20;

export const AdvancedMode = ({ handleError }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const navigate = useNavigate();

  const financialModel = useSelector(selectModel);
  const drawing = useSelector(selectDrawing);
  const interactions = useSelector(selectInteractions);
  const message = useSelector(selectMessage);

  const [pointsDistance, setPointsDistance] = useState(0);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(true);

  useEffect(() => {
    dispatch(resetFinancialModel());
    setIsSpinnerVisible(true);
    dispatch(loadSavedModelAsync(id))
      // @ts-ignore
      .then((savedData) => {
        dispatch(setSavedUserData(savedData.items[0]?.value));
        dispatch(setSavedDepth(savedData.depth));
        dispatch(
          interactModelAsync({
            interactions: {
              depth: savedData.depth || 4
            },
            user_data: {
              cik: id,
              ...savedData.items[0]?.value
            }
          })
        )
          // @ts-ignore
          .then((model) => {
            dispatch(setModel(model));
            dispatch(setMessage(model.interactions));
          })
          .catch((err) => {
            handleError(err);
          });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setIsSpinnerVisible(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, handleError]);

  useEffect(() => {
    if (interactions) {
      dispatch(
        interactModelAsync({
          ...financialModel,
          interactions
        })
      )
        // @ts-ignore
        .then((model) => {
          dispatch(setInteractions(null));
          dispatch(setModel(model));
          dispatch(setMessage(model.interactions));
        })
        .catch((err) => {
          handleError(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, interactions, handleError]);

  const legendWidth = useMemo(() => {
    if (!drawing.dg_legend_width || !drawing.gr_legend_width) {
      return;
    }

    return Math.max(parseInt(drawing.dg_legend_width.slice(0, -1)), parseInt(drawing.gr_legend_width.slice(0, -1)));
  }, [drawing]);

  useEffect(() => {
    if (!legendWidth || !Object.keys(drawing).length) {
      return;
    }

    const windowWidth = window.innerWidth;
    const modelWidth = windowWidth * (drawing.total_width.slice(0, -1) / 100);
    const chartWidth = modelWidth * ((100 - legendWidth) / 100);
    const dist = drawing.last_year - drawing.first_year;

    setPointsDistance(chartWidth / dist);
  }, [drawing, legendWidth]);

  const navigateToFiancialModel = useCallback(() => {
    const state = 'model';
    navigate(`/company/overview/${id}`, { state });
  }, [navigate, id]);
  return (
    <>
      <Header currentView={'model'} handleError={handleError} advanced={true} />
      <div className={styles.modelSection}>
        {isSpinnerVisible ? (
          <div className={styles.spinnerWrap}>
            <AppSpinner />
          </div>
        ) : (
          <>
            <FloatingWindow advanced={true} />
            <span className={styles.closeBtn} onClick={navigateToFiancialModel}>
              Close Advanced
            </span>
            {drawing.diagrams && (
              <>
                <div className={styles.inline}>
                  <div className={styles.elem}>
                    <ModelChart
                      diagram={drawing.diagrams[1]}
                      start={drawing?.first_year}
                      end={drawing?.last_year}
                      legendWidth={legendWidth}
                      isColumnWrapper={pointsDistance < minPointsDistance}
                    />
                  </div>
                  <div className={styles.elem}>
                    <ModelChart
                      diagram={drawing.diagrams[0]}
                      start={drawing?.first_year}
                      end={drawing?.last_year}
                      legendWidth={legendWidth}
                      isColumnWrapper={pointsDistance < minPointsDistance}
                    />
                  </div>
                </div>
                <div className={styles.inline}>
                  <div className={styles.elem}>
                    <ModelChart
                      diagram={drawing.diagrams[2]}
                      start={drawing?.first_year}
                      end={drawing?.last_year}
                      legendWidth={legendWidth}
                      isColumnWrapper={pointsDistance < minPointsDistance}
                    />
                  </div>
                  <div className={styles.elem}>
                    <ModelChart
                      diagram={drawing.diagrams[4]}
                      start={drawing?.first_year}
                      end={drawing?.last_year}
                      legendWidth={legendWidth}
                      isColumnWrapper={pointsDistance < minPointsDistance}
                    />
                  </div>
                </div>
                <div className={styles.inline}>
                  <div className={styles.elem}>
                    <ModelChart
                      diagram={drawing.diagrams[3]}
                      start={drawing?.first_year}
                      end={drawing?.last_year}
                      legendWidth={legendWidth}
                      isColumnWrapper={pointsDistance < minPointsDistance}
                    />
                  </div>
                  <div className={styles.elem}>
                    <ModelChart
                      diagram={drawing.diagrams[5]}
                      start={drawing?.first_year}
                      end={drawing?.last_year}
                      legendWidth={legendWidth}
                      isColumnWrapper={pointsDistance < minPointsDistance}
                    />
                  </div>
                </div>
                <div className={styles.inline}>
                  <div className={styles.elem}>
                    <ModelChart
                      diagram={drawing.diagrams[7]}
                      start={drawing?.first_year}
                      end={drawing?.last_year}
                      legendWidth={legendWidth}
                      isColumnWrapper={pointsDistance < minPointsDistance}
                    />
                  </div>
                  <div className={styles.elem}>
                    <ModelChart
                      diagram={drawing.diagrams[6]}
                      start={drawing?.first_year}
                      end={drawing?.last_year}
                      legendWidth={legendWidth}
                      isColumnWrapper={pointsDistance < minPointsDistance}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <MessageModal
        visible={message.isVisible}
        onClose={() => dispatch(setMessage({ message: null }))}
        isSuccessModal={message.isSuccess}
        messageText={message.text}
      />
    </>
  );
};
