import { axiosDelete, axiosErrors, axiosGet, axiosPost, axiosPut } from '../helpers/axios';

export const addToIdeasAsync = (tickerId) => (dispatch) => {
  const addToIdeas = axiosPut('ideas/collection');
  return addToIdeas({
    ticker_id: tickerId
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const deleteFromIdeasAsync = (tickerId) => (dispatch) => {
  const deleteFromIdeas = axiosDelete(`ideas/collection/${tickerId}`);
  return deleteFromIdeas()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadAccountIdeasAsync =
  (options = { isNeedSpinner: true }) =>
  (dispatch) => {
    const loadAccountIdeas = axiosGet('ideas/collection', options);
    return loadAccountIdeas()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };

export const loadIdeasInScreeningAsync =
  (index, limit, sort, options = { isNeedSpinner: false }) =>
  (dispatch) => {
    // const loadIdeasInScreening = axiosPost(
    //   `screenings/${id}/idea?index=${index || 0}&limit=${limit || 10}${sort ? generateSortString(sort) : ''}`,
    //   options
    // );
    const loadIdeasInScreening = axiosPost(
      `ideas?index=${index || 0}&limit=${limit || 10}${sort ? generateSortString(sort) : ''}`,
      options
    );
    return loadIdeasInScreening()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };

const generateSortString = (sort) => {
  return sort.reduce((result, next, index) => {
    return (result += `&order[${index}]=${next.column}&order[${index}]=${next.direction}`);
  }, '');
};
