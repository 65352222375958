import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { getProfileAsync } from '../../api/profile';
import { selectIsAuthenticated } from '../../slices/authSlice';
import { setProfile, selectProfile } from '../../slices/profileSlice';
import { loadNames } from 'src/app/api/names';
import { loadPortfoliosAsync } from 'src/app/api/portfolio';
import { loadScreeningDictAsync } from 'src/app/api/screen';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { setPortfolios, selectPortfolios } from 'src/app/slices/portfolioSlice';
import {
  setScreeningsDictionary,
  selectScreeningsDictionary,
  selectNames,
  setNames
} from 'src/app/slices/screeningSlice';

const ProfileLoader = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [prevRoute, setPrevRoute] = useState();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const profile = useSelector(selectProfile);
  const screenDictionary = useSelector(selectScreeningsDictionary);
  const screenNames = useSelector(selectNames);
  // const optionDictionary = useSelector(selectOptionsDictionary);
  const portfolios = useSelector(selectPortfolios);

  useEffect(() => {
    if (isAuthenticated && prevRoute !== location.pathname) {
      dispatch(getProfileAsync())
        .then((data) => {
          dispatch(setProfile(data));
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
          dispatch(setProfile(null));
        });
    }
    setPrevRoute(location.pathname);
  }, [isAuthenticated, dispatch, location, prevRoute]);

  useEffect(() => {
    if (profile && isAuthenticated && !screenDictionary.length) {
      dispatch(loadScreeningDictAsync(null, 100, null, null, { isNeedSpinner: false }))
        .then((res) => {
          const { items, total } = res;
          dispatch(
            setScreeningsDictionary({
              screenings: items,
              total
            })
          );
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    }
  }, [dispatch, isAuthenticated, profile, screenDictionary.length]);

  useEffect(() => {
    if (profile && isAuthenticated && !screenNames.length) {
      dispatch(loadNames)
        .then((res) => {
          dispatch(setNames(res));
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    }
  }, [dispatch, isAuthenticated, profile, screenNames.length]);

  // useEffect(() => {
  //   if (profile && isAuthenticated && !optionDictionary.length) {
  //     dispatch(loadScreeningOptionDictAsync())
  //       .then((res) => {
  //         dispatch(setOptionsDictionary(res));
  //       })
  //       .catch((e) => {
  //         appMessage(typeMessage.ERROR, e.message);
  //       });
  //   }
  // }, [dispatch, isAuthenticated, profile, optionDictionary.length]);

  useEffect(() => {
    if (profile && isAuthenticated && !portfolios.length) {
      dispatch(loadPortfoliosAsync(0, 10, null, { isNeedSpinner: false }))
        .then((res) => {
          dispatch(setPortfolios(res.items));
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    }
  }, [dispatch, isAuthenticated, portfolios.length, profile]);

  return null;
};

export default ProfileLoader;
