import React from 'react';

import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import successIcon from 'src/assets/svg/success-icon.svg';

import styles from './SuccessStep.module.css';

export const SuccessStep = () => {
  const { t } = useTranslation();

  return (
    <Row align="top">
      <Col>
        <img className={styles.successIcon} src={successIcon} />
      </Col>
      <Col>
        <div className={styles.content}>
          <h2>{t('success_change_password_title')}</h2>
          <p>{t('success_change_password_content')}</p>
        </div>
      </Col>
    </Row>
  );
};
