import { createSlice } from '@reduxjs/toolkit';

const companySlice = createSlice({
  name: 'company',
  initialState: {
    company: null,
    isShowErrorMessageCompany: false
  },
  reducers: {
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setIsShowErrorMessageCompany: (state, action) => {
      state.isShowErrorMessageCompany = action.payload;
    }
  }
});

export const { setCompany, setIsShowErrorMessageCompany } = companySlice.actions;

export const selectCompany = (state) => state.company.company;
export const selectIsShowErrorMessageCompany = (state) => state.company.isShowErrorMessageCompany;

export default companySlice.reducer;
