import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { loadArticleListAsync } from 'src/app/api/resources';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';

import { Editor } from './components/index';
import styles from './Resources.editor.module.css';

export const ResourcesEditor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [topics, setTopics] = useState([]);
  useEffect(() => {
    dispatch(loadArticleListAsync())
      // @ts-ignore
      .then((menuList) => {
        setTopics(
          menuList.map((item) => {
            return {
              label: `${item.word} (${item.name})`,
              value: item.name,
              id: item.id
            };
          })
        );
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch]);
  return (
    <div className="page">
      <h1 className="page__title-uppercase">{t('resources_editor')}</h1>
      <div className={styles.editorForm}>
        <Editor topics={topics} />
      </div>
    </div>
  );
};
