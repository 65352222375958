import React from 'react';

import { useTranslation } from 'react-i18next';

import successIcon from 'src/assets/svg/success-icon.svg';

import styles from './SuccessStep.module.css';

export const SuccessStep = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap} data-test="request__success">
      <img src={successIcon} />
      <div className={styles.content}>
        <h2>{t('invite_success_message')}</h2>
      </div>
    </div>
  );
};
