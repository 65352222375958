import React from 'react';

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './SuccessfulPaymentModal.module.css';

export const SuccessfulPaymentModal = ({ visible, text, onClick }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title=""
      centered
      visible={visible}
      forceRender
      width={400}
      okText={t('got_it')}
      getContainer={'main'}
      className={`mg-modal _confirm`}
      okButtonProps={{ className: `mg-btn ${styles.okBtn}` }}
      cancelButtonProps={{ className: styles.cancelBtn }}
      onOk={onClick}
    >
      <div className={styles.successfulPaymentModal}>
        <p>{text}</p>
      </div>
    </Modal>
  );
};
