import { axiosGet, axiosErrors, axiosPut } from '../helpers/axios';

const getSystemSettings = axiosGet('config/list?order=type&order=ASC');
export const loadSystemSettingsAsync = () => () => {
  return getSystemSettings()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const updateSystemSettingsAsync = (data) => () => {
  const updateSystemSettings = axiosPut('config/list');
  return updateSystemSettings(data)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const updateCronTimeAsync = (time) => () => {
  const updateSystemSettings = axiosPut('alerts/time');
  return updateSystemSettings({ timeString: time })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};
