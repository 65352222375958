import { getRelativePosition } from 'chart.js/helpers';
// import { debounce } from '../../../helpers/debounce';

export const eventYAxisPlugin = (id) => {
  return {
    id: 'eventYAxisPlugin',
    beforeEvent(chart, args) {
      callBeforeEventFunc(chart, args, id);
    }
    // afterEvent: (chart, args) => {
    //   const event = args.event;
    //   if (event.type === 'mouseout') {
    //     chart.config.options.scales.x.ticks.color = chart.config.options.scales.x.ticks.color.map(() => '#9CAEBD');
    //     chart.config.options.scales.y.ticks.color = chart.scales.y.ticks.map(() => '#9CAEBD');
    //     if (chart.config.options.scales.y1) {
    //       chart.config.options.scales.y1.ticks.color = chart.scales.y1.ticks.map(() => '#9CAEBD');
    //       chart.config.options.scales.y1.ticks.color = chart.config.options.scales.y1.ticks.color.map(() => '#9CAEBD');
    //     }
    //     chart.config.options.scales.y.ticks.color = chart.config.options.scales.y.ticks.color.map(() => '#9CAEBD');
    //     chart.update();
    //   }
    // }
  };
};

const callBeforeEventFunc = (chart, args, id) => {
  const event = args.event;
  const { ticks } = chart.scales.y;
  const ticksY1 = chart.scales.y1?.ticks;
  const yValues = ticks.map((elem) => elem.value);
  const y1Values = ticksY1?.map((elem) => elem.value);
  const canvasPosition = getRelativePosition(event, chart);
  const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);
  const dataY1 = chart.scales.y1?.getValueForPixel(canvasPosition.y);
  const closestVal = closest(yValues, dataY);
  const closestValY1 = y1Values ? closest(y1Values, dataY1) : undefined;
  const pixelPosition = chart.scales.y.getPixelForValue(closestVal);
  const pixelPositionY1 = chart.scales.y1?.getPixelForValue(closestValY1);
  if (
    isNearValue(yValues, dataY, chart.config.options.scales.y.ticks.stepSize) &&
    event.x < chart.chartArea.left &&
    event.type !== 'mouseout'
  ) {
    showLine(closestVal, yValues, chart, pixelPosition, id);
    // colorValue('y', chart, closestVal);
  } else if (
    y1Values &&
    isNearValue(y1Values, dataY1, chart.config.options.scales.y1.ticks.stepSize) &&
    event.x > chart.chartArea.right + 10 &&
    event.type !== 'mouseout'
  ) {
    showLine(closestValY1, y1Values, chart, pixelPositionY1, id);
    // colorValue('y1', chart, closestValY1);
  } else {
    const lineEl = document.getElementById(`y-axis-line-${id}`);
    lineEl.style.opacity = 0;
  }
};

const showLine = (closestVal, yValues, chart, pixelPosition, id) => {
  const lineEl = document.getElementById(`y-axis-line-${id}`);
  const tooltipEl = chart.canvas.parentNode.querySelector(`#tooltip-${id}`);
  const lineElX = document.getElementById(`x-axis-line-${id}`);
  if (tooltipEl) {
    tooltipEl.style.opacity = 0;
    if (lineElX) {
      lineElX.style.opacity = 0;
    }
  }
  if (closestVal && closestVal !== yValues[0]) {
    lineEl.style.width = chart.chartArea.width + 'px';
    lineEl.style.left = chart.chartArea.left + 'px';
    lineEl.style.top = pixelPosition + 'px';
    lineEl.style.opacity = 1;
  }
};

// const colorValue = (coloredAxes, chart, closestVal) => {
//   let index = 0;
//   chart.scales[coloredAxes].ticks.forEach((tick, i) => {
//     if (tick.value === closestVal) {
//       index = i;
//     }
//   });
//   if (chart.config.options.scales.y1) {
//     chart.config.options.scales.y1.ticks.color = chart.scales.y1.ticks.map(() => '#9CAEBD');
//   }
//   chart.config.options.scales[coloredAxes].ticks.color = chart.scales.y.ticks.map(() => '#9CAEBD');
//   chart.config.options.scales[coloredAxes].ticks.color[index] = '#011B24';
//   chart.update();
//   console.log(closestVal, chart.scales[coloredAxes].ticks, index, chart.config.options.scales[coloredAxes].ticks.color);
// };

const closest = (counts, goal) =>
  counts.reduce(function (prev, curr) {
    return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
  });

const isNearValue = (array, value, step) => {
  const stepVal = step || 1;
  const result = array.find((val) => val + stepVal / 2 > value && val - stepVal / 2 < value);
  return !!result;
};
