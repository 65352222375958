export const replaceSpaceWithUnderscores = (str) => {
  return str ? str.replaceAll(' ', '_') : str;
};

/** @type {(pattern: string, mapping: {[key: string]: any}) => string} */
export function formatString(pattern, mapping) {
  /** @type {(find: string, findGroup: string) => string} */
  const replace = (find, findGroup) => {
    return mapping[findGroup];
  };

  const sub = /\$\{(\w+)\}/gm;

  // @ts-ignore
  return pattern.replaceAll(sub, replace);
}

export function roundTo(value, decimal = 2) {
  const div = 10 ** decimal;
  return Math.round((value + Number.EPSILON) * div) / div;
}
