import React, { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { SearchCompany } from 'src/app/components/search-company';

export const HeaderSearchCompany = () => {
  const navigate = useNavigate();

  const handleChange = useCallback(
    (val) => {
      val && navigate(`/company/overview/${val}`);
    },
    [navigate]
  );

  return <SearchCompany value={null} onChange={handleChange} autoFocus={false} selectStyle={{ width: '400px' }} />;
};
