import React, { useState, useEffect } from 'react';

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router';

import { joinToGroupAsync } from 'src/app/api/admin';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';

export const AccessToGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { hash } = useParams();
  const [isVisible, setIsVisible] = useState(true);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const windowUrl = window.location.search;
    const email = new URLSearchParams(windowUrl);
    setEmail(email.get('to') || '');
  }, []);

  const setInvisible = () => {
    navigate('/');
    setIsVisible((prev) => !prev);
  };

  const handleOk = () => {
    dispatch(joinToGroupAsync(hash, email))
      .then(() => {
        navigate('/');
        appMessage(typeMessage.SUCCESS, t('added_to_group_success_message'));
      })
      .catch((e) => {
        navigate('/');
        appMessage(typeMessage.ERROR, e.message);
      });
    setIsVisible((prev) => !prev);
  };

  return (
    <Modal
      data-test="accept-modal"
      title="Accept invitation"
      centered
      visible={isVisible}
      forceRender
      closable={false}
      className={`mg-modal _info`}
      okButtonProps={{ className: `mg-btn`, style: { display: undefined } }}
      okText="Accept"
      cancelText="Ignore"
      cancelButtonProps={{ className: `mg-btn`, style: { display: undefined } }}
      onOk={handleOk}
      onCancel={setInvisible}
    >
      <span>{t('invite_to_group_text')}</span>
    </Modal>
  );
};
