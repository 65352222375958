import React, { useState, useCallback, useEffect } from 'react';

import { Button } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import styles from '../Edit.module.css';
import { SearchUserModal } from '../search-users-modal';
import AppInput from 'src/app/components/form/input';
import { ReactComponent as TrashIcon } from 'src/assets/svg/custom-screen/trash.svg';

export const MembersList = ({ setRecipients, selectedMembers, setSelectedMembers, setSendToAll, sendToAllInit }) => {
  const [openModal, setOpenModal] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(-1);
  const [membersCopy, setMembersCopy] = useState([]);
  const [userEmail, setUserEmail] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedMembers) {
      setMembersCopy(selectedMembers?.filter((memberEmail) => memberEmail?.includes(userEmail)));
    }
  }, [selectedMembers, userEmail]);

  useEffect(() => {
    setActiveTabIndex(+!sendToAllInit);
  }, [sendToAllInit]);

  useEffect(() => {
    if (activeTabIndex === 0) {
      setSendToAll(true);
    } else if (activeTabIndex === 1) {
      setSendToAll(false);
      setRecipients(membersCopy);
    }
  }, [activeTabIndex, membersCopy, setRecipients, setSendToAll]);

  const handleChangeVisible = useCallback(
    (selectedMembers) => {
      setSelectedMembers(selectedMembers);
      setOpenModal(!openModal);
    },
    [openModal, setSelectedMembers]
  );

  const handleDeleteMember = useCallback(
    (email) => {
      setSelectedMembers((prev) => prev.filter((memberEmail) => memberEmail !== email));
    },
    [setSelectedMembers]
  );

  return (
    <div className={styles.members}>
      <div className={styles.membersTabs}>
        <div
          className={classNames(styles.membersTab, { [styles.membersTabActive]: activeTabIndex === 0 })}
          onClick={() => setActiveTabIndex(0)}
        >
          {t('tab_pane_all_users')}
        </div>
        <div
          className={classNames(styles.membersTab, { [styles.membersTabActive]: activeTabIndex === 1 })}
          onClick={() => setActiveTabIndex(1)}
        >
          {t('tab_pane_selected_users')}
        </div>
      </div>

      <SearchUserModal visible={openModal} onChangeVisible={handleChangeVisible} members={selectedMembers} />

      {activeTabIndex === 1 && (
        <>
          <div className={styles.addUserRow}>
            <AppInput
              className={`${styles.input} ${styles.searchMembers}`}
              placeholder={t('search_members_placeholder')}
              value={userEmail}
              onChange={setUserEmail}
            ></AppInput>
            <Button
              onClick={() => {
                setOpenModal(true);
              }}
              className={`mg-btn _text ${styles.addUserBtn}`}
            >
              {t('add_users_btn')}
            </Button>
          </div>
          <div className={styles.membersContent}>
            <PerfectScrollbar className={`scroll-container ${styles.scrollContainer}`}>
              {membersCopy?.map((item, idx) => (
                <div key={idx} className={styles.memberItem}>
                  <span>{item}</span>
                  <TrashIcon className={styles.deleteIcon} onClick={() => handleDeleteMember(item)} />
                </div>
              ))}
            </PerfectScrollbar>
          </div>
        </>
      )}
    </div>
  );
};
