import React, { useCallback } from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import styles from './AlertLimit.module.css';

export const AlertLimit = ({ profile }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToSubscriptions = () => {
    navigate('/account/subscription');
  };

  const getAmountPriceAlerts = useCallback(() => {
    return profile?.priceAlerts.length;
  }, [profile?.priceAlerts.length]);

  const getAmountCompanyAlerts = useCallback(() => {
    return profile?.insiderAlerts.length;
  }, [profile?.insiderAlerts.length]);

  const getTotalAlerts = useCallback(() => {
    if (profile?.restriction?.data?.alertCreationLimit === null) {
      return Infinity;
    }
    return profile?.restriction?.data?.alertCreationLimit;
  }, [profile?.restriction?.data?.alertCreationLimit]);

  return (
    <div className={styles.alert}>
      <div className={styles.title}>
        <h3 className="page__subtitle">{t('account_alert_limit_subtitle')}</h3>
        <hr />
      </div>
      <div className={styles.content}>
        <div className={styles.info}>
          <div>
            <span>{t('account_alert_position')}</span>
            <span>{getAmountCompanyAlerts()}</span>
          </div>
          <div>
            <span>{t('account_alert_price')}</span>
            <span>{getAmountPriceAlerts()}</span>
          </div>
          <div>
            <span>{t('account_alert_total')}</span>
            <span className={styles.highlightedText}>
              {getAmountCompanyAlerts() + getAmountPriceAlerts() + ' / '}
              {getTotalAlerts()}
            </span>
          </div>
        </div>
        <Button
          data-test="profile_page_increase_limit"
          onClick={navigateToSubscriptions}
          className="mg-btn"
          style={{ width: '273px' }}
        >
          {t('btn_increase_limit')}
        </Button>
      </div>
    </div>
  );
};
