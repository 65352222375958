import React, { useEffect, useState } from 'react';

import { ReactComponent as CorporateImg } from 'src/assets/svg/subscription-icons/corporate.svg';
import { ReactComponent as FullImg } from 'src/assets/svg/subscription-icons/full.svg';
import { ReactComponent as StandardImg } from 'src/assets/svg/subscription-icons/standard.svg';

export const defineStyles = (title) => {
  let backgroundColor;

  switch (title.toLowerCase()) {
    case 'standard':
      backgroundColor = 'var(--light-blue)';
      break;
    case 'full':
      backgroundColor = 'var(--green-bright)';
      break;
    case 'corporate':
      backgroundColor = 'var(--light-violet)';
      break;
    default:
      backgroundColor = 'var(--green-bg-light)';
  }
  return {
    backgroundColor
  };
};

export const DefineSvg = ({ title }) => {
  const [svgIcon, setSvgIcon] = useState(null);
  useEffect(() => {
    switch (title.toLowerCase()) {
      case 'standard':
        setSvgIcon(<StandardImg />);
        break;
      case 'full':
        setSvgIcon(<FullImg />);
        break;
      case 'corporate':
        setSvgIcon(<CorporateImg />);
        break;
      default:
        setSvgIcon(<StandardImg />);
    }
  }, [title]);

  return svgIcon;
};
