import React from 'react';

import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from '../Header.module.css';
import arrow from 'src/assets/svg/arrows/arrow-top.svg';
import { ReactComponent as ProfileImageHover } from 'src/assets/svg/header/user-logged.svg';
import { ReactComponent as ProfileImageLogout } from 'src/assets/svg/header/user-logout.svg';

export const ProfileMenuItem = ({ profile, onVisibleMenu, isSelected }) => {
  const { t } = useTranslation();
  return (
    <Menu.Item
      data-test={profile ? 'header_profile_menu' : null}
      key="profile"
      className={
        isSelected
          ? `${styles.mgHeaderMenu} ${styles.mgHeaderMenuProfile} ${styles.mgHeaderMenuSelected}`
          : `${styles.mgHeaderMenu} ${styles.mgHeaderMenuProfile}`
      }
      onClick={onVisibleMenu}
    >
      {isSelected && <img src={arrow} className={styles.arrowTop} />}
      <div className={styles.profileWrap}>
        {profile ? <ProfileImageHover className={styles.profileImageLogged} /> : <ProfileImageLogout />}
        {profile ? (
          <span>Profile</span>
        ) : (
          <a data-test="header_enter" href="#">
            {t('header_enter')}
          </a>
        )}
      </div>
    </Menu.Item>
  );
};
