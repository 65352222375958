import { createSlice } from '@reduxjs/toolkit';

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState: {
    portfolios: [],
    isShowErrorMessagePortfolio: false,
    searchText: '',
    portfoliosSearch: [],
    companiesSearch: [],
    currencies: [],
    cashType: [],
    sharesType: [],
    taxType: [],
    tickers: []
  },
  reducers: {
    setPortfolios: (state, action) => {
      state.portfolios = action.payload;
    },
    setIsShowErrorMessagePortfolio: (state, action) => {
      state.isShowErrorMessagePortfolio = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setPortfoliosSearch: (state, action) => {
      state.portfoliosSearch = action.payload;
    },
    setCompaniesSearch: (state, action) => {
      state.companiesSearch = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    setCashType: (state, action) => {
      state.cashType = action.payload;
    },
    setSharesType: (state, action) => {
      // @ts-ignore
      state.sharesType = action.payload;
    },
    setTaxType: (state, action) => {
      state.taxType = action.payload;
    },
    setTickers: (state, action) => {
      state.tickers = action.payload;
    }
  }
});

export const {
  setPortfolios,
  setIsShowErrorMessagePortfolio,
  setSearchText,
  setPortfoliosSearch,
  setCompaniesSearch,
  setCurrencies,
  setCashType,
  setSharesType,
  setTaxType,
  setTickers
} = portfolioSlice.actions;

export const selectPortfolios = (state) => state.portfolio.portfolios;
export const selectIsShowErrorMessagePortfolio = (state) => state.portfolio.isShowErrorMessagePortfolio;
export const selectSearchText = (state) => state.portfolio.searchText;
export const selectPortfoliosSearch = (state) => state.portfolio.portfoliosSearch;
export const selectCompaniesSearch = (state) => state.portfolio.companiesSearch;
export const selectCurrencies = (state) => state.portfolio.currencies;
export const selectCashType = (state) => state.portfolio.cashType;
export const selectSharesType = (state) => state.portfolio.sharesType;
export const selectTaxType = (state) => state.portfolio.taxType;
export const selectTickers = (state) => state.portfolio.tickers;

export default portfolioSlice.reducer;
