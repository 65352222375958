export class InsiderCompanyAlertModelAll {
  id = '';
  companyCik = 0;
  companyName = '';
  personId = 0;
  personName = '';
  personPosition = '';
  alerts = [];
  tickers = [];

  constructor(data) {
    if (data) {
      this.id =
        data.cik +
          data.company.company_name?.toLowerCase().replaceAll(' ', '_') +
          '_' +
          data.person.person_name?.toLowerCase().replaceAll(' ', '_') || '0';
      this.companyCik = data.cik || 0;
      this.companyName = data.company.company_name || 'No data';
      this.personId = data.person_id || 0;
      this.personName = data.person.person_name || 'No data';
      this.personPosition = data.personPosition || 'No data';
      this.alerts = data.alerts || [];
      this.tickers = data.tickers || [];
    }
  }
}

export class InsiderCompanyAlertModel {
  id = '';
  companyCik = 0;
  companyName = '';
  personId = 0;
  personName = '';
  personPosition = '';
  alerts = [];
  tickers = [];

  constructor(data) {
    if (data) {
      this.id =
        data.companyCik +
          data.companyName?.toLowerCase().replaceAll(' ', '_') +
          '_' +
          data.personName?.toLowerCase().replaceAll(' ', '_') || '0';
      this.companyCik = data.companyCik || 0;
      this.companyName = data.companyName || 'No data';
      this.personId = data.personId || 0;
      this.personName = data.personName || 'No data';
      this.personPosition = data.personPosition || 'No data';
      this.alerts = data.alerts || [];
      this.tickers = data.tickers || [];
    }
  }
}

export class PriceCompanyAlertModel {
  id = '';
  rangeStart = 0;
  rangeEnd = 0;
  companyCik = 0;
  companyName = '';
  alertId = '';
  tickerId = '';
  tickerName = '';
  events = '';
  type = '';

  constructor(data) {
    if (data) {
      this.id = data?.tickerId + '_' + data?.tickerName || '';
      this.rangeStart = data.rangeStart || null;
      this.rangeEnd = data.rangeEnd || null;
      this.companyCik = data.companyCik || '';
      this.companyName = data.companyName || 'No data';
      this.alertId = data.alertId || '';
      this.tickerName = data.tickerName || 'No data';
      this.tickerId = data.tickerId || '';
      this.type = data.type || '';
      this.events = 'text';
    }
  }
}
