import { axiosErrors, axiosGet, axiosPost } from '../helpers/axios';
import { setIsShowErrorMessageCompany } from '../slices/companySlice';

/** @type {(id: string, options?: {isNeedSpinner: boolean})=> () => Promise<any>} */
export const loadCompanyAsync =
  (id, options = { isNeedSpinner: false }) =>
  (dispatch) => {
    const loadCompany = axiosGet(`/company/overview/${id}`, options);
    return loadCompany()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(setIsShowErrorMessageCompany(axiosErrors(e)));
        throw axiosErrors(e);
      });
  };

/** @type {(id: string, index: number, limit: number, sort: {column: string[], direction: string}, filters?: string, options?: {isNeedSpinner: boolean})=> () => Promise<any>} */
export const loadCompanyTransfersAsync =
  (id, index, limit, sort, filters, options = { isNeedSpinner: false }) =>
  (dispatch) => {
    const loadCompanyTransfers = axiosPost(
      `/company/overview/${id}/transfers?index=${index || 0}&limit=${limit || 10}${sort.column
        .map((col) => `&order[0]=${col}`)
        .join('')}&order[0]=${sort.direction}`,
      options
    );
    return loadCompanyTransfers({
      trans_date: filters.trans_date,
      price: filters.price,
      person: filters.person_name
    })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(setIsShowErrorMessageCompany(axiosErrors(e)));
        throw axiosErrors(e);
      });
  };

/** @type {(id: string, options?: {isNeedSpinner: boolean})=> () => Promise<any>} */
export const loadCompanyHoldsAsync =
  (id, options = { isNeedSpinner: false }) =>
  (dispatch) => {
    const loadCompanyHolds = axiosGet(`/company/overview/${id}/holds`, options);
    return loadCompanyHolds()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(setIsShowErrorMessageCompany(axiosErrors(e)));
        throw axiosErrors(e);
      });
  };

/** @type {() => (dispatch) => unknown}
 * not used so far
 */
export const loadCompanyListAsync = () => (dispatch) => {
  const loadCompanyList = axiosGet(`/company/list`);
  return loadCompanyList()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageCompany(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const searchCompanyByNameAsync = (name) => (dispatch) => {
  const searchCompanyByName = axiosGet(`company/search?value=${name}`, {
    isNeedSpinner: false
  });
  return searchCompanyByName()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

/** @type {(companyName: string) => (dispatch) => unknown}
 * not used so far
 */
export const getMatchedCompanyListAsync = (companyName) => (dispatch) => {
  const loadCompanyList = axiosGet(`/company/list?where[company_name][_iLike]=%${companyName}%`);
  return loadCompanyList()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

/** @type {(id: string | number, options?: {isNeedSpinner: boolean}) => {description: string}} */
export const loadCompanyShortDescriptionAsync =
  (id, options = { isNeedSpinner: false }) =>
  // @ts-ignore
  (dispatch) => {
    const loadCompanyDescription = axiosGet(`/company/overview/${id}/short-description`, options);
    return loadCompanyDescription()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(setIsShowErrorMessageCompany(axiosErrors(e)));
        throw axiosErrors(e);
      });
  };

/** @type {(id: number | string, options?: {isNeedSpinner: boolean})=> {description: string}} */
export const loadCompanyLongDescriptionAsync =
  (id, options = { isNeedSpinner: false }) =>
  // @ts-ignore
  (dispatch) => {
    const loadCompanyDescription = axiosGet(`/company/overview/${id}/description`, options);
    return loadCompanyDescription()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(setIsShowErrorMessageCompany(axiosErrors(e)));
        throw axiosErrors(e);
      });
  };

/** @type {(id: number | string, options?: {isNeedSpinner: boolean})=> {description: string}} */
export const loadCompanyDiscussionAsync =
  (id, options = { isNeedSpinner: false }) =>
  // @ts-ignore
  (dispatch) => {
    const loadCompanyDiscussion = axiosGet(`/company/overview/${id}/discussion`, options);
    return loadCompanyDiscussion()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(setIsShowErrorMessageCompany(axiosErrors(e)));
        throw axiosErrors(e);
      });
  };

/** @type {(id: number | string, name: string, options: {isNeedSpinner: boolean})=> () => Promise<any>} */
export const loadCompanyInsidersAsync =
  (id, name, options = { isNeedSpinner: false }) =>
  (dispatch) => {
    const loadCompanyInsiders = axiosGet(
      `company/${id}/insiders` + (name ? `?where[person_name][_iLike]=%${name}%` : ''),
      options
    );
    return loadCompanyInsiders()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(setIsShowErrorMessageCompany(axiosErrors(e)));
        throw axiosErrors(e);
      });
  };

/** @type {(id: number | string, options: {isNeedSpinner: boolean})=> () => Promise<any>} */
export const loadCompanyTickersAsync =
  (id, options = { isNeedSpinner: false }) =>
  (dispatch) => {
    const loadCompanyTickers = axiosGet(`company/${id}/tickers`, options);
    return loadCompanyTickers()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(setIsShowErrorMessageCompany(axiosErrors(e)));
        throw axiosErrors(e);
      });
  };
