import React, { useState } from 'react';

import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import AppInput from 'src/app/components/form/input';

import styles from './PasswordRecoverySendLinkForm.module.css';

export const SendLinkForm = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [disabledSave, setDisabledSave] = useState(false);
  const sendLinkInitialValues = {
    email: ''
  };

  const handleFormChange = () => {
    const hasErrors = !form.getFieldError('email').length && !!form.getFieldValue('email');
    setDisabledSave(hasErrors);
  };

  return (
    <div className={styles.formWrap}>
      <Form
        name="sendLink"
        form={form}
        onFieldsChange={handleFormChange}
        initialValues={sendLinkInitialValues}
        onFinish={onSubmit}
        autoComplete="off"
        className={styles.form}
      >
        <Form.Item
          className={styles.formItem}
          name="email"
          rules={[
            {
              required: true,
              message: t('required_input')
            },
            {
              type: 'email',
              message: t('invalid_input')
            }
          ]}
        >
          <AppInput data-test="password_recovery_page_email" placeholder={t('email')} type="email"></AppInput>
        </Form.Item>
        <Button
          data-test="password_recovery_page_recover"
          htmlType="submit"
          className={styles.btn + ' ' + 'mg-btn'}
          disabled={!disabledSave}
        >
          {t('recover_btn')}
        </Button>
      </Form>
      <p className={styles.formRulesText}>{t('recover_password_fist_message')}</p>
    </div>
  );
};
