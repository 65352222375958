import React, { useEffect, useState, useCallback } from 'react';

import { Form } from 'antd';

import AppInput from 'src/app/components/form/input';
import { useDebouse } from 'src/app/hooks/debounse';

import styles from './SearchUsersModal.module.css';

export const escapeString = (currentString) => {
  return currentString.replace(/[.*+?^${}()|[\]\\]/g, '_');
};

export const SearchForm = ({ value, onSubmit, onSearch, onChange, placeholder, className }) => {
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebouse(searchTerm, 500);

  useEffect(() => {
    form.setFields([{ email: 'email' }]);
  }, [form, value]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const value = form.getFieldsValue();
      if (value.email) {
        onSearch(escapeString(value.email));
      }
    }
  }, [debouncedSearchTerm, form, onSearch]);

  const handleChange = () => {
    const value = form.getFieldsValue();
    onChange(value.email);
    setSearchTerm(value.email);
  };

  const handleOnPressEnter = useCallback(
    (value) => {
      if (value.email) {
        onSearch(escapeString(value.email));
      }
    },
    [onSearch]
  );

  return (
    <div className={`${className}`}>
      <Form form={form} onFinish={onSubmit} autoComplete="off" onFieldsChange={handleChange} className={styles.form}>
        <Form.Item className={styles.formItem} name="email">
          <AppInput
            data-test="custom_screen_page_modal_search_column"
            className={styles.input}
            onPressEnter={handleOnPressEnter}
            placeholder={placeholder}
          ></AppInput>
        </Form.Item>
      </Form>
    </div>
  );
};
