import React from 'react';

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FailedIcon } from 'src/assets/svg/company/failed-modal.svg';
import { ReactComponent as SuccessIcon } from 'src/assets/svg/company/success-modal.svg';

import styles from './MessageModal.module.css';

export const MessageModal = ({ isSuccessModal, visible, onClose, messageText }) => {
  const { t } = useTranslation();

  return (
    <Modal
      closable={false}
      centered
      width={680}
      visible={visible}
      forceRender
      className={`mg-modal`}
      okText={t('ok')}
      getContainer={'main'}
      okButtonProps={{
        className: `mg-btn ${styles.okButton}`
      }}
      cancelButtonProps={{
        className: `${styles.cancelButton}`
      }}
      onOk={onClose}
      onCancel={onClose}
    >
      <div className={styles.modalContent}>
        {isSuccessModal ? <SuccessIcon /> : <FailedIcon />}
        {isSuccessModal ? <h3>{t('success')}</h3> : <h3>{t('failed')}</h3>}
        <p className={styles.messageText}>{messageText}</p>
      </div>
    </Modal>
  );
};
