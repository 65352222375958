export const columns = [
  {
    title: 'tariff_name_label',
    key: 'name',
    dataIndex: 'name',
    sorted: true,
    filtered: false,
    width: '65%'
  },
  {
    title: 'status_label',
    key: 'active',
    dataIndex: 'active',
    sorted: true,
    filtered: false,
    render: (status) => {
      if (status) {
        return 'Active';
      }
      return 'Archive';
    }
  },
  {
    title: 'creation_date_label',
    key: 'created',
    dataIndex: 'created',
    sorted: true,
    filtered: false
  },
  {
    title: 'subscribers_label',
    key: 'subscriptionsCount',
    dataIndex: 'subscriptionsCount',
    sorted: true,
    filtered: false
  }
];
