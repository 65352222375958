export const menuItemsArray = [
  {
    key: 'valuation',
    titleKey: 'menu_company_valuation',
    anchor: 'valuation'
  },
  {
    key: 'financials',
    titleKey: 'menu_company_financials',
    anchor: 'financials'
  },
  {
    key: 'distribution',
    titleKey: 'menu_company_distribution',
    anchor: 'distribution'
  },
  {
    key: 'transactions',
    titleKey: 'menu_company_transactions',
    anchor: 'transactions'
  },
  {
    key: 'business_description',
    titleKey: 'menu_company_business_description',
    anchor: 'business_description'
  },
  {
    key: 'management',
    titleKey: 'menu_company_discussion',
    anchor: 'management'
  }
];

export const modelMenuItems = [
  {
    key: 'fair_value',
    titleKey: 'calculate_fair_value_label'
  },
  {
    key: 'model_builder',
    titleKey: 'mg_model_builder_label'
  },
  {
    key: 'time_horizon',
    titleKey: 'model_time_horizon_label'
  },
  {
    key: 'sensitivity',
    titleKey: 'sensitivity_label'
  },
  {
    key: 'tutorial',
    titleKey: 'model_tutorial'
  }
];
