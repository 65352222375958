import React from 'react';

import { Menu } from 'antd';

import styles from './MenuRowsPerPage.module.css';

export const MenuRowsPerPage = ({ onClick }) => {
  return (
    <Menu>
      <Menu.Item
        data-test="portfolio_page_selected_10_lines"
        className={`${styles.rowsMenuItem}`}
        key="0"
        onClick={() => onClick(10)}
      >
        10
      </Menu.Item>
      <Menu.Item
        data-test="portfolio_page_selected_30_lines"
        className={`${styles.rowsMenuItem}`}
        key="1"
        onClick={() => onClick(30)}
      >
        30
      </Menu.Item>
      <Menu.Item
        data-test="portfolio_page_selected_50_lines"
        className={`${styles.rowsMenuItem}`}
        key="2"
        onClick={() => onClick(50)}
      >
        50
      </Menu.Item>
    </Menu>
  );
};
