import React from 'react';

import './styles.css';
import { AppSpinner } from '../spinner-component';

export const AppSpinnerFull = () => {
  return (
    <div className="mg-spinner-full__wrap">
      <AppSpinner />
    </div>
  );
};
