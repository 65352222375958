import React from 'react';

import { useTranslation } from 'react-i18next';

import { TextBlock } from '../text-block';
import { TextListBlock } from '../text-list-block';
import { TextSection } from '../text-section';

import styles from './PrivacyPolicy.module.css';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className={`page ${styles.privacyPolicy}`}>
      <h1 className="page__title-uppercase">{t('title_privacy_policy')}</h1>
      <header className={styles.header}>
        <p>{t('title')}</p>
        <p>{t('last_updated')}</p>
      </header>
      <div>
        <p dangerouslySetInnerHTML={{ __html: t('introduction') }} style={{ marginBottom: '36px' }}></p>
        <TextListBlock
          title={t('title_block_what_we_do')}
          beforeText={t('text_block_what_we_do_1')}
          listItems={[t('what_we_do_list_item_1'), t('what_we_do_list_item_2'), t('what_we_do_list_item_3')]}
          afterText={t('text_block_what_we_do_2')}
          setInnerHTML
        />
        <TextListBlock
          title={t('title_block_who_you_are')}
          beforeText={t('text_block_who_you_are')}
          listItems={[t('who_you_are_list_item_1'), t('who_you_are_list_item_2'), t('who_you_are_list_item_3')]}
          setInnerHTML
        />
        <div className={styles.businessPurpose}>
          <p>
            <b>{t('title_block_collect_information')}</b>
          </p>
          <p>{t('text_block_collect_information_1')}</p>
          <p dangerouslySetInnerHTML={{ __html: t('text_block_collect_information_2') }}></p>
          <p>{t('text_block_collect_information_3')}</p>
        </div>
        <TextBlock title={t('title_block_protect_information')} text={t('text_block_protect_information')} />
        <TextBlock title={t('title_block_change_pp')} text={t('text_block_change_pp')} />
        <TextSection title={t('title_block_collect_data')} text={t('text_block_collect_data')} setInnerHTML>
          <TextListBlock
            title={t('title_block_identifies_data')}
            listItems={[
              t('identifies_data_list_item_1'),
              t('identifies_data_list_item_2'),
              t('identifies_data_list_item_3'),
              t('identifies_data_list_item_4'),
              t('identifies_data_list_item_5'),
              t('identifies_data_list_item_6')
            ]}
            afterText={t('text_block_identifies_data')}
          />
          <TextListBlock
            title={t('title_block_payment_data')}
            listItems={[t('payment_data_list_item_1'), t('payment_data_list_item_2'), t('payment_data_list_item_3')]}
            afterText={t('text_block_payment_data')}
          />
          <TextListBlock
            title={t('title_block_services_data')}
            beforeText={t('text_block_services_data_1')}
            listItems={[t('services_data_list_item_1'), t('services_data_list_item_2'), t('services_data_list_item_3')]}
            afterText={t('text_block_services_data_2')}
          />
        </TextSection>
        <TextSection title={t('title_block_how_collect_data')}>
          <TextListBlock
            listText={t('text_block_how_collect_data')}
            listItems={[
              t('how_collect_data_list_item_1'),
              t('how_collect_data_list_item_2'),
              t('how_collect_data_list_item_3'),
              t('how_collect_data_list_item_4'),
              t('how_collect_data_list_item_5')
            ]}
            letterList
          />
        </TextSection>
        <TextSection title={t('title_block_why_can_process_data')} text={t('text_block_why_can_process_data_1')}>
          <TextListBlock
            listText={t('why_can_process_data_list_1_title')}
            listItems={[
              t('why_can_process_data_list_1_item_1'),
              t('why_can_process_data_list_1_item_2'),
              t('why_can_process_data_list_1_item_3'),
              t('why_can_process_data_list_1_item_4')
            ]}
            nestedItemIndex={3}
            nestedListText={t('why_can_process_data_list_2_title')}
            nestedListItems={[
              t('why_can_process_data_list_2_item_1'),
              t('why_can_process_data_list_2_item_2'),
              t('why_can_process_data_list_2_item_3'),
              t('why_can_process_data_list_2_item_4'),
              t('why_can_process_data_list_2_item_5'),
              t('why_can_process_data_list_2_item_6'),
              t('why_can_process_data_list_2_item_7'),
              t('why_can_process_data_list_2_item_8')
            ]}
            letterList
          />
        </TextSection>
        <TextSection title={t('title_block_why_process_data')}>
          <TextListBlock
            listText={t('why_process_data_list_title')}
            listItems={[
              t('why_process_data_list_item_1'),
              t('why_process_data_list_item_2'),
              t('why_process_data_list_item_3'),
              t('why_process_data_list_item_4'),
              t('why_process_data_list_item_5'),
              t('why_process_data_list_item_6'),
              t('why_process_data_list_item_7'),
              t('why_process_data_list_item_8'),
              t('why_process_data_list_item_9'),
              t('why_process_data_list_item_10'),
              t('why_process_data_list_item_11'),
              t('why_process_data_list_item_12')
            ]}
            afterText={t('text_block_why_process_data')}
            letterList
          />
        </TextSection>
        <TextSection title={t('title_block_sharing_data')} text={t('text_block_sharing_data')}>
          <TextListBlock
            listText={t('title_block_share_with_entities')}
            listItems={[
              t('share_with_entities_list_item_1'),
              t('share_with_entities_list_item_2'),
              t('share_with_entities_list_item_3'),
              t('share_with_entities_list_item_4'),
              t('share_with_entities_list_item_5'),
              t('share_with_entities_list_item_6'),
              t('share_with_entities_list_item_7')
            ]}
            letterList
          />
          <TextBlock title={t('title_block_how_protect_data')} text={t('text_block_how_protect_data')} />
          <TextBlock title={t('title_block_how_share_data')} text={t('text_block_how_share_data')} setInnerHTML />
          <TextBlock title={t('title_block_how_use_shield')} text={t('text_block_how_use_shield')} />
          <TextBlock title={t('title_block_how_use_safeguards')} text={t('text_block_how_use_safeguards')} />
          <TextBlock title={t('title_block_how_share_partners')} text={t('text_block_how_share_partners')} />
        </TextSection>
        <TextSection title={t('title_block_how_safe_data')} text={t('text_block_how_safe_data')} setInnerHTML />
        <TextSection
          title={t('title_block_how_long_keep_data')}
          text={t('text_block_how_long_keep_data')}
          setInnerHTML
        />
        <TextSection title={t('title_block_no_profiling')} text={t('text_block_no_profiling')} setInnerHTML />
        <TextSection title={t('title_block_how_exercise_rights')} text={t('text_block_how_exercise_rights')}>
          <TextListBlock
            title={t('title_block_what_rights_have')}
            listText={t('what_rights_have_list_title')}
            listItems={[
              t('what_rights_have_list_item_1'),
              t('what_rights_have_list_item_2'),
              t('what_rights_have_list_item_3'),
              t('what_rights_have_list_item_4'),
              t('what_rights_have_list_item_5'),
              t('what_rights_have_list_item_6'),
              t('what_rights_have_list_item_7')
            ]}
            letterList
          />
          <TextBlock title={t('title_block_limited_rights')} text={t('text_block_limited_rights')} />
          <TextBlock title={t('title_block_how_use_rights')} text={t('text_block_how_use_rights')} />
          <TextBlock title={t('title_block_necessary_rights')} text={t('text_block_necessary_rights')} />
        </TextSection>
        <TextSection title={t('title_block_cookies')} text={t('text_block_cookies')}>
          <TextListBlock
            listText={t('different_cookies_list_title')}
            listItems={[
              t('different_cookies_list_item_1'),
              t('different_cookies_list_item_2'),
              t('different_cookies_list_item_3'),
              t('different_cookies_list_item_4'),
              t('different_cookies_list_item_5'),
              t('different_cookies_list_item_6')
            ]}
            letterList
          />
          <TextListBlock
            title={t('title_block_web_beacons')}
            listText={t('web_beacons_list_title')}
            listItems={[t('web_beacons_list_item_1'), t('web_beacons_list_item_2')]}
            letterList
          />
          <TextBlock title={t('title_block_analytic_tools')} text={t('text_block_analytic_tools')} />
        </TextSection>
        <TextSection title={t('title_block_feedback')} text={t('text_block_feedback')} />
      </div>
    </div>
  );
};
