import React, { useState } from 'react';

import { Switch } from 'antd';
import './styles.css';

export const AppSwitch = ({ label, value, onChange, ...rest }) => {
  const [val, setVal] = useState(value);
  const [active, setActive] = useState(false);

  const handleChange = (value) => {
    if (onChange) {
      const res = onChange(value);
      if (res === false) {
        return res;
      }
    }
    setActive(true);
    setVal(value);
  };

  return (
    <div className="mg-input-switch__wrap">
      <Switch checked={val} disabled={active} className="mg-swith-input" onChange={handleChange} {...rest}>
        {label}
      </Switch>
    </div>
  );
};
