import React, { useState, useCallback, useEffect } from 'react';

import { Grid } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { listOfPollsColumns } from '../consts.js';
import { loadAllPollsAsync } from 'src/app/api/polls-and-notifications.js';
import { AppSimpleTable } from 'src/app/components/table';
import { PollModel } from 'src/app/models/poll';

import { PollEdit } from './components/poll-edit';
import { SearchPanel } from './components/search-panel';

export const Polls = () => {
  const [searchValue, setSearchValue] = useState('');
  const [pollsList, setPollsList] = useState([]);
  const [sort, setSort] = useState({ column: 'createdAt', direction: 'DESC' });
  const [pagination, setPagination] = useState({
    limit: 10,
    index: 1,
    total: 0
  });
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllPollsAsync(pagination.index - 1, pagination.limit, searchValue, sort)).then((data) => {
      const { items, total } = data;
      setPagination((prevState) => {
        if (!items.length) {
          return {
            ...prevState,
            index: pagination.index > 1 ? pagination.index - 1 : 1,
            total: 0
          };
        }
        return {
          ...prevState,
          total
        };
      });
      setPollsList(
        items.map((pollInfo) => {
          return new PollModel({
            ...pollInfo,
            questionsAmount: pollInfo.data.length,
            createdAt: moment(pollInfo?.createdAt).format('MMM D YYYY')
          });
        })
      );
    });
  }, [dispatch, pagination.index, pagination.limit, searchValue, sort]);

  const handleSearch = useCallback((val) => {
    setSearchValue(val);
  }, []);

  const handleOnAddPoll = useCallback(() => {
    navigate('polls/creation');
  }, [navigate]);

  const handleChangePagination = useCallback((page, pageSize) => {
    setPagination((prev) => {
      return {
        ...prev,
        limit: pageSize,
        index: page
      };
    });
  }, []);

  const handleSelectRow = useCallback(
    (poll) => {
      navigate(`polls/${poll.id}`);
    },
    [navigate]
  );
  if (Object.keys(screens).length === 0) {
    return <></>;
  }
  return (
    <div>
      <Routes>
        <Route
          index
          element={
            <>
              <SearchPanel onSearch={handleSearch} handleOnAddPoll={handleOnAddPoll} />
              <AppSimpleTable
                padding={
                  screens.lg
                    ? '0 130px 0 130px'
                    : screens.md
                    ? '0 80px 0 80px'
                    : screens.sm
                    ? '0 40px 0 40px'
                    : '0 0 0 0'
                }
                pagination={pagination}
                sort={sort}
                className={'_white-row'}
                listOfData={pollsList}
                onSort={(val) => setSort(val)}
                columns={listOfPollsColumns}
                onChangePagination={handleChangePagination}
                onSelectRow={handleSelectRow}
              />
            </>
          }
        />
        <Route path={'polls/:pollId'} element={<PollEdit />} />;
      </Routes>
    </div>
  );
};
