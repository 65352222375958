import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { roundTo } from 'src/app/helpers/strings';
import { selectUserData } from 'src/app/slices/financialModelSlice';
import { ReactComponent as CalculatorIcon } from 'src/assets/svg/company/calculator.svg';

import styles from './FloatingWindow.module.css';

export const FloatingWindow = ({ advanced }) => {
  const { t } = useTranslation();

  const userData = useSelector(selectUserData);

  return advanced ? (
    <div className={styles.advancedFloatingWindow}>
      <div>
        <CalculatorIcon />
      </div>
      <table>
        <tbody>
          <tr>
            <td className={styles.label}>{t('calculated_fair_value_label')}</td>
            <td className={styles.value}>
              {userData?.saved_fair_value != null ? roundTo(userData?.saved_fair_value, 2) : '—'}
            </td>
            <td className={styles.label}>{t('expected_return_label')}</td>
            <td className={styles.value}>
              {userData?.expected_return != null ? roundTo(userData.expected_return * 100, 2) + '%' : '—'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <div className={styles.floatingWindow}>
      <div>
        <CalculatorIcon />
      </div>
      <table>
        <tbody>
          <tr>
            <td>{t('calculated_fair_value_label')}</td>
            <td>{userData?.saved_fair_value != null ? roundTo(userData?.saved_fair_value, 2) : '—'}</td>
          </tr>
          <tr>
            <td>{t('expected_return_label')}</td>
            <td>{userData?.expected_return != null ? roundTo(userData.expected_return * 100, 2) + '%' : '—'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
