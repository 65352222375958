import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { ProductModel } from '../../models/product';
import { loadProductsAsync } from 'src/app/api/product';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { roles } from 'src/app/models/role';
import { setSpinnerVisible } from 'src/app/slices/commonSlice';
import { selectProfile } from 'src/app/slices/profileSlice';
import { ReactComponent as SubTitleIcon } from 'src/assets/svg/subscription-icons/sub-title-icon.svg';

import styles from './AccountSubscription.module.css';
import { Subscription } from './components/subscription';
import { SuccessfulPaymentModal } from './components/successful-payment-modal';
import { getProductOrder } from './functions';

const MessageWithSupportLink = ({ onClickSupport }) => {
  const { t } = useTranslation();
  return (
    <>
      <span>
        {`${t('subscribe_error_message_start')} `}
        <span className={styles.requestLink} onClick={onClickSupport}>
          {`${t('subscribe_error_message_link')} `}
        </span>
        {t('subscribe_error_message_end')}
      </span>
    </>
  );
};

export const AccountSubscription = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState(null);
  const [visibleSuccessfulModal, setVisibleSuccessfulModal] = useState(false);

  const profile = useSelector(selectProfile);

  const hadleOnClickSupportLink = useCallback(() => {
    navigate('/contact-support');
  }, [navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');
    if (status) {
      if (status === 'success') {
        setVisibleSuccessfulModal(true);
      } else {
        appMessage(typeMessage.ERROR, <MessageWithSupportLink onClickSupport={hadleOnClickSupportLink} />);
      }
    }
  }, [hadleOnClickSupportLink]);

  useEffect(() => {
    setSelected(profile?.customer?.subscription?.price?.product);
  }, [profile?.customer?.subscription]);

  const getSortedProducts = useCallback((items) => {
    const products = items.map((item) => new ProductModel({ ...item, order: getProductOrder(item?.name) }));
    return products.sort((a, b) => a.order - b.order);
  }, []);

  useEffect(() => {
    const isActive = profile?.role?.title === 'Administrator';
    dispatch(setSpinnerVisible(true));
    dispatch(loadProductsAsync(isActive))
      .then((data) => {
        const { items } = data;
        setProducts(getSortedProducts(items));
        dispatch(setSpinnerVisible(false));
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, i18n.t(e.message));
      });
  }, [dispatch, getSortedProducts, profile?.role?.title, i18n]);

  const handleShowPeriodSubscription = useCallback(
    (subscriptionId) => {
      if (profile?.roleId !== roles.Influencer) {
        navigate(`/account/subscription-period/${subscriptionId}`);
      }
    },
    [navigate, profile?.roleId]
  );

  const handleOnOkSuccessfulModal = useCallback(() => {
    setVisibleSuccessfulModal(false);
    navigate('/');
  }, [navigate]);

  return (
    <div className="page">
      <h1 className={styles.title + ' ' + 'page__title-uppercase _green'}>
        {t('my_subscriptions_title')} <SubTitleIcon />
      </h1>
      <p className={styles.text}>Choose your plan</p>
      <div className={styles.row}>
        {products.map((elem) => (
          <Subscription key={elem.id} subscription={elem} selected={selected} onClick={handleShowPeriodSubscription} />
        ))}
      </div>

      <SuccessfulPaymentModal
        visible={visibleSuccessfulModal}
        onClick={handleOnOkSuccessfulModal}
        text={t('success_pay_subscription_text')}
      />
    </div>
  );
};
