import { axiosErrors, axiosGet, axiosPost } from '../helpers/axios';

export const loadSavedModelAsync =
  (id, options = { isNeedSpinner: false }) =>
  (dispatch) => {
    const loadModel = axiosGet(`/financial/${id}`, options);
    return loadModel()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        // dispatch(setIsShowErrorMessageCompany(axiosErrors(e)));
        throw axiosErrors(e);
      });
  };

export const saveModelAsync = (userData) => (dispatch) => {
  const saveModel = axiosPost(`financial`);
  return saveModel({
    companyCik: userData.cik,
    value: userData
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      // dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

// инициализация изменение горизонта, движение точки
export const interactModelAsync = (modelData) => (dispatch) => {
  const interactModel = axiosPost(`mgapi/data`);
  return interactModel(modelData)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      // dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

// расчет fair value
export const calcFairValueAsync = (interactions) => (dispatch) => {
  const calcValue = axiosPost(`mgapi/fair_value`);
  return calcValue(interactions)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      // dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const sensitivityAsync = (interactions) => (dispatch) => {
  const sensitivity = axiosPost(`mgapi/sensitivity`);
  return sensitivity(interactions)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

// rebuild model
export const rebuildModelAsync = (model) => (dispatch) => {
  const rebuildModel = axiosPost(`mgapi/model_rebuild`);
  return rebuildModel(model)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      // dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};
