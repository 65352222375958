import { axiosErrors, axiosGet, axiosPut } from '../helpers/axios';

export const loadMenuAsync =
  (options = { isNeedSpinner: false }) =>
  (dispatch) => {
    // const loadMenu = axiosGet(`mgapi/resources/content`, options);
    const loadMenu = axiosGet(`definitions/content`, options);
    return loadMenu()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };

export const loadArticleAsync =
  (id, options = { isNeedSpinner: false }) =>
  (dispatch) => {
    // const loadArticle = axiosGet(`mgapi/resources/articles/${id}`, options);
    const loadArticle = axiosGet(`definitions/articles/${id}`, options);
    return loadArticle()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };

export const loadArticleListAsync =
  (options = { isNeedSpinner: false }) =>
  (dispatch) => {
    const loadArticleList = axiosGet(`definitions/list`, options);
    return loadArticleList()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };

export const loadArticleListItemAsync =
  (id, options = { isNeedSpinner: false }) =>
  (dispatch) => {
    const loadArticleListItem = axiosGet(`definitions/item/${id}`, options);
    return loadArticleListItem()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };

export const putArticleListItemAsync =
  (currentArticle, options = { isNeedSpinner: false }) =>
  (dispatch) => {
    const putArticleListItem = axiosPut(`definitions/item/${currentArticle.id}`, options);
    return putArticleListItem({
      word: currentArticle?.word,
      definition: currentArticle?.text,
      name: currentArticle?.name
    })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };

export const putArticlePreviewAsync =
  (text, options = { isNeedSpinner: false }) =>
  (dispatch) => {
    const putArticlePreview = axiosPut(`/definitions/preview`, options);
    return putArticlePreview({
      definition: text
    })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };
