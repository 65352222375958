export class RoleModel {
  id = '';
  title = '';

  constructor(data) {
    if (data) {
      this.id = data.id || '';
      this.title = data.title || 0;
    }
  }
}

export const roles = {
  Administrator: 1,
  Member: 2,
  Guest: 4,
  Influencer: 5,
  Full: 6,
  Free: 7,
  Standard: 8,
  Corporate: 9
};

export const subscriptions = {
  Free: 'Free',
  Standard: 'Standard',
  Full: 'Full',
  Corporate: 'Corporate'
};

export const subscriptionsIdMapping = {
  '1603a6ca-cd38-4758-ae46-ced070162de4': subscriptions.Free,
  '4af332be-7192-42d3-9a66-a24c6a4eb346': subscriptions.Standard,
  'b225d6fa-11f9-4201-a115-6c14025d0228': subscriptions.Full,
  'd2436b52-7281-46cb-bbd9-be2572bbbbaa': subscriptions.Corporate
};
