export const unitOptions = [
  {
    label: 'Unit',
    value: 1,
    customOptionTitle: 'Unit'
  },
  {
    label: 'k',
    value: 1e3,
    customOptionTitle: 'Thousand'
  },
  {
    label: 'M',
    value: 1e6,
    customOptionTitle: 'Million'
  },
  {
    label: 'B',
    value: 1e9,
    customOptionTitle: 'Billion'
  }
];
