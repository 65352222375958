import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

import admin from './slices/adminSlice';
import alerts from './slices/alertsSlice';
import auth from './slices/authSlice';
import common from './slices/commonSlice';
import company from './slices/companySlice';
import definition from './slices/definitionSlice';
import financialModel from './slices/financialModelSlice';
import portfolio from './slices/portfolioSlice';
import product from './slices/productSlice';
import profile from './slices/profileSlice';
import screening from './slices/screeningSlice';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
});

export const Store = configureStore({
  reducer: {
    common,
    admin,
    auth,
    screening,
    company,
    alerts,
    router: routerReducer,
    profile,
    product,
    portfolio,
    definition,
    financialModel
  },
  // middleware: [
  //   ...getDefaultMiddleware({
  //     serializableCheck: false
  //   }),
  //   routerMiddleware
  // ]
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(routerMiddleware)
});

export const History = createReduxHistory(Store);
export const AppDispatch = typeof Store.dispatch;
