import React, { useCallback } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { roles } from 'src/app/models/role';
import { selectProfile } from 'src/app/slices/profileSlice';

import { defineStyles } from './functions';
import styles from './Subscription.module.css';

export const Subscription = ({ subscription, selected, onClick }) => {
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(subscription?.id);
    }
  }, [onClick, subscription?.id]);

  const profile = useSelector(selectProfile);

  const getSplittedDescription = (description) => {
    return description.split('\n').map((c, index) => {
      // FIX: временное решение, при появлении точных цен данные с бэкенда не будут видоизменяться
      if (c.includes('/ month')) {
        return <li key={index}> starting {c} </li>;
      }
      return <li key={index}> {c} </li>;
    });
  };
  return (
    subscription && (
      <div
        className={classNames(styles.subscription, {
          [styles.subscriptionSelected]: selected && selected?.id === subscription.id,
          [styles.subscriptionDisabled]: profile?.roleId === roles.Influencer
        })}
        onClick={handleOnClick}
        style={defineStyles(subscription.name)}
        data-test={`product_${subscription.name}`}
      >
        <div
          className={classNames(styles.subscriptionContent, {
            [styles.subscriptionContentSelected]: selected?.id === subscription.id,
            [styles.subscriptionContentDisabled]: profile?.roleId === roles.Influencer
          })}
        >
          <h4>{subscription.name}</h4>
          <ul>{getSplittedDescription(subscription.description)}</ul>
        </div>
      </div>
    )
  );
};
