import React from 'react';

import { Radio } from 'antd';
import './styles.css';

export const AppRadio = ({ label, value, checked, onChange, ...rest }) => {
  return (
    <div className="mg-input-radio__wrap">
      <Radio checked={checked} value={value} className="mg-radio-input" onChange={onChange} {...rest}>
        {label}
      </Radio>
    </div>
  );
};
