import React from 'react';

import './styles.css';
import { ReactComponent as ArrowSort } from 'src/assets/svg/arrowSort.svg';
/**
 * @param {object} props
 * @param {ColumnTypes.ColumnSort} props.sort
 * @param {boolean} props.isActive
 * @param {() => void} [props.handleClick]
 */
export const AppTableSort = ({ sort, isActive, handleClick }) => {
  return (
    <div className="table-sort-wrap" onClick={handleClick}>
      <ArrowSort className={`${isActive && sort.direction === 'ASC' ? '_active' : ''}`} />{' '}
      <ArrowSort className={`table-sort-down ${isActive && sort.direction === 'DESC' ? '_active' : ''}`} />
    </div>
  );
};
