import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SendLinkForm } from '../send-link-form/sendLinkForm';
import { Stepper } from '../stepper/index';
import { recoverPasswordLinkAsync } from 'src/app//api/notification';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';

import styles from './FirstStep.module.css';
import { PasswordRecoveryFirstStepSuccess } from './firstStepSuccess';

export const PasswordRecoveryFirstStep = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const current = 0;
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (val) => {
      dispatch(recoverPasswordLinkAsync(val.email))
        .then((data) => {
          setSuccess(true);
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    },
    [dispatch]
  );

  const stepCounter = [
    {
      component: <SendLinkForm onSubmit={handleSubmit} />
    }
  ];

  return (
    <div className="page">
      <h1 className={styles.title + ' page__title-uppercase'}>{t('title_recovery_password')}</h1>
      <Stepper current={current} totalCount={3}>
        {' '}
      </Stepper>
      {!success ? stepCounter[current].component : <PasswordRecoveryFirstStepSuccess />}
    </div>
  );
};
