import moment from 'moment';

export const parseCronTimeTo12hFormat = (value) => {
  // example of cronTime: "10 15 12 */1 * *" (At 12:15:10 PM, every day)
  const elements = value.split(' ');
  const hours = elements[2];
  const minutes = elements[1];

  return moment(`${hours}:${minutes}`, 'HH:mm a');
};

export const parse12hFormatToCronTime = (value) => {
  return `00 ${value.minutes()} ${value.hours()} * * *`;
};
