import React from 'react';

import styles from '../../Footer.module.css';

export const TextSection = ({ title, text, children, setInnerHTML = false }) => {
  return (
    <div className={`${styles.textBlock} ${styles.textSection}`}>
      <h2>{title}</h2>
      {text && (setInnerHTML ? <p dangerouslySetInnerHTML={{ __html: text }}></p> : <p>{text}</p>)}
      {children}
    </div>
  );
};
