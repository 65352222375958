import { axiosGet, axiosErrors } from '../helpers/axios';

/** @type {(options: { isNeedSpinner: boolean } ) => Promise<ColumnTypes.Name[]>} */
export const loadNames = (options = { isNeedSpinner: false }) => {
  const load = axiosGet(`dictionaries/names`, options);
  return load()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};
