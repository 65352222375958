import React, { useCallback, useState, useEffect } from 'react';

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { AppInputNumber } from 'src/app/components/form/input-number';
import { selectUserData, setInteractions } from 'src/app/slices/financialModelSlice';

import styles from './ModelTimeHorizonModal.module.css';

export const ModelTimeHorizon = ({ isVisible, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userData = useSelector(selectUserData);

  const [investmentTime, setInvestmentTime] = useState();
  const [value, setValue] = useState();

  useEffect(() => {
    setInvestmentTime(userData?.time_horizon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleChangeInvestmentTime = useCallback((value) => {
    const editedValue = Math.round(value);
    setValue(value);
    setInvestmentTime(editedValue);
  }, []);

  const handleUpdate = useCallback(() => {
    if (investmentTime && investmentTime !== userData?.time_horizon) {
      dispatch(
        setInteractions({
          time_horizon: investmentTime
        })
      );
    }
    handleClose();
  }, [dispatch, handleClose, investmentTime, userData?.time_horizon]);

  return (
    <Modal
      title={t('model_time_modal_title')}
      centered
      width={600}
      visible={isVisible}
      className={`mg-modal`}
      okText={t('update_button_label')}
      cancelText={t('cancel')}
      getContainer={'main'}
      okButtonProps={
        value > 1 && value < 21
          ? {
              className: `mg-btn ${styles.button}`
            }
          : {
              className: `mg-btn disabled ${styles.button}`
            }
      }
      cancelButtonProps={{
        className: `mg-btn _green-border ${styles.button}`,
        'data-test': 'alerts_page_cancel_alert'
      }}
      onOk={handleUpdate}
      onCancel={handleClose}
    >
      <div className={styles.parametersInputWrapper}>
        <span>{t('investment_time_horizon_label')}</span>
        <AppInputNumber value={investmentTime} onChange={handleChangeInvestmentTime}></AppInputNumber>
      </div>
    </Modal>
  );
};
