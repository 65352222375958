import React, { useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LocalStorageHelper } from '../helpers/localstore';
import { AppEmptyLayout } from '../layouts/empty-layout';
import { AppPrivateLayout } from '../layouts/private-layout';
import { selectIsAuthenticated } from '../slices/authSlice';

export const ProtectedRoute = ({ children, isNeedHeader, ...rest }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const navigate = useNavigate();

  const navigateHome = useCallback(() => {
    navigate('/');
    // appMessage(typeMessage.ERROR, t('must_logged_message'));
  }, [navigate]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigateHome();
    }
  }, [isAuthenticated, navigateHome]);

  return isAuthenticated && LocalStorageHelper.getData() ? (
    rest.layout === 'empty' ? (
      <AppEmptyLayout isNeedHeader={isNeedHeader}>{children}</AppEmptyLayout>
    ) : (
      <AppPrivateLayout isNeedHeader={isNeedHeader}>{children}</AppPrivateLayout>
    )
  ) : (
    ''
  );
};
