import { createSlice } from '@reduxjs/toolkit';

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    admins: [],
    isShowErrorMessageAdmin: false,
    usersTotal: 0,
    groupsTotal: 0
  },
  reducers: {
    setAdmins: (state, action) => {
      state.admins = action.payload;
    },
    setIsShowErrorMessageAdmin: (state, action) => {
      state.isShowErrorMessageAdmin = action.payload;
    },
    setUsersTotal: (state, action) => {
      state.usersTotal = action.payload;
    },
    setGroupsTotal: (state, action) => {
      state.groupsTotal = action.payload;
    }
  }
});

export const { setAdmins, setIsShowErrorMessageAdmin, setUsersTotal, setGroupsTotal } = adminSlice.actions;

export const selectAdmins = (state) => state.admin.admins;
export const selectIsShowErrorMessageAdmin = (state) => state.admin.isShowErrorMessageAdmin;
export const selectUsersTotal = (state) => state.admin.usersTotal;
export const selectGroupsTotal = (state) => state.admin.groupsTotal;

export default adminSlice.reducer;
