import React from 'react';

import { Button } from 'antd';
import DragListView from 'react-drag-listview';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseSvg } from 'src/assets/svg/close.svg';

import styles from './SortOrder.module.css';
import './styles.css';

export const TableSortOrder = ({ sort, onDeleteSort, isVisibleScroll, onSortEnd }) => {
  const { t } = useTranslation();
  // const handleSortOver = (data) => {
  //   const { newIndex, oldIndex } = data;
  //   onSortEnd(oldIndex, newIndex);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      onSortEnd(fromIndex, toIndex);
    },
    // handleSelector: 'div[class="SortOrder_sortItem__yKRBJ"]',
    nodeSelector: 'div[class]',
    lineClassName: 'global-drag-line-sort'
  };

  return (
    <div className={`${styles.orderWrap} ${!isVisibleScroll ? styles.scrollVisible : ''}`}>
      <span className={styles.orderTitle}>{t('order_title')}</span>
      <div className={styles.sortList}>
        {/* <SortableList
          shouldCancelStart={shouldCancelStart}
          axis={'x'}
          onDeleteSort={onDeleteSort}
          sort={sort}
          onSortEnd={(data) => handleSortOver(data)}
        /> */}
        <DragListView {...dragProps}>
          <div className={styles.carousel}>
            {sort.map((sort, i) => (
              <div className={styles.sortItem} key={i}>
                {sort.sortTitle}
                <Button
                  data-test="custom_screen_page_sort_order_close_item"
                  className={`mg-btn _text ${styles.buttonClose}`}
                  onClick={() => onDeleteSort(sort)}
                >
                  <CloseSvg />
                </Button>
              </div>
            ))}
          </div>
        </DragListView>
      </div>
    </div>
  );
};
