import { alertColumnTitlePositionTable, typeSchema } from './consts';

export const definePersonPosition = (isDirector, isOfficer) => {
  if (isDirector && isOfficer) {
    return 'director_officer_position_label';
  } else if (isDirector) {
    return 'director_position_label';
  } else {
    return 'officer_position_label';
  }
};

export const defineTitleAlertColumn = (alertTitle) => {
  switch (alertTitle) {
    case 'insiderAcquiringOpenMarket':
      return alertColumnTitlePositionTable.insiderAcquiringOpenMarket;
    case 'insiderSelling':
      return alertColumnTitlePositionTable.insiderSelling;
  }
};

export const getAlertBySchema = (schema, company) => {
  const alertBySchema = {};
  switch (schema.type) {
    case typeSchema.object: {
      const { properties } = schema;
      const propertiesKeys = Object.keys(properties);
      propertiesKeys.forEach((key) => {
        alertBySchema[key] = company[key];
      });
    }
  }
  return alertBySchema;
};

export const colorResult = (text, value) => {
  const index = text?.toLowerCase().indexOf(value?.toLowerCase());
  if (index !== -1 && value) {
    const newText = text;
    const coloredPart = newText.slice(index, index + value.length);
    return text.replaceAll(new RegExp(value.toLowerCase(), 'ig'), `<span class="colored-result">${coloredPart}</span>`);
  }
  return text;
};
