export class LocalStorageHelper {
  static STORE_KEY = 'mg_bearer';

  static getValue(prop) {
    const data = LocalStorageHelper.getData();
    return data[prop];
  }

  static setData(data) {
    localStorage.setItem(LocalStorageHelper.STORE_KEY, data);
  }

  static getData() {
    const store = localStorage.getItem(LocalStorageHelper.STORE_KEY);
    if (store) {
      return store;
    } else {
      return null;
    }
  }

  static clear() {
    localStorage.removeItem(LocalStorageHelper.STORE_KEY);
  }
}
