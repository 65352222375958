import { axiosErrors, axiosGet, axiosPost, axiosPut } from '../helpers/axios';
import { setIsShowErrorMessageAuth } from '../slices/authSlice';
import { setIsShowErrorMessageProfile } from '../slices/profileSlice';

const signin = axiosPost('account/sign-in');
const signout = axiosGet('account/sign-out');

export const signinAsync = (email, password) => (dispatch) => {
  return signin({
    email,
    password
  })
    .then((res) => {
      dispatch(setIsShowErrorMessageAuth(false));
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAuth(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const oauthSocialNetworkAsync = (code, type) => (dispatch) => {
  const oauthSocialNetwork = axiosGet(`account/oauth/${type}`);
  return oauthSocialNetwork({ code, type })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageAuth(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const signupAsync = (hash, formData) => (dispatch) => {
  const { email, password, name, surname } = formData;
  const signup = axiosPost(`account/sign-up/${hash}`);
  return signup({
    email,
    password,
    name,
    surname
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProfile(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const changePasswordAsync = (oldPassword, newPassword) => (dispatch) => {
  const changePassword = axiosPut(`account/change-password`);
  return changePassword({
    oldPassword: oldPassword,
    newPassword: newPassword
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProfile(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const recoverPasswordAsync = (hash, email, password) => (dispatch) => {
  const recoverPassword = axiosPut(`account/recover-password/${hash}`);
  return recoverPassword({
    email,
    password
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProfile(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const signoutAsync = () => (dispatch) => {
  return signout()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProfile(axiosErrors(e)));
      throw axiosErrors(e);
    });
};
