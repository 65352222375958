import React, { useState, useCallback, useEffect } from 'react';

import { Divider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { loadAccountsAsync } from 'src/app/api/admin';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';

import { SearchForm } from './searchForm';
import { SearchResults } from './searchResults';
import styles from './SearchUsersModal.module.css';

export const SearchUserModal = ({ visible, onChangeVisible, members }) => {
  const [results, setResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      setSelectedUsers(members);
      dispatch(loadAccountsAsync(0, 100)).then((data) => {
        setResults(
          data?.items.map((user) => {
            return {
              email: user.email,
              checked: !!members.find((member) => member === user.email)
            };
          })
        );
      });
    }
  }, [dispatch, members, visible]);

  const handleSearch = useCallback(
    (searchValue) => {
      dispatch(loadAccountsAsync(0, 100, null, searchValue))
        .then((data) => {
          setResults((prev) => {
            return data?.items.map((user) => {
              return {
                email: user?.email,
                checked: !!selectedUsers.find((member) => member === user.email)
              };
            });
          });
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    },
    [dispatch, selectedUsers]
  );

  const handleChangeSearch = useCallback((val) => {
    setSearch(val);
  }, []);

  const handleOk = () => {
    setSearch('');
    setResults([]);
    onChangeVisible(selectedUsers);
  };

  const handleCancel = () => {
    setSearch('');
    setResults([]);
    onChangeVisible(members);
  };

  const handleChange = (element) => {
    setResults((prev) => {
      return prev.map((el) => {
        if (el.email === element.email) {
          return { ...el, checked: element.checked };
        }
        return el;
      });
    });
    setSelectedUsers((prev) => {
      return element.checked ? [element.email, ...prev] : prev.filter((el) => el !== element.email);
    });
  };

  return (
    <Modal
      title={''}
      centered
      width={800}
      visible={visible}
      forceRender
      className={`mg-modal`}
      okText={t('save_btn')}
      cancelText={t('cancel')}
      getContainer={'main'}
      okButtonProps={{
        className: `mg-btn ${styles.button}`,
        'data-test': 'custom_screen_page_search_column_modal_add'
      }}
      cancelButtonProps={{
        className: `mg-btn _green-border ${styles.button}`,
        'data-test': 'custom_screen_page_search_column_modal_cancel'
      }}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p className={styles.titleModal}>{t('users_search_title')}</p>
      <Divider className={styles.divider} />
      <SearchForm onSearch={handleSearch} value={search} onChange={handleChangeSearch} />
      <SearchResults results={results} onChange={handleChange} />
    </Modal>
  );
};
