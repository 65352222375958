import React, { useState, useEffect, useCallback, useMemo } from 'react';

// import { Row, Col } from 'antd';
// import { useTranslation } from 'react-i18next';
// import { InfoBox } from './components/info-box/index';
import { SideBar } from './components/sidebar/index';
import { WhyMgTextBlock } from './components/text-blocks/whyMg';
import { WithMg } from './components/text-blocks/withMachineGrading';
import { sidebarMenu } from './consts';
import styles from './WhyMg.module.css';

export const WhyMG = () => {
  // const { t } = useTranslation();
  const [container, setContainer] = useState(null);
  const [selectedId, setSelectedId] = useState('title-1');
  const [offsetTop, setOffsetTop] = useState(200);
  const [position, setPosition] = useState('absolute');
  const [elements, setElements] = useState([]);
  const sidebarItems = useMemo(() => sidebarMenu, []);

  const onScroll = useCallback(() => {
    const pageOffset = window.pageYOffset;
    if (pageOffset >= container?.offsetTop) {
      const height = container?.clientHeight - pageOffset;
      if (height > 400) {
        setPosition('fixed');
        setOffsetTop(110);
      } else if (position !== 'absolute') {
        setPosition('absolute');
        setOffsetTop(pageOffset + 40);
      }
    } else {
      setPosition('absolute');
      setOffsetTop(container?.offsetTop + 72);
    }

    sidebarItems.forEach((item) => {
      const element = document.getElementById(item.id);
      if (window.pageYOffset >= element.offsetTop - 100) {
        setSelectedId(item.id);
      }
      if (item.submenu) {
        item.submenu.forEach((elem) => {
          const element = document.getElementById(elem.id);
          if (window.pageYOffset >= element.offsetTop - 100) {
            setSelectedId(elem.id);
          }
        });
      }
    });
  }, [container?.offsetTop, container?.clientHeight, sidebarItems, position]);

  useEffect(() => {
    // setOffsetTop(container?.offsetTop + 72);
    sidebarItems.forEach((item) => {
      if (item.submenu) {
        item.submenu.forEach((elem) => {
          setElements((elems) => [...elems, document.getElementById(elem.id)]);
        });
      }
      setElements((elems) => [...elems, document.getElementById(item.id)]);
    });
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [container?.offsetTop, onScroll, sidebarItems]);

  const handleClickSidebar = useCallback(
    (id) => {
      setSelectedId(id);
      const elem = elements.find((elem) => elem.id === id);
      window.scrollTo({
        top: elem.offsetTop - 100,
        behavior: 'smooth'
      });
    },
    [elements]
  );
  return (
    <div>
      {/* <Row className={styles.promo} justify="center">
        <Col span={24}>
          <h1>{t('promo_title')}</h1>
          <p>{t('promo_desc')}</p>
        </Col>
      </Row> */}
      <div className={styles.page} ref={setContainer}>
        <div className={styles.sideBar} style={{ top: offsetTop + 'px', position: position }}>
          <SideBar items={sidebarItems} onClick={handleClickSidebar} selectedId={selectedId} />
        </div>
        <WhyMgTextBlock />
        <WithMg />
      </div>
    </div>
  );
};
