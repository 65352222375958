import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { AppCheckbox } from 'src/app/components/form/checkbox';

import styles from './SearchColumnModal.module.css';

export const SearchResults = ({ results, onChange }) => {
  return (
    <PerfectScrollbar options={{ wheelPropagation: false }} className={'scroll-search-column'}>
      <div className={styles.resultsWrap}>
        {results?.map((elem) => {
          return (
            <div
              key={elem.key}
              className={styles.resultItem}
              onClick={() => onChange({ ...elem, checked: !elem.checked })}
            >
              <div className={styles.titleColumn}>
                <span>{elem.groupName || elem.title}</span>
              </div>
              <div>
                <AppCheckbox value={elem.checked} />
              </div>
            </div>
          );
        })}
      </div>
    </PerfectScrollbar>
  );
};
