// import { message } from 'antd';
import React from 'react';

import { useTranslation } from 'react-i18next';

import svgIcon from 'src/assets/svg/Union.svg';

import styles from './SetPasswordSteps.module.css';

export const SuccessInfo = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.successWrap} data-test="recovery__success">
      <img src={svgIcon} />
      <h4>{t('password_recovery_success_title')}</h4>
    </div>
  );
};
