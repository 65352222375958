export class PollModel {
  id = '';
  title = '';
  creator = '';
  questionsAmount = 0;
  membersAmount = 0;
  createdAt = '';

  constructor(data) {
    if (data) {
      this.id = data.id || '';
      this.title = data.title || '';
      this.creator = data.creator || '';
      this.questionsAmount = data.questionsAmount || 0;
      this.membersAmount = data.membersAmount || 0;
      this.createdAt = data.createdAt || '';
    }
  }
}
