import React, { useState } from 'react';

import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { AppTextarea } from 'src/app/components/form/text-area';

import styles from './ContactSupport.module.css';

export const ContactSupport = ({ visible, onCreate, onCancel, supportData }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);

  const handleAfterClose = () => {
    form.resetFields();
    // setCreateButtonDisabled(true);
  };

  const handleOnFieldsChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setCreateButtonDisabled(hasErrors);
  };

  return (
    <Modal
      title={t('contact_support_modal_title')}
      centered
      visible={visible}
      forceRender
      getContainer={'main'}
      className={`mg-modal`}
      okText={t('add')}
      cancelText={t('cancel')}
      data-test={'contact_support_modal'}
      okButtonProps={{
        disabled: createButtonDisabled,
        className: `mg-btn`
      }}
      cancelButtonProps={{
        className: `mg-btn _green-border`
      }}
      onOk={() => {
        onCreate(form.getFieldsValue());
        form.resetFields();
      }}
      onCancel={() => onCancel()}
      afterClose={handleAfterClose}
    >
      {supportData ? (
        <>
          <p>{t('support_data_text')}</p>
          <ul className={styles.companyData}>
            <li>
              <b>{t('support_company_cik_text')} </b> - {supportData.cik || ''}
            </li>
            <li>
              <b>{t('support_company_ticker_text')}</b> - {supportData.ticker || ''}
            </li>
            <li>
              <b>{t('support_company_column_text')}</b> - {supportData.column || ''}
            </li>
            <li>
              <b>{t('support_company_value_text')} </b> - {supportData.data || ''}
            </li>
            <li>
              <b>{t('contact_support_topic')} </b> - {t('problem_with_data_topic')}
            </li>
          </ul>
        </>
      ) : (
        ''
      )}

      <Form
        name="requestSupport"
        form={form}
        autoComplete="off"
        onValuesChange={handleOnFieldsChange}
        className={`${styles.supportForm} support`}
      >
        <Form.Item
          name="message"
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: t('required_input')
            }
          ]}
        >
          <AppTextarea data-test="profile_page_message" placeholder={t('message')}></AppTextarea>
        </Form.Item>
      </Form>
    </Modal>
  );
};
