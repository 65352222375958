import React, { useEffect, useState } from 'react';

import { Modal, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { AppTextarea } from 'src/app/components/form/text-area';

import './style.css';
import styles from './ProductDescriptionModal.module.css';

export const ProductDescriptionModal = ({ visible, onSave, onCancel, product }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [saveDisabledButton, setSaveDisabledButton] = useState(true);
  const editDescriptionInitialValues = {
    description: ''
  };

  useEffect(() => {
    form.setFields([
      {
        name: 'description',
        value: product.description
      }
    ]);
  }, [form, product, visible]);

  const handleOnFieldsChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setSaveDisabledButton(hasErrors);
  };

  const handleAfterClose = () => {
    form.resetFields();
    setSaveDisabledButton(true);
  };

  return (
    <Modal
      title={`"${product.name}" ${t('description')}`}
      centered
      visible={visible}
      forceRender
      getContainer={'main'}
      className={`mg-modal edit-description-product`}
      okText={t('save')}
      cancelText={t('cancel')}
      okButtonProps={{
        disabled: saveDisabledButton,
        className: `mg-btn`,
        'data-test': 'products_edit-description_save'
      }}
      cancelButtonProps={{ className: `mg-btn _green-border`, 'data-test': 'products_edit_description_cancel' }}
      onOk={() => onSave(form.getFieldsValue())}
      onCancel={onCancel}
      afterClose={handleAfterClose}
    >
      <p className={styles.additionalText}>{t('edit_description_product_modal_text')}</p>
      <Form
        name="edit-description-product"
        form={form}
        initialValues={editDescriptionInitialValues}
        autoComplete="off"
        className="form"
        onFieldsChange={handleOnFieldsChange}
      >
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: t('required_input')
            }
          ]}
        >
          <AppTextarea data-test="products_edit_description_text"></AppTextarea>
        </Form.Item>
      </Form>
    </Modal>
  );
};
