import React from 'react';

import styles from '../../Footer.module.css';

export const TermsList = ({ title, beforeText, listItems, nestedListItems }) => {
  return (
    <div className={`${styles.textBlock}`}>
      {title && (
        <h2>
          <li>{title}</li>
        </h2>
      )}
      <ol>
        {listItems.map((listBlock, i) => (
          <>
            {beforeText[i] && (
              <p>
                <b>{beforeText[i]}</b>
              </p>
            )}
            {listBlock.map((listItem, j) =>
              nestedListItems[i] ? (
                <li key={j}>
                  <span dangerouslySetInnerHTML={{ __html: listItem }}></span>
                  <ol>
                    {nestedListItems[i].map((nestedItem, k) => (
                      <li key={k} dangerouslySetInnerHTML={{ __html: nestedItem }}></li>
                    ))}
                  </ol>
                </li>
              ) : (
                <li key={j} dangerouslySetInnerHTML={{ __html: listItem }}></li>
              )
            )}
          </>
        ))}
      </ol>
    </div>
  );
};
