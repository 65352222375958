import React, { useState } from 'react';

import { Menu, Dropdown, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DeleteSvg } from 'src/assets/svg/delete.svg';
import { ReactComponent as PriceSvg } from 'src/assets/svg/money.svg';
import { ReactComponent as VerticalDots } from 'src/assets/svg/vertical-dots.svg';

import styles from './RowMenu.module.css';

export const RowMenuPrice = ({ onClickElement }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleChange = (visible) => {
    setVisible(visible);
  };
  return (
    <Dropdown
      onVisibleChange={handleChange}
      overlay={
        <Menu className={styles.dropWown}>
          <Menu.Item key="0">
            <Button
              data-test="alerts_page_add_price_row_price"
              className={`mg-btn _text ${styles.addPrice}`}
              onClick={() => {
                onClickElement('addPrice');
                handleChange(false);
              }}
            >
              <PriceSvg /> {t('set_alert_btn')}
            </Button>
          </Menu.Item>
          <Menu.Item key="1">
            <Button
              data-test="alerts_page_delete_alert_price"
              className={`mg-btn _text ${styles.deleteAlert}`}
              onClick={() => {
                handleChange(false);
                onClickElement('delete');
              }}
            >
              <DeleteSvg /> {t('delete_alerts_text')}
            </Button>
          </Menu.Item>
        </Menu>
      }
      trigger={['click']}
    >
      <a
        data-test="alerts_page_vertical_dots_price"
        className={`${styles.button} mg-btn _text ${visible ? styles.visible : ''}`}
        onClick={(e) => e.preventDefault()}
      >
        <VerticalDots />
      </a>
    </Dropdown>
  );
};
