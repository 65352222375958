import React from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import notificationsStyles from '../../Notifications.module.css';
import AppInput from 'src/app/components/form/input';
import styles from 'src/app/modules/notifications-and-polls/NotificationsAndPolls.module.css';

export const SearchPanel = ({ onSearch, handleOnAddNotification }) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.topPanel} ${notificationsStyles.topPanel} topPanel`}>
      <Button onClick={handleOnAddNotification} className={`mg-btn _text ${styles.addNotificationBtn}`}>
        {t('add_notification_btn')}
      </Button>

      <AppInput
        data-test="notification_search"
        placeholder={t('search_notification_placeholder')}
        onPressEnter={onSearch}
      ></AppInput>
    </div>
  );
};
