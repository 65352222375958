import React, { useEffect, useState } from 'react';

import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

import { loadAllPollsAsync, loadAllNotificationsAsync } from 'src/app/api/polls-and-notifications';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';

import { Notifications } from './components/notifications';
import { Polls } from './components/polls';
import styles from './NotificationsAndPolls.module.css';

import './styles.css';
import 'src/app/modules/admin/styles.css';

const { TabPane } = Tabs;

export const NotificationsAndPolls = () => {
  const [notificationsTotal, setNotificationsTotal] = useState([]);
  const [pollsTotal, setPollsTotal] = useState([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllPollsAsync())
      .then((data) => {
        setPollsTotal(data.total);
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });

    dispatch(loadAllNotificationsAsync())
      .then((data) => {
        setNotificationsTotal(data.total);
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch]);

  return (
    <>
      <div>
        <div className={styles.paddings}>
          <h1 className={`${styles.title} page__title-uppercase`}>{t('notifications_title')}</h1>
        </div>
      </div>
      <Tabs
        className="tabs-admins"
        defaultActiveKey={location.hash ? location.hash.replace('#', '') : '1'}
        onChange={(key) => {
          navigate(`/notifications-and-polls#${key}`);
        }}
      >
        <TabPane tab={`${t('notifications_tab')} (${notificationsTotal})`} key="notifications">
          <Notifications />
        </TabPane>
        <TabPane tab={`${t('polls_tab')} (${pollsTotal})`} key="polls">
          <Polls />
        </TabPane>
      </Tabs>
    </>
  );
};
