import React from 'react';

import { Steps } from 'antd';

import { CustomDot } from './customStepDot';
import styles from './Stepper.module.css';

const { Step } = Steps;

export const Stepper = ({ current, totalCount }) => {
  return (
    <Steps current={current} progressDot={CustomDot}>
      {[...new Array(totalCount)].map((item, i) => {
        return <Step key={i} className={styles.stepContainer} />;
      })}
    </Steps>
  );
};
