import { axiosDelete, axiosErrors, axiosGet, axiosPost, axiosPut } from '../helpers/axios';

export const loadPollInfoAsync = (pollId) => () => {
  const loadPollInfo = axiosGet(`polls/${pollId}`);
  return loadPollInfo()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadMyPollsAsync = () => () => {
  const loadPolls = axiosGet(`polls/my`);
  return loadPolls()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadAllPollsAsync = (index, limit, queryTemplate, order) => () => {
  const loadPolls = axiosGet(
    `polls?index=${index || 0}&limit=${limit || 20}${queryTemplate ? `&where[title][_iLike]=%${queryTemplate}%` : ''}${
      order ? `&order=${order.column}&order=${order.direction}` : ''
    }`
  );
  return loadPolls()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const createPollAsync = (poll) => () => {
  const createPoll = axiosPost(`polls`);
  return createPoll(poll)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const updatePollAsync = (poll, id) => () => {
  const updatePoll = axiosPut(`polls/${id}`);
  return updatePoll(poll)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const createPollAnswerAsync = (body) => () => {
  const createPollAnswer = axiosPost(`poll-answers`);
  return createPollAnswer(body).catch((e) => {
    throw axiosErrors(e);
  });
};

export const markPollSeen = (id) => () => {
  const createPollAnswer = axiosPut(`polls/seen/${id}`);
  return createPollAnswer().catch((e) => {
    throw axiosErrors(e);
  });
};

export const deletePollAsync = (id) => () => {
  const deletePoll = axiosDelete(`polls/${id}`);
  return deletePoll()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadAllNotificationsAsync = (index, limit, queryTemplate, order) => () => {
  const loadNotifications = axiosGet(
    `admin-notification?index=${index || 0}&limit=${limit || 20}${
      queryTemplate ? `&where[text][_iLike]=%${queryTemplate}%` : ''
    }${order ? `&order=${order.column}&order=${order.direction}` : ''}`
  );
  return loadNotifications()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadMyNotificationsAsync = () => () => {
  const loadNotifications = axiosGet(`admin-notification/my`);
  return loadNotifications()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadNotificationInfoAsync = (id) => () => {
  const loadNotificationInfo = axiosGet(`admin-notification/${id}`);
  return loadNotificationInfo()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const deleteNotificaionAsync = (id) => () => {
  const deleteNotificaion = axiosDelete(`admin-notification/${id}`);
  return deleteNotificaion()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const updateNotificationAsync = (data, id) => () => {
  const updateNotifications = axiosPut(`admin-notification/${id}`);
  return updateNotifications(data)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const createNotificationAsync = (data) => () => {
  const createNotification = axiosPost(`admin-notification`);
  return createNotification(data)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const markNotificationSeen = (id) => () => {
  const createNotification = axiosPut(`admin-notification/seen/${id}`);
  return createNotification().catch((e) => {
    throw axiosErrors(e);
  });
};
