import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from '../../WhyMg.module.css';

export const WithMg = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.textBlock}>
      <h2 className={`${styles.lightGreenTitle} ${styles.noBottomMargin}`} id="title-2">
        {t('machine_grading_title')}
      </h2>
      <h3 className={styles.lightGreenSubTitle} id="subtitle-1">
        {t('machine_grading_investment_ideas')}
      </h3>
      <ul className={styles.listStyleNone}>
        <li>
          <h3 className={styles.lightGreenSubTitle}>{t('its_important_title')}</h3>
          <p>{t('machine_grading_investment_ideas_important')}</p>
        </li>
        <li>
          <h3 className={styles.lightGreenSubTitle}>{t('we_better_title')}</h3>
          <p>{t('machine_grading_investment_ideas_better')}</p>
        </li>
      </ul>

      <h3 className={styles.lightGreenSubTitle} id="subtitle-2">
        {t('machine_grading_use_profits')}{' '}
      </h3>
      <ul className={styles.listStyleNone}>
        <li>
          <h3 className={styles.lightGreenSubTitle}>{t('its_important_title')}</h3>
          <p>{t('machine_grading_use_profits_important')}</p>
        </li>
        <li>
          <h3 className={styles.lightGreenSubTitle}>{t('we_better_title')}</h3>
          <p>{t('machine_grading_use_profits_better')}</p>
        </li>
      </ul>

      <h3 className={styles.lightGreenSubTitle} id="subtitle-3">
        {t('machine_grading_features')}{' '}
      </h3>
      <ul className={styles.listStyleNone}>
        <li>
          {' '}
          <h3 className={styles.lightGreenSubTitle}>{t('its_important_title')}</h3>
          <p>{t('machine_grading_features_important')}</p>
        </li>
        <li>
          {' '}
          <h3 className={styles.lightGreenSubTitle}>{t('we_better_title')}</h3>
          <p>{t('machine_grading_features_better')}</p>
        </li>
      </ul>

      {/* <InfoBox
      text={
        'Praesent porttitor in mi sit amet egestas. Phasellus volutpat quis tellus non lacinia. Ut lobortis orci sapien, a lobortis elit accumsan ac. Pellentesque mattis pulvinar feugiat.'
      }
    /> */}
      <p>{t('machine_grading_conclusion')}</p>
    </div>
  );
};
