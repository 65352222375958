import React, { useState } from 'react';

import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { AppInputPassword } from 'src/app/components/form/input-password';

import styles from './PasswordRecoverySetPasswordForm.module.css';

export const SetPasswordForm = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(false);
  const { t } = useTranslation();
  const setPasswordInitialValues = {
    newPassword: ''
  };

  const handleFormChange = () => {
    const hasErrors =
      !form.getFieldError('newPassword').length &&
      !!form.getFieldValue('newPassword') &&
      !form.getFieldError('confirmPassword').length &&
      !!form.getFieldValue('confirmPassword');
    setDisabledSave(hasErrors);
  };

  return (
    <div className={styles.formWrap}>
      <Form
        name="setPassword"
        form={form}
        onFieldsChange={handleFormChange}
        initialValues={setPasswordInitialValues}
        onFinish={onSubmit}
        autoComplete="off"
        className={styles.form}
      >
        <Form.Item
          className={styles.formItem}
          name="newPassword"
          rules={[
            {
              required: true,
              message: t('required_input')
            },
            () => ({
              validator(_, value) {
                const reg = /^(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                if (!value || reg.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('password_incorrect')));
              }
            })
          ]}
        >
          <AppInputPassword
            data-test="password_recovery_page_new_password"
            placeholder={t('new_password')}
            autoComplete={'off'}
          />
        </Form.Item>
        <Form.Item
          className={styles.formItem}
          name="confirmPassword"
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: t('required_input')
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('confirm_password_error')));
              }
            })
          ]}
        >
          <AppInputPassword
            data-test="password_recovery_page_confirm_password"
            placeholder={t('confirm_password')}
            autoComplete={'off'}
          />
        </Form.Item>
        <Button
          data-test="recovery_password_page_save_new_password"
          htmlType="submit"
          className={styles.btn + ' ' + 'mg-btn'}
          disabled={!disabledSave}
        >
          {t('save_new_password_btn')}
        </Button>
      </Form>
      <p className={styles.formRulesText}>{t('password_rules_message')}</p>
    </div>
  );
};
