import { createSlice } from '@reduxjs/toolkit';

// FIX: not used
const definitonSlice = createSlice({
  name: 'definition',
  initialState: {
    definitions: [],
    isShowErrorMessageDefinitions: false,
    isNeedReload: false
  },
  reducers: {
    setDefinitions: (state, action) => {
      state.products = action.payload;
    },
    setIsShowErrorMessageDefinitions: (state, action) => {
      state.isShowErrorMessageDefinitions = action.payload;
    },
    setIsNeedReload: (state, action) => {
      state.isNeedReload = action.payload;
    }
  }
});

export const { setDefinitions, setIsShowErrorMessageDefinitions, setIsNeedReload } = definitonSlice.actions;

export const selectDefinitions = (state) => state.definition.definitions;
export const selectisShowErrorMessageDefinitions = (state) => state.definition.isShowErrorMessageProducts;
export const selectIsNeedReload = (state) => state.definition.isNeedReload;

export default definitonSlice.reducer;
