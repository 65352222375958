import React from 'react';

import { Layout } from 'antd';

import AppFooter from '../../components/footer';
import { AppHeader } from 'src/app/components/header';

import styles from './PrivateLayout.module.css';

const { Content } = Layout;

export const AppPrivateLayout = ({ children, isNeedHeader }) => {
  return isNeedHeader ? (
    <Layout className={styles.PrivateLayout}>
      <AppHeader />
      <Content className={styles.content}>
        <div className={styles.wrapper}>{children}</div>
      </Content>
      <AppFooter className={styles.footer}></AppFooter>
    </Layout>
  ) : (
    <Layout className={styles.PrivateLayout}>
      <Content>
        <div className={styles.wrapper}>{children}</div>
      </Content>
    </Layout>
  );
};
