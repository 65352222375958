import React, { useCallback, useState, useEffect } from 'react';

import { Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { rebuildModelAsync } from 'src/app/api/financial-model';
import { AppInputNumber } from 'src/app/components/form/input-number';
import { roundTo } from 'src/app/helpers/strings';
import { setModel, setMessage, selectUserData, selectModel } from 'src/app/slices/financialModelSlice';

import styles from './SelectAlgorithmModal.module.css';
import './styles.css';

export const SelectAlgorithm = ({ isVisible, handleClose, handleError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const financialModel = useSelector(selectModel);
  const userData = useSelector(selectUserData);

  const [targetDebt, setTargetDebt] = useState();
  const [timeReach, setTimeReach] = useState();
  const [buybacksValue, setBuybacksValue] = useState();

  useEffect(() => {
    setTargetDebt(roundTo(userData?.target_da_ratio, 2));
    setTimeReach(roundTo(userData?.target_period, 2));
    setBuybacksValue(roundTo(userData?.target_buyback_price_book, 2));
  }, [userData?.target_da_ratio, userData?.target_period, userData?.target_buyback_price_book]);

  const handleUpdate = useCallback(() => {
    const interactions = {
      ...(targetDebt && { target_da_ratio: targetDebt }),
      ...(timeReach && { target_period: timeReach }),
      ...(buybacksValue && { target_buyback_price_book: buybacksValue })
    };
    if (Object.keys(interactions).length) {
      dispatch(
        rebuildModelAsync({
          ...financialModel,
          interactions
        })
      )
        .then((model) => {
          dispatch(setModel(model));
          dispatch(setMessage(model.interactions));
        })
        .catch((err) => {
          handleError(err);
        });
    }
    handleClose();
  }, [dispatch, financialModel, handleClose, targetDebt, timeReach, buybacksValue, handleError]);

  const handleClick = () => {
    setBuybacksValue(roundTo(financialModel.service_data.avg_hist_values.AverageBuybackPriceBook, 2));
    setTargetDebt(roundTo(financialModel.service_data.avg_hist_values.AverageDebtToAssets, 2));
    setTimeReach(roundTo(4, 0));
  };

  return (
    <Modal
      title={t('select_algorithm_modal_title')}
      centered
      width={650}
      visible={isVisible}
      className={`mg-modal`}
      okText={t('update_button_label')}
      cancelText={t('cancel')}
      getContainer={'main'}
      okButtonProps={{
        className: `mg-btn ${styles.button}`
      }}
      cancelButtonProps={{
        className: `mg-btn _green-border ${styles.button}`,
        'data-test': 'alerts_page_cancel_alert'
      }}
      onOk={handleUpdate}
      onCancel={handleClose}
    >
      <p>{t('moving_target_description')}</p>
      <fieldset className={styles.field}>
        <legend className={styles.legendContainer}>
          <a className={styles.legend} onClick={handleClick}>
            reset to last 4-years average
          </a>
        </legend>
        <table>
          <tbody className={styles.parametersInputWrapper}>
            <tr>
              <td>{t('target_debt_and_assets_ratio_label')}</td>
              <td>
                <Tooltip overlayClassName="mg-tooltip" title={targetDebt}>
                  <div>
                    <AppInputNumber
                      min={0}
                      max={1}
                      step={0.01}
                      value={targetDebt}
                      onChange={setTargetDebt}
                    ></AppInputNumber>
                  </div>
                </Tooltip>
              </td>
            </tr>
            <tr>
              <td>{t('time_reach_ratio_label')}</td>
              <td>
                <Tooltip overlayClassName="mg-tooltip" title={timeReach}>
                  <div>
                    <AppInputNumber
                      min={1}
                      max={20}
                      step={1}
                      value={roundTo(timeReach, 0)}
                      onChange={setTimeReach}
                    ></AppInputNumber>
                  </div>
                </Tooltip>
              </td>
            </tr>
            <tr>
              <td>{t('buyback_value_label')}</td>
              <td>
                <Tooltip overlayClassName="mg-tooltip" title={buybacksValue}>
                  <div>
                    <AppInputNumber
                      value={buybacksValue}
                      onChange={setBuybacksValue}
                      min={0}
                      max={10000}
                    ></AppInputNumber>
                  </div>
                </Tooltip>
              </td>
              <td>book value</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </Modal>
  );
};
