import React from 'react';

import { Layout } from 'antd';
import './styles.css';

const { Content } = Layout;

export const AppEmptyLayout = ({ children }) => {
  return (
    <Layout className="layout">
      <Content className="content">
        <div className="site-layout-content">{children}</div>
      </Content>
    </Layout>
  );
};
