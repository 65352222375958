import { externalTooltipHandler } from './tooltip';

export const defaultOptions = (
  tiksXLength,
  id,
  isNeedDatasetText,
  inTable,
  isTooltipXLeftAlign = false,
  yTicksStepSize,
  maxYTicksWidth,
  setMaxYTicksWidth,
  legendColors = null
) => ({
  responsive: true,
  elements: {
    point: {
      pointRadius: 2,
      pointBackgroundColor: 'transparent'
    }
  },
  layout: {
    padding: inTable
      ? {
          bottom: -20
        }
      : {
          top: 50,
          bottom: 20,
          left: 20
        }
  },
  plugins: {
    // annotation: {
    //   annotations: {
    //     line1: {
    //       type: 'line',
    //       yMin: 0,
    //       yMax: 0,
    //       borderColor: '#A6B8C7',
    //       borderWidth: 1,
    //       borderDash: [5, 10]
    //     }
    //   }
    // },
    title: {
      display: false
    },
    tooltip: {
      enabled: false,
      external: (context) => externalTooltipHandler(context, id, isNeedDatasetText, isTooltipXLeftAlign)
    },
    htmlLegend: {
      // ID of the container to put the legend in
      containerID: `legend-container-${id}`,
      colors: legendColors
    },
    legend: {
      display: false
    }
  },
  events: ['mousemove', 'mouseout'],
  // onHover: (e) => {
  //   if (!inTable) {
  //     const { chart } = e;
  //     const { tooltip } = chart;
  //     if (tooltip.body) {
  //       let index = null;
  //       chart.scales.x.ticks.forEach((tick, i) => {
  //         if (tick.label.toString() === tooltip.title[0]) {
  //           index = i;
  //         }
  //       });
  //       chart.config.options.scales.x.ticks.color = chart.config.options.scales.x.ticks.color.map(() => '#9CAEBD');
  //       if (index !== null) {
  //         chart.config.options.scales.x.ticks.color[index] = '#011B24';
  //       }
  //       chart.update();
  //     }
  //   }
  // },
  scales: {
    x: {
      grid: {
        color: 'white',
        borderColor: '#A6B8C7',
        tickColor: '#A6B8C7',
        tickLength: 8
      },
      ticks: {
        color: [...new Array(tiksXLength)].map(() => '#9CAEBD'),
        align: 'start',
        font: {
          size: 14,
          lineHeight: '24px'
        }
      }
    },
    y: {
      ...(!inTable && {
        afterFit: (scale) => {
          if (!setMaxYTicksWidth) {
            return;
          }

          if (scale.width > maxYTicksWidth) {
            setMaxYTicksWidth(scale.width);
          } else if (maxYTicksWidth) {
            scale.width = maxYTicksWidth;
          }
        }
      }),
      ticks: {
        color: '#9CAEBD',
        align: 'center',
        // stepSize: yTicksStepSize,
        font: {
          size: 14,
          lineHeight: '20px'
        }
      },
      grid: {
        tickColor: '#A6B8C7',
        tickLength: 8,
        borderColor: '#A6B8C7',
        color: 'white'
      }
    }
  }
});
