import React, { useMemo } from 'react';

import { Input } from 'antd';

import './styles.css';

export const AppInputPassword = ({ placeholder, label, value, onChange, ...rest }) => {
  const componentId = useMemo(() => Date.now().toString(16), []);
  return (
    <div className="mg-input__wrap">
      <label htmlFor={componentId} className="mg-input__label">
        {label}
      </label>
      <Input.Password
        id={componentId}
        value={value}
        autoComplete="off"
        className="mg-input __password"
        placeholder={placeholder}
        onChange={(e) => onChange(e.currentTarget.value)}
        {...rest}
      />
    </div>
  );
};
