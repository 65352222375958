import React from 'react';

import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import AppInput from 'src/app/components/form/input';
import { AppTextarea } from 'src/app/components/form/text-area/index';

import styles from './RequestInvitationForm.module.css';

export const RequestInvitationForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const invitationRequestInitialValues = {
    email: '',
    message: t('invitation_request_message')
  };

  const handleSubmit = (val) => {
    form.resetFields();
    onSubmit(val);
  };

  return (
    <Form
      name="requestInvitation"
      form={form}
      initialValues={invitationRequestInitialValues}
      onFinish={handleSubmit}
      autoComplete="off"
      className={styles.form}
    >
      <Form.Item
        className={styles.formItem}
        name="email"
        rules={[
          {
            type: 'email',
            message: t('invalid_input')
          },
          {
            required: true,
            message: t('required_input')
          }
        ]}
      >
        <AppInput data-test="request_invitation_page_email" type="email" placeholder={t('email')}></AppInput>
      </Form.Item>
      <Form.Item name="message" className={styles.formItem}>
        <AppTextarea data-test="request_invitation_page_message" placeholder={t('message')}></AppTextarea>
      </Form.Item>
      <Form.Item shouldUpdate className={styles.formItem}>
        {() => (
          <Button
            data-test="request_invitation_page_send"
            htmlType="submit"
            className={`mg-btn ${styles.formItem}`}
            disabled={
              form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !form.isFieldTouched('email')
            }
          >
            {t('send')}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
