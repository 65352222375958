import React from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import pollsStyles from '../../Polls.module.css';
import AppInput from 'src/app/components/form/input';
import styles from 'src/app/modules/notifications-and-polls/NotificationsAndPolls.module.css';

export const SearchPanel = ({ onSearch, handleOnAddPoll }) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.topPanel} ${pollsStyles.topPanel} topPanel`}>
      <Button onClick={handleOnAddPoll} className={`mg-btn _text ${styles.addPollBtn}`}>
        {t('add_polls_btn')}
      </Button>

      <AppInput data-test="poll_search" placeholder={t('search_poll_placeholder')} onPressEnter={onSearch}></AppInput>
    </div>
  );
};
