import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { sensitivityAsync } from 'src/app/api/financial-model';
import { selectModel } from 'src/app/slices/financialModelSlice';

import styles from './Sensitivity.module.css';

export const SensitivityModal = ({ visible, onCancel, handleError }) => {
  const financialModel = useSelector(selectModel);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [params, setParams] = useState();
  const [globalParams, setGlobalParams] = useState();
  useEffect(() => {
    if (visible === true) {
      dispatch(sensitivityAsync(financialModel))
        // @ts-ignore
        .then((data) => {
          if (data) {
            // @ts-ignore
            setParams(generateSensParams(data.interactions.sens_params));
            // @ts-ignore
            setGlobalParams(generateSensGlobalParams(data.interactions.sens_global_params));
          }
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }, [dispatch, financialModel, handleError, visible]);
  function generateSensParams(data) {
    if (data) {
      const years = Object.keys(data.us_revenue_growth_forecast);
      let table = '<table>';

      table += '<thead><tr><th>Years</th>';
      Object.keys(data).forEach((param) => {
        table += `<th>${param.replace(/_/g, ' ').toUpperCase()}</th>`;
      });
      table += '</tr></thead>';

      table += '<tbody>';
      years.forEach((year) => {
        table += `<tr><td>${year}</td>`;
        Object.keys(data).forEach((param) => {
          table += `<td>${data[param][year]}</td>`;
        });
        table += '</tr>';
      });
      table += '</tbody>';
      table += '</table>';
      return table;
    }
  }
  function generateSensGlobalParams(data) {
    if (data) {
      let table = '<table>';
      table += '<thead><tr>';
      Object.keys(data).forEach((param) => {
        table += `<th>${param.replace(/_/g, ' ').toUpperCase()}</th>`;
      });
      table += '</tr></thead>';

      table += '<tbody><tr>';
      Object.values(data).forEach((param) => {
        table += `<td>${param.toFixed(6)}</td>`;
      });
      table += '</tr></tbody>';
      table += '</table>';
      return table;
    }
  }

  return (
    <Modal
      title={'Sensitivity'}
      width={680}
      centered
      visible={visible}
      forceRender
      onOk={onCancel}
      onCancel={onCancel}
      okText={t('ok')}
      cancelText={t('cancel')}
      className="mg-modal"
      cancelButtonProps={{ className: 'mg-btn _green-border' }}
      okButtonProps={{ className: 'mg-btn' }}
    >
      <p className={styles.tableSens} dangerouslySetInnerHTML={{ __html: params }}></p>
      <p className={styles.tableSens} dangerouslySetInnerHTML={{ __html: globalParams }}></p>
    </Modal>
  );
};
