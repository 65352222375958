import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AppInput from 'src/app/components/form/input';
import { AppInputPassword } from 'src/app/components/form/input-password';

import styles from './SignUpForm.module.css';

export const SignUpForm = ({ email, onSubmit }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [signupDisabledButton, setSignupDisabledButton] = useState(true);
  const signupInitialValues = {
    email: '',
    password: '',
    name: '',
    surname: '',
    agreed: false
  };

  useEffect(() => {
    form.setFields([{ name: 'email', value: email }]);
  }, [email, form]);

  const handleOnFieldsChange = () => {
    const hasErrors =
      !form.isFieldTouched('name') ||
      !form.isFieldTouched('surname') ||
      !form.isFieldTouched('password') ||
      !form.getFieldValue('agreed') ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setSignupDisabledButton(hasErrors);
  };

  return (
    <div className={styles.wrap}>
      <Form
        name="signup"
        form={form}
        initialValues={signupInitialValues}
        onFinish={onSubmit}
        onFieldsChange={handleOnFieldsChange}
        autoComplete="off"
        className={styles.form}
      >
        <Form.Item
          className={styles.formItem}
          name="email"
          rules={[
            {
              required: true,
              message: t('required_input')
            }
          ]}
        >
          <AppInput placeholder={t('email')} disabled autoComplete="username"></AppInput>
        </Form.Item>

        <Form.Item
          className={styles.formItem}
          name="name"
          rules={[
            {
              required: true,
              message: t('required_input')
            }
          ]}
          autoComplete="off"
        >
          <AppInput placeholder={t('name')}></AppInput>
        </Form.Item>

        <Form.Item
          className={styles.formItem}
          name="surname"
          rules={[
            {
              required: true,
              message: t('required_input')
            }
          ]}
        >
          <AppInput
            placeholder={t('surname')}
            readOnly
            onFocus={(e) => {
              e.target.removeAttribute('readonly');
            }}
            autoComplete="off"
          ></AppInput>
        </Form.Item>

        <Form.Item
          className={styles.formItem}
          name="password"
          rules={[
            {
              required: true,
              message: t('required_input')
            },
            () => ({
              validator(_, value) {
                const reg = /^(?=.*[!@#$%^&*(),`./_+={}[|:;"'<>?~\]\\-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                if (!value || reg.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('password_incorrect')));
              }
            })
          ]}
        >
          <AppInputPassword data-test="signup_page_password" placeholder={t('password')} autoComplete={'off'} />
        </Form.Item>
        <Form.Item name="agreed" valuePropName="checked">
          <Checkbox>
            {t('confirm_agreement')}{' '}
            <Link data-test="form_privacy_policy" to="/privacy-policy" target="_blank">
              {t('privacy_policy')}{' '}
            </Link>{' '}
            {t('and')}{' '}
            <Link data-test="form_terms_conditions" to="/terms-and-conditions" target="_blank">
              {t('terms_and_conditions')}{' '}
            </Link>
          </Checkbox>
        </Form.Item>
        <Button data-test="signup_page_signup" htmlType="submit" className="mg-btn" disabled={signupDisabledButton}>
          {t('sign_up_btn')}
        </Button>
      </Form>
      <p className={styles.formRulesText}>{t('password_rules_message')}</p>
    </div>
  );
};
