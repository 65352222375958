import React, { useCallback, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { SetPasswordForm } from '../set-password-form/setPasswordFrom';
import { Stepper } from '../stepper/index';
import { recoverPasswordAsync, signinAsync } from 'src/app/api/auth';
import { getProfileAsync } from 'src/app/api/profile';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { setAuthToken } from 'src/app/slices/authSlice';
import { setProfile } from 'src/app/slices/profileSlice';

import styles from './SetPasswordSteps.module.css';
import { SuccessInfo } from './succesInfo';

export const SetPasswordSteps = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useParams();
  const [current, setCurrent] = useState(1);
  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  const loadProfile = useCallback(() => {
    dispatch(getProfileAsync())
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch(() => {
        dispatch(setProfile(null));
      });
  }, [dispatch]);

  const handleSubmit = useCallback(
    (val) => {
      dispatch(recoverPasswordAsync(hash, email, val.newPassword))
        .then((data) => {
          dispatch(signinAsync(email, val.newPassword))
            .then((res) => {
              dispatch(setAuthToken(res.token));
              loadProfile();
              setCurrent((curr) => curr + 1);
            })
            .catch((e) => {
              appMessage(typeMessage.ERROR, e.message);
            });
        })
        .catch((e) => {
          navigate('/account/recover-password');
          appMessage(typeMessage.ERROR, e.message);
        });
    },
    [dispatch, email, hash, loadProfile, navigate]
  );

  useEffect(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    setEmail(params.get('to'));
  }, []);

  const StepsCount = [
    {
      component: <SetPasswordForm onSubmit={handleSubmit} />
    },
    {
      component: <SuccessInfo />
    }
  ];

  return (
    <div className="page">
      <h1 className={styles.title + ' ' + 'page__title-uppercase'}>{t('title_recovery_password')}</h1>
      <Stepper totalCount={3} current={current}></Stepper>
      {StepsCount[current - 1].component}
    </div>
  );
};
