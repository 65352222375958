export const intervalOptions = [
  {
    label: 'one_day_label',
    value: '1 day'
  },
  {
    label: 'one_month_label',
    value: '1 month'
  },
  {
    label: 'three_months_label',
    value: '3 months'
  },
  {
    label: 'six_months_label',
    value: '6 months'
  },
  {
    label: 'one_year_label',
    value: '1 year'
  }
];

export const currencyOptions = [
  {
    label: 'USD',
    value: 'usd'
  },
  {
    label: 'EUR',
    value: 'eur'
  }
];
