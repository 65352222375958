import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';

import { Table, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { AppTableCell } from './components/cell/index';
import { AppTableSort } from './components/sort/index';
import './styles.css';

/* @params
  columns = [
    {
      title: "Цена",
      key: 'cost',
      dataIndex: 'cost',
      sorter?: true,
      filtered?: true,
      sortTitle?: 'costValue',
      sortValue?: null,
      editableCellComponent?: ReactComponent,
      onChangeCell?: ReactComponent,
      coloredRowConditions?: [
      {
        condition: {
          field: 'blocked',
          value: true
        },
        color: '#AC447C'
      }
    ]
    }
  ]

  sort: {column: 'createdDate', direction: 'asc' | 'desc'}
*/
export const AppSimpleTable = ({
  columns,
  listOfData,
  sort,
  padding,
  onChange,
  pagination,
  onSelectRow,
  onSort,
  scroll,
  className,
  onChangePagination,
  noNeedScroll
}) => {
  const { i18n } = useTranslation();

  const [tableCols, setTableCols] = useState([]);
  const scrollbarsRef = useRef();
  const scrollbarValueRef = useRef(scrollbarsRef?.current?.scrollTop | 0);

  useLayoutEffect(() => {
    if (scrollbarValueRef?.current) {
      scrollbarsRef.current.scrollTop = scrollbarValueRef.current;
    }
  });

  const handleScroll = () => {
    scrollbarValueRef.current = scrollbarsRef.current.scrollTop;
  };

  const components = {
    body: {
      cell: AppTableCell
    },
    table: noNeedScroll
      ? (props) => (
          <table style={{ minWidth: scroll?.x, overflowX: scroll }} className={props.className}>
            {props.children}
          </table>
        )
      : (props) => (
          <PerfectScrollbar
            onScroll={handleScroll}
            containerRef={(el) => (scrollbarsRef.current = el)}
            options={{ minScrollbarLength: 150 }}
            className={'scroll-container'}
            style={{ maxHeight: scroll?.y }}
          >
            <table style={{ minWidth: scroll?.x, overflowX: scroll }} className={props.className}>
              {props.children}
            </table>
          </PerfectScrollbar>
        )
  };

  const sortTable = useCallback(
    (key, sortValue) => {
      onSort({ direction: sort.direction === 'ASC' ? 'DESC' : 'ASC', column: key, sortValue: sortValue });
    },
    [onSort, sort.direction]
  );

  const isSorted = useCallback(
    (col) => {
      return col.sorted && sort.column === (col.sortTitle || col.key) && sort.sortValue === col.sortValue;
    },
    [sort.column, sort.sortValue]
  );

  useEffect(() => {
    const cols = [];
    columns.forEach((col) => {
      cols.push({
        ...col,
        title: (
          <div className="mg-table-th">
            <span
              onClick={col.sorted ? () => sortTable(col.sortTitle || col.key, col.sortValue) : null}
              className={`mg-table-th__title ${isSorted(col) ? '_active' : ''}`}
            >
              {i18n.t(col.title)}
              {col.sorted ? <AppTableSort sort={sort} isActive={isSorted(col)} /> : null}
            </span>
          </div>
        ),
        filtered: false,
        sorter: false,
        onCell: (record) => ({
          record,
          title: i18n.t(col.title),
          chartData: col.chartData,
          dataIndex: col.dataIndex,
          EditableCellComponent: col.editableCellComponent,
          AdditionalEventsComponent: col.additionalEventsComponent,
          onChangeCell: col.onChangeCell,
          onPressEnterCell: col.onPressEnterCell,
          coloredRowConditions: col.coloredRowConditions,
          cellClassConditions: col.cellClassConditions,
          onBlurCell: col.onBlurCell,
          onFocusCell: col.onFocusCell,
          onClickCell: col.onClickCell,
          editingDefault: col.editingDefault,
          onChangeAdditionalEvent: col.onChangeAdditionalEvent,
          disableEditingConditions: col.disableEditingConditions
        })
      });
    });
    setTableCols(cols);
  }, [columns, isSorted, sort, sortTable, i18n]);

  return (
    <div className={`mg-table__wrap ${className}`}>
      <Table
        style={{ padding: padding }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (onSelectRow) {
                onSelectRow(record);
              }
            }
          };
        }}
        pagination={false}
        rowKey={(record) => {
          return record.id || Math.random().toString(16).slice(2);
        }}
        scroll={{ y: scroll?.y } || null}
        onChange={onChange}
        components={components}
        columns={tableCols}
        dataSource={listOfData}
        className="mg-table"
      />
      {pagination && pagination.total > pagination.limit ? (
        <div className="mg-table__pagination-wrap">
          <Pagination
            pageSize={pagination.limit}
            total={pagination.total}
            current={pagination.index}
            onChange={onChangePagination}
            hideOnSinglePage={true}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
