import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import { isNumber } from 'chart.js/helpers';
import classNames from 'classnames';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';

import { htmlLegendPlugin } from 'src/app/components/chart/helpers/legend';
import { defaultOptions } from 'src/app/components/chart/helpers/options';
import { getValueScale } from 'src/app/components/chart/helpers/prepareNums';
import {
  selectGraphicsData,
  selectMaxYTicksWidth,
  setMaxYTicksWidth,
  setInteractions
} from 'src/app/slices/financialModelSlice';

import 'chartjs-plugin-dragdata';
import { PointInput } from './components/pointInput';
import { backgroundPlugin } from './helpers/background';
import { getGraphicGradient } from './helpers/gradient';
import styles from './ModelChartTest.module.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export const ModelChart = ({ start, end, diagram, legendWidth, isColumnWrapper }) => {
  const dispatch = useDispatch();

  const graphicsData = useSelector(selectGraphicsData);
  const maxYTicksWidth = useSelector(selectMaxYTicksWidth);

  const chartRef = useRef();
  const id = useMemo(() => (Math.random() + 1).toString(36).substring(7), []);

  const [labels, setLabels] = useState([]);
  const [legendColors, setLegendColors] = useState([]);
  const [preparedGraphicsData, setPreparedGraphicsData] = useState({});
  const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
  const [contextMenuPoint, setContextMenuPoint] = useState(null);

  const isUpdated = useRef();
  const stateRef = useRef();
  stateRef.current = preparedGraphicsData;

  const getGraphicData = useCallback(
    (graphic, preparedLabels) => {
      return preparedLabels.map((year) => {
        if (!graphicsData[graphic.gr_param_name][year]) {
          return {
            x: year,
            y: null
          };
        } else {
          const point = graphicsData[graphic.gr_param_name][year];
          const pointValue = graphic.gr_data_type === 'percent' ? point[0] * 100 : point[0];
          return {
            x: year,
            y: pointValue,
            yBeforeDragging: pointValue,
            initialValue: point[0],
            valueType: graphic.gr_data_type,
            ratio: null,
            valueExtraLabel: graphic.gr_data_type === 'percent' ? '%' : '',
            isDraggable: point[1],
            color: `#${point[2]}`,
            graphicName: graphic.gr_param_name,
            graphicTitle: graphic.gr_legend.length === 0 ? undefined : graphic.gr_legend,
            maxValue: (graphic.gr_up_limit_param_name && graphicsData[graphic.gr_up_limit_param_name][year]) || null,
            minValue: (graphic.gr_down_limit_param_name && graphicsData[graphic.gr_down_limit_param_name][year]) || null
          };
        }
      });
    },
    [graphicsData]
  );

  useEffect(() => {
    let minValue = null;

    const preparedLabels = [];
    for (let i = start; i <= end; i++) {
      preparedLabels.push(i.toString());
    }
    setLabels(preparedLabels);

    const preparedData = diagram.dg_graphs.reduce((obj, graphic) => {
      const data = getGraphicData(graphic, preparedLabels);
      obj[graphic.gr_param_name] = data;
      if (graphic.gr_data_type === 'currency') {
        const min = Math.min(...data.filter((point) => isNumber(point.y) && point.y !== 0).map((point) => point.y));
        minValue = min < minValue || !minValue ? min : minValue;
      }
      return obj;
    }, {});

    if (minValue !== null) {
      const item = getValueScale([minValue]);
      Object.entries(preparedData).forEach(
        ([graphic, points]) =>
          (preparedData[graphic] = points.map((point) =>
            isNumber(point.y) && point.valueType === 'currency'
              ? { ...point, y: point.y / item.value, ratio: item.value, valueExtraLabel: item.symbol }
              : point
          ))
      );
    }

    setPreparedGraphicsData(preparedData);
    setLegendColors(Object.values(preparedData).map((points) => points.filter((p) => p.y !== null)[0]?.color));
    isUpdated.current = true;
  }, [diagram.dg_graphs, end, getGraphicData, start]);

  const sendNewPointValue = useCallback(
    (graphicName, year, value) => {
      dispatch(
        setInteractions({
          param: {
            name: graphicName,
            year,
            value
          }
        })
      );
    },
    [dispatch]
  );

  const handleInputPointValue = useCallback(
    (point, value, datasetIndex) => {
      const yValue = point.valueType === 'percent' ? value : value / point.ratio;
      const interactValue = point.valueType === 'percent' ? value / 100 : value;

      setPreparedGraphicsData((prev) => {
        const newGraphData = [...Object.values(prev)[datasetIndex]];
        const index = newGraphData.findIndex((p) => p.x === point.x);
        newGraphData[index] = { ...newGraphData[index], y: yValue, initialValue: interactValue };
        return { ...prev, [point.graphicName]: newGraphData };
      });

      sendNewPointValue(point.graphicName, point.x, interactValue);
    },
    [sendNewPointValue]
  );

  const data = useMemo(
    () => ({
      labels,
      datasets: !Object.keys(preparedGraphicsData).length
        ? []
        : diagram.dg_graphs.map((graphic, datasetIndex) => {
            const getGradient = getGraphicGradient(datasetIndex);

            return {
              type: graphic.gr_style === 'simple' ? 'line' : 'scatter',
              label: graphic.gr_legend,
              data: structuredClone(preparedGraphicsData[graphic.gr_param_name]),
              pointHitRadius: 25,
              pointRadius: (context) => {
                if (
                  contextMenuPoint &&
                  contextMenuPoint.datasetIndex === datasetIndex &&
                  contextMenuPoint.point.x === context.raw?.x
                ) {
                  return 6;
                }

                if (diagram.dg_type === 2) {
                  return 0;
                } else if (graphic.gr_style === 'simple') {
                  return 3;
                } else {
                  return 20;
                }
              },
              pointStyle: graphic.gr_style === 'simple' ? 'circle' : 'line',
              pointBorderWidth: graphic.gr_style === 'simple' ? 1 : 3,
              pointHoverRadius: (context) => {
                if (
                  contextMenuPoint &&
                  contextMenuPoint.datasetIndex === datasetIndex &&
                  contextMenuPoint.point.x === context.raw?.x
                ) {
                  return 6;
                }

                if (diagram.dg_type === 2) {
                  return 0;
                } else if (context.raw?.isDraggable) {
                  return 5;
                } else {
                  return 3;
                }
              },
              pointBorderColor: (context) => {
                return context.raw?.color;
              },
              pointBackgroundColor: (context) => {
                return context.raw?.isDraggable ? 'white' : context.raw?.color;
              },
              borderColor: (context) => getGradient(context),
              backgroundColor: (context) =>
                diagram.dg_type === 2 && datasetIndex === 4 ? '#E78131' : getGradient(context),
              fill:
                diagram.dg_type === 2 &&
                datasetIndex !== diagram.dg_graphs.length - 1 &&
                datasetIndex !== diagram.dg_graphs.length - 2,
              stack:
                datasetIndex !== diagram.dg_graphs.length - 1 && datasetIndex !== diagram.dg_graphs.length - 2
                  ? 'stack1'
                  : 'stack2',
              ...(diagram.dg_type === 2
                ? {
                    order:
                      datasetIndex !== diagram.dg_graphs.length - 1 && datasetIndex !== diagram.dg_graphs.length - 2
                        ? 1
                        : 0
                  }
                : graphic.gr_is_alterable && { order: -1 })
            };
          })
    }),
    [contextMenuPoint, diagram.dg_graphs, diagram.dg_type, labels, preparedGraphicsData]
  );

  const options = useMemo(
    () => ({
      events: ['mousemove', 'mouseout', 'click', 'contextmenu'],
      onClick: (event, el, chart) => {
        if (event.type !== 'contextmenu') {
          return;
        }

        const points = chart.getElementsAtEventForMode(
          event,
          'point',
          {
            intersect: true
          },
          true
        );

        for (let i = 0; i < points.length; i++) {
          const { datasetIndex, element, index } = chart.getElementsAtEventForMode(
            event,
            'point',
            {
              intersect: true
            },
            true
          )[i];

          const point = chartRef.current.data.datasets[datasetIndex].data[index];
          if (point.isDraggable) {
            setContextMenuPoint({
              point,
              datasetIndex,
              positionTop: element.y,
              positionLeft: element.x,
              screenXPosition: event.native.x
            });
            setIsContextMenuOpened(true);
            return;
          }
        }
      },
      plugins: {
        customCanvasBackgroundColor: {
          backgroundColors: diagram.dg_backgroung_color
        },
        dragData: {
          dragDataRound: 5,
          showTooltip: true,
          onDragStart: (e, datasetIndex, index, value) => {
            if (!value.isDraggable) {
              return false;
            }
          },
          onDrag: (e, datasetIndex, index, value) => {
            e.target.style.cursor = 'grabbing';

            let isLimit = false;
            const maxValue = value.maxValue && value.maxValue[0] / (value.ratio ? value.ratio : 1);
            const minValue = value.minValue && value.minValue[0] / (value.ratio ? value.ratio : 1);
            const newValueY = value.y;

            if (maxValue !== null && newValueY > maxValue) {
              setPreparedGraphicsData((prev) => {
                const newGraphData = [...Object.values(prev)[datasetIndex]];
                newGraphData[index] = { ...value, y: maxValue };
                return { ...prev, [value.graphicName]: newGraphData };
              });
              isLimit = true;
            } else if (minValue !== null && newValueY < minValue) {
              setPreparedGraphicsData((prev) => {
                const newGraphData = [...Object.values(prev)[datasetIndex]];
                newGraphData[index] = { ...value, y: minValue };
                return { ...prev, [value.graphicName]: newGraphData };
              });
              isLimit = true;
            }

            setTimeout(() => {
              chartRef.current?.tooltip.setActiveElements(
                chartRef.current?.tooltip.dataPoints.map((dataset) => ({ datasetIndex: dataset.datasetIndex, index }))
              );
              chartRef.current?.update();
            }, 0);

            if (isLimit) {
              return false;
            }
          },
          onDragEnd: (e, datasetIndex, index, value) => {
            e.target.style.cursor = 'default';

            if (value.y === stateRef.current[value.graphicName][index].yBeforeDragging) {
              return;
            }

            const interactValue = value.valueType === 'percent' ? value.y / 100 : value.y * value.ratio;
            // setPreparedGraphicsData((prev) => {
            //   const newGraphData = [...Object.values(prev)[datasetIndex]];
            //   newGraphData[index] = { ...value, yBeforeDragging: value.y, initialValue: interactValue };
            //   return { ...prev, [value.graphicName]: newGraphData };
            // });

            sendNewPointValue(value.graphicName, value.x, interactValue);
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 100
      },
      interaction: {
        mode: 'index',
        intersect: false
      },
      stacked: false,
      layout: {
        padding: {
          top: 10
        }
      },
      scales: {
        x: {
          grid: {
            color: 'transparent',
            borderColor: '#A6B8C7',
            tickColor: '#A6B8C7',
            tickLength: 8
          },
          ticks: {
            color: [...new Array(labels.length)].map(() => '#9CAEBD'),
            font: {
              size: 10,
              lineHeight: '24px'
            }
          }
        },
        y: {
          afterFit: (scale) => {
            if (scale.width > maxYTicksWidth) {
              dispatch(setMaxYTicksWidth(scale.width));
            } else if (maxYTicksWidth) {
              scale.width = maxYTicksWidth;
            }
          },
          afterDataLimits: (scale) => {
            scale.max += 10;
            scale.min -= 10;
          },
          stacked: diagram.dg_type === 2,
          grid: {
            color: 'transparent',
            tickColor: '#A6B8C7',
            tickLength: 8,
            borderColor: '#A6B8C7'
          },
          ticks: {
            color: '#9CAEBD',
            font: {
              size: 14,
              lineHeight: '24px'
            }
          }
        }
      }
    }),
    [diagram.dg_backgroung_color, diagram.dg_type, dispatch, labels.length, maxYTicksWidth, sendNewPointValue]
  );

  const configuredDefaultOptions = useMemo(
    () => defaultOptions(labels.length, id, false, false, true, null, null, null, legendColors),
    [id, labels.length, legendColors]
  );

  return (
    id &&
    data &&
    labels.length !== 0 && (
      <div
        className={classNames(styles.diagramWrapper, {
          [styles.columnWrapper]: isColumnWrapper,
          columnDiagramWrapper: isColumnWrapper
        })}
        style={{ height: diagram.dg_height }}
      >
        <div className={styles.legendWrapper}>
          <h3 className={styles.title}>{diagram.dg_legend}</h3>
          <div id={'legend-container-' + id} className={`${styles.legendWrap}`}></div>
        </div>
        <div style={{ width: `${100 - legendWidth}%` }} className={`${styles.mgChartWrap}`}>
          <div
            className={styles.lineWrapper}
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          >
            <Line
              ref={chartRef}
              options={{
                ...configuredDefaultOptions,
                ...options,
                plugins: {
                  ...configuredDefaultOptions.plugins,
                  ...options.plugins
                }
              }}
              data={{ labels: labels, datasets: data.datasets }}
              plugins={[
                htmlLegendPlugin,
                backgroundPlugin,
                {
                  afterRender: (chart, args, options) => {
                    if (isUpdated.current) {
                      chartRef.current?.update();
                      isUpdated.current = false;
                    }
                  }
                }
              ]}
            />
          </div>
          <span className={styles.xAxisLine} id={'x-axis-line-' + id}></span>
          <span className={styles.tooltip} id={'tooltip-' + id}>
            <table className={styles.tooltipTable}></table>
          </span>
          <PointInput
            visible={isContextMenuOpened}
            onClose={() => {
              setIsContextMenuOpened(false);
              setContextMenuPoint(null);
            }}
            onOk={handleInputPointValue}
            pointInfo={contextMenuPoint}
          />
        </div>
      </div>
    )
  );
};
