export const columns = [
  {
    title: 'index_label',
    key: 'index',
    dataIndex: 'index',
    sorter: false,
    filtered: false
  },
  {
    title: 'to_label',
    key: 'to',
    dataIndex: 'to',
    sorter: false,
    filtered: false,
    width: '40%'
  },
  {
    title: 'date_label',
    key: 'date',
    dataIndex: 'date',
    sorter: false,
    filtered: false,
    width: '40%'
  },
  {
    title: 'status_label',
    key: 'status',
    dataIndex: 'status',
    sorter: false,
    filtered: false
  }
];
