import React, { useEffect, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import './style.css';
import { oauthSocialNetworkAsync } from 'src/app/api/auth';
import { getProfileAsync } from 'src/app/api/profile';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { setAuthToken } from 'src/app/slices/authSlice';
import { setProfile } from 'src/app/slices/profileSlice';

export const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathParams = useParams();

  const loadProfile = useCallback(() => {
    dispatch(getProfileAsync())
      .then((data) => {
        dispatch(setProfile(data));
        navigate('/');
      })
      .catch(() => {
        dispatch(setProfile(null));
      });
  }, [dispatch, navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    if (code) {
      dispatch(oauthSocialNetworkAsync(code, pathParams.type))
        .then((res) => {
          dispatch(setAuthToken(res.token));
          loadProfile();
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
          navigate('/');
        });
    }
  }, [dispatch, navigate, loadProfile, pathParams.type]);

  return <div className="page auth"></div>;
};
