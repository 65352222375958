import React, { useState, useCallback } from 'react';

import styles from '../../Polls.module.css';
import AppInput from 'src/app/components/form/input';
import { ReactComponent as TrashIcon } from 'src/assets/svg/custom-screen/trash.svg';

export const Question = ({ question, setQuestion, handleDeleteQuestion }) => {
  const [newAnswerValue, setNewAnswerValue] = useState('');

  const addNewAnswer = useCallback(
    (value) => {
      if (value) {
        setQuestion({ ...question, answers: [...question.answers, value] });
        setNewAnswerValue('');
      }
    },
    [question, setQuestion]
  );

  const updatePollQuestion = (value) => {
    setQuestion({ ...question, title: value });
  };

  const updatePollAnswers = useCallback(
    (index) => (answer) => {
      if (answer.length === 0) {
        const newAnswers = [...question.answers];
        newAnswers.splice(index, 1);
        setQuestion({
          ...question,
          answers: newAnswers
        });
      } else {
        const newAnswers = [...question.answers];
        newAnswers.splice(index, 1, answer);
        setQuestion({
          ...question,
          answers: newAnswers
        });
      }
    },
    [question, setQuestion]
  );

  return (
    <div className={`${styles.poll} poll`} key={question.id}>
      <div className={styles.sectionsWrapper}>
        <div className={styles.section}>
          <span className={styles.title}>Question</span>
          <AppInput value={question.title} onChange={updatePollQuestion} maxLength={1023} />
        </div>
        <div className={styles.section}>
          <span className={styles.title}>Answers</span>
          <div className={styles.answersList}>
            {question?.answers?.map((answer, index) => (
              <AppInput
                value={answer}
                key={`${question.id}_${index}`}
                onBlur={updatePollAnswers(index)}
                onChange={updatePollAnswers(index)}
              />
            ))}
            <AppInput
              value={newAnswerValue}
              onChange={setNewAnswerValue}
              placeholder="Add answer"
              onBlur={addNewAnswer}
              onPressEnter={addNewAnswer}
            />
          </div>
        </div>
      </div>
      <TrashIcon className={styles.deleteBtn} onClick={() => handleDeleteQuestion(question.id)} />
    </div>
  );
};
