import React from 'react';

import { Layout } from 'antd';

import AppFooter from '../../components/footer';
import { AppHeader } from 'src/app/components/header';

import styles from './PublicLayout.module.css';

const { Content } = Layout;

export const AppPublicLayout = ({ children }) => {
  return (
    <Layout className={styles.PublicLayout}>
      <AppHeader />
      <Content className={styles.content}>
        <div>{children}</div>
      </Content>
      <AppFooter className={styles.footer}></AppFooter>
    </Layout>
  );
};
