import React from 'react';

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

export const DeleteModal = ({ visible, onDelete, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('modal_title_warning')}
      centered
      visible={visible}
      forceRender
      onOk={onDelete}
      onCancel={onCancel}
      okText={t('delete')}
      cancelText={t('cancel')}
      className="mg-modal _confirm"
      okButtonProps={{ className: 'mg-btn _green-border', 'data-test': 'details_portfolio_page_modal_delete' }}
      cancelButtonProps={{ className: 'mg-btn', 'data-test': 'details_portfolio_page_modal_cancel' }}
    >
      <p>{t('modal_delete_company_from_idea')}</p>
    </Modal>
  );
};
