import React from 'react';

import { useSelector } from 'react-redux';

import './App.css';
import { AppSpinnerFull } from './app/components/spinner/spinner-full';
import ProfileLoader from './app/modules/auth/profileLoader';
import { AppRoutes } from './app/routes/index';
import { selectSpinnerVisible } from './app/slices/commonSlice';

export const App = ({ history }) => {
  const isSpinnerVisible = useSelector(selectSpinnerVisible);
  // useEffect(() => {
  //   if (isSpinnerVisible) {
  //     document.body.style.overflow = 'auto';
  //   }
  //   if (!isSpinnerVisible) {
  //     document.body.style.overflow = 'auto';
  //   }
  // }, [isSpinnerVisible]);

  return (
    <div className="App">
      <ProfileLoader />
      {isSpinnerVisible ? <AppSpinnerFull></AppSpinnerFull> : ''}
      <AppRoutes history={history} />
    </div>
  );
};
