import React from 'react';

import { Modal, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import discountRate from 'src/assets/video/discountRate.mp4';
import enterValue from 'src/assets/video/enterValue.mp4';
import modelBuilder from 'src/assets/video/modelBuilder.mp4';
import movingCircle from 'src/assets/video/movingCircle.mp4';
import reloadModel from 'src/assets/video/reloadModel.mp4';
import restoreAvg from 'src/assets/video/restoreAvg.mp4';
import saveModel from 'src/assets/video/saveModel.mp4';
import slider from 'src/assets/video/slider.mp4';
import timeHorizon from 'src/assets/video/timeHorizon.mp4';

import styles from './TutorialModal.module.css';
import './style.css';

export const TutorialModal = ({ visible, onCancel }) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  return (
    <Modal
      title={'Tutorial'}
      width={1400}
      centered
      visible={visible}
      forceRender
      onOk={onCancel}
      onCancel={onCancel}
      okText={t('ok')}
      cancelText={t('cancel')}
      className="mg-modal _confirm"
      cancelButtonProps={{ className: 'mg-btn _green-border' }}
      okButtonProps={{ className: 'mg-btn' }}
    >
      <div className={`${styles.tabsWrap} tabs-tutorial-model-modal`}>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t('calculate_fair_model_label')} key="1">
            <div className={styles.inline}>
              <div className={styles.element}>
                <div className={styles.inline}>
                  <div>
                    <video
                      onMouseOver={(event) => event.target.play()}
                      onMouseOut={(event) => event.target.pause()((event.target.currentTime = 0))}
                      loop
                      src={discountRate}
                      style={{ width: '430px', height: '260px' }}
                    />
                  </div>
                  <div>
                    <p className={styles.tutorialText}>
                      The fair price of a share is calculated as the sum of: <br></br>
                      1. The share price at the end of the planning period, discounted at a given discount rate
                      <br></br>
                      2. Dividends for the planning period, also discounted at a given discount rate <br></br>
                      <br></br>
                      Please fill in the discount rate box with the preferred discount rate<br></br>
                      <br></br>
                      Expected return if bought at the current price is calculated as the ratio of the projected stock
                      price at the end of the period to the last available market price. The calculation is made under
                      the assumption of fully reinvested dividends.<br></br>
                      <br></br>
                      An important part of the calculation in both cases could be the valuation of the share price at
                      the end of the period. Depending on the capital intensity and profitability of the company, the
                      following indicators can be used:
                    </p>
                  </div>
                </div>
                <div className={styles.inline}>
                  <div>
                    <video
                      onMouseOver={(event) => event.target.play()}
                      onMouseOut={(event) => event.target.pause()((event.target.currentTime = 0))}
                      loop
                      src={slider}
                      style={{ width: '430px', marginTop: '20px', height: '260px' }}
                    />
                  </div>
                  <div>
                    <p className={styles.tutorialText} style={{ marginTop: '130px' }}>
                      Select the slider position OR fill with the percentages the one of the text boxes below the slider
                    </p>
                  </div>
                </div>
                <div className={styles.inline}>
                  <div>
                    <video
                      onMouseOver={(event) => event.target.play()}
                      onMouseOut={(event) => event.target.pause()((event.target.currentTime = 0))}
                      loop
                      src={restoreAvg}
                      style={{ width: '430px', marginTop: '20px', height: '260px' }}
                    />
                  </div>
                  <div>
                    <p className={styles.tutorialText} style={{ marginTop: '130px' }}>
                      To use the average of the last 4 years for Price-to-Earnings and Price-to-Book ratios, click
                      “reset to last 4-years average”.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab={t('create_model_label')} key="2">
            <div className={styles.inline}>
              <div className={styles.element}>
                <div className={styles.inline}>
                  <div>
                    <video
                      onMouseOver={(event) => event.target.play()}
                      onMouseOut={(event) => event.target.pause()((event.target.currentTime = 0))}
                      loop
                      src={timeHorizon}
                      // type="video/mp4"
                      style={{ width: '330px', height: '180px' }}
                    />
                  </div>
                  <div>
                    <p className={styles.tutorialText}>
                      The planning horizon may differ depending on the goals of the portfolio being created. You can
                      select the planning horizon by clicking the manipulator on Model Time Horizon
                    </p>
                  </div>
                </div>
                <div className={styles.inline}>
                  <div>
                    {visible && (
                      <video
                        onMouseOver={(event) => event.target.play()}
                        onMouseOut={(event) => event.target.pause()((event.target.currentTime = 0))}
                        loop
                        src={movingCircle}
                        // type="video/mp4"
                        style={{ width: '330px', height: '180px' }}
                      />
                    )}
                  </div>
                  <div>
                    <p className={styles.tutorialText}>
                      The first version of the model allows changes to the Revenue Growth, Net Income Margin, Return on
                      Assets, Dividends and BuyBacks indicators. The remaining indicators are calculated automatically.
                      <br></br>
                      To change a value, move the mouse pointer over the circle on the chart and, by pressing the left
                      button, move it to the desired value.
                      <br></br>
                      <p className={styles.tutorialTextCentered}>OR</p>
                    </p>
                  </div>
                </div>
                <div className={styles.inline}>
                  <div>
                    {visible && (
                      <video
                        onMouseOver={(event) => event.target.play()}
                        onMouseOut={(event) => event.target.pause()((event.target.currentTime = 0))}
                        loop
                        src={enterValue}
                        // type="video/mp4"
                        style={{ width: '330px', height: '180px' }}
                      />
                    )}
                  </div>
                  <div>
                    <p className={styles.tutorialText}>
                      Right-click and enter the exact value in the input box that appears.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.element}>
                <div className={styles.inline}>
                  <div>
                    {visible && (
                      <video
                        onMouseOver={(event) => event.target.play()}
                        onMouseOut={(event) => event.target.pause()((event.target.currentTime = 0))}
                        loop
                        src={modelBuilder}
                        // type="video/mp4"
                        style={{ width: '330px', height: '180px' }}
                      />
                    )}
                  </div>
                  <p className={styles.tutorialText}>
                    The simplify the process, we have created several planning algorithms that extrapolate past
                    indicators into the future. The forecast constructed is not a recommendation. We strongly recommend
                    adjusting the resulting model based on your understanding of the future.
                  </p>
                </div>
                <p className={styles.tutorialText}>
                  “Moving to target ratios”: The algorithm is useful in situations where the company&apos;s performance
                  has deviated from normal due to unforeseen events. Although profitability may sometimes be restored in
                  the short term, normalizing the balance sheet may take longer. It is possible to set a target capital
                  structure (target debt-to-assets ratio) and specify the period over which this target will be
                  achieved. The algorithm will then develop a scenario to achieve the required balance sheet structure.
                  “Keeping last 4 years trends”. The algorithm extrapolates the company&apos;s performance indicators
                  based on average results over the past 4 years.
                </p>
                <p className={styles.tutorialText}>
                  “Buyback-to-Book ratios”: In addition to profitability indicators, return on investment is influenced
                  by capital allocation factors, particularly the share buyback price. Please enter the estimated
                  buyback price in the buyback-to-book box, or use the historical average.
                </p>
                <p className={styles.tutorialText}>
                  The algorithm extrapolates the company&apos;s performance indicators based on average results over the
                  past 4 years. To use click “reset to last 4-years average”.
                </p>
              </div>
            </div>
          </TabPane>
          <TabPane tab={t('load_save_model_label')} key="3">
            <div className={styles.inline}>
              <div className={styles.element}>
                <div className={styles.inline}>
                  <div>
                    <video
                      onMouseOver={(event) => event.target.play()}
                      onMouseOut={(event) => event.target.pause()((event.target.currentTime = 0))}
                      loop
                      src={saveModel}
                      // type="video/mp4"
                      style={{ width: '330px', height: '180px' }}
                    />
                  </div>
                  <div>
                    <p className={styles.tutorialText}>
                      Press “SAVE MODEL” if you want to save the model for the future use. ATTENTION! Previously saved
                      values will be overwritten and cannot be restored later.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.element}>
                <div className={styles.inline}>
                  <div>
                    <video
                      onMouseOver={(event) => event.target.play()}
                      onMouseOut={(event) => event.target.pause()((event.target.currentTime = 0))}
                      loop
                      src={reloadModel}
                      // type="video/mp4"
                      style={{ width: '330px', height: '180px' }}
                    />
                  </div>
                  <div>
                    <p className={styles.tutorialText}>
                      Press “RELOAD MODEL” if you want to return to the previously saved model. ATTENTION! When you
                      return to a saved model, all changes made in the current session will be discarded.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Modal>
  );
};
