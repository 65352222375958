import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { RowMenuPrice } from '../../../table-row-menu-price/rowMenu';
import { loadAlertsListByViewAsync, updateAlertAsync, createAlertAsync, deleteAlertAsync } from 'src/app/api/alerts';
import { loadCompanyTickersAsync } from 'src/app/api/company';
import { AppInputNumber } from 'src/app/components/form/input-price';
import { AppSimpleTable } from 'src/app/components/table';
import { PriceCompanyAlertModel } from 'src/app/models/alerts';
import { priceColumnsModal, alertView } from 'src/app/modules/account-alerts/consts';
import { selectPriceAlertsTotal } from 'src/app/slices/alertsSlice';

export const SetPriceForm = ({ onUpdatePriceAlertsList, companyCik, handleError }) => {
  const [sort, setSort] = useState({ column: 'companyName', direction: 'ASC' });

  const [tableData, setTableData] = useState([]);
  const tableDataRef = useRef();
  tableDataRef.current = tableData;
  const [accountPriceAlerts, setAccountPriceAlerts] = useState([]);
  const [companyTickersByCik, setCompanyTickersByCik] = useState([]);

  const dispatch = useDispatch();

  const [priceColumns, setPriceColumns] = useState(priceColumnsModal);

  const accountPriceAlertsTotal = useSelector(selectPriceAlertsTotal);

  useEffect(() => {
    if (companyCik) {
      dispatch(loadCompanyTickersAsync(companyCik))
        .then((data) => {
          setCompanyTickersByCik(data);
        })
        .catch((e) => {
          handleError(e);
        });
    }
  }, [companyCik, dispatch, handleError]);

  useEffect(() => {
    dispatch(
      loadAlertsListByViewAsync(alertView.price, 0, accountPriceAlertsTotal, {
        column: 'companyName',
        direction: 'ASC'
      })
    )
      .then((data) => {
        const res = [...data.items.map((elem) => new PriceCompanyAlertModel(elem))];
        setAccountPriceAlerts(res);
      })
      .catch((e) => {
        handleError(e);
      });
  }, [accountPriceAlertsTotal, dispatch, handleError]);

  const createPreparedData = useCallback(() => {
    const preparedData = companyTickersByCik.map((el) => {
      const newEl = {
        id: el.ticker_id,
        tickerName: el.ticker,
        tickerId: el.ticker_id,
        rangeStart: null,
        rangeEnd: null
      };
      const accountAlert = accountPriceAlerts.find((accAlert) => accAlert.tickerId === el.ticker_id);
      if (accountAlert) {
        newEl.rangeStart = accountAlert.rangeStart;
        newEl.rangeEnd = accountAlert.rangeEnd;
        newEl.alertId = accountAlert.alertId;
      }
      return newEl;
    });
    return preparedData;
  }, [accountPriceAlerts, companyTickersByCik]);

  useEffect(() => {
    setTableData(createPreparedData());
  }, [createPreparedData]);

  const addAlert = useCallback(
    (updatedAlert) => {
      if (updatedAlert.rangeEnd === 0) {
        updatedAlert.rangeEnd = null;
      } else if (updatedAlert.rangeStart === 0) {
        updatedAlert.rangeStart = null;
      }
      const alertBySchema = {
        tickerId: updatedAlert.tickerId,
        rangeEnd: updatedAlert.rangeEnd,
        rangeStart: updatedAlert.rangeStart
      };
      dispatch(createAlertAsync(alertView.price, alertBySchema))
        .then((newAlert) => {
          setTableData((prev) => {
            const newState = structuredClone(prev);
            newState.forEach((e) => {
              if (e.tickerId === newAlert.data.tickerId) {
                e.alertId = newAlert.id;
                e.rangeEnd = newAlert.data.rangeEnd;
                e.rangeStart = newAlert.data.rangeStart;
              }
            });
            return newState;
          });
          onUpdatePriceAlertsList('add');
        })
        .catch((e) => {
          handleError(e);
        });
    },
    [dispatch, onUpdatePriceAlertsList, handleError]
  );

  const sendDeleteRequest = useCallback(
    (id) => {
      dispatch(deleteAlertAsync(id))
        .then(() => {
          onUpdatePriceAlertsList('delete');
          setTableData((prev) => {
            const newState = structuredClone(prev);
            newState.forEach((e) => {
              if (e.alertId === id) {
                e.alertId = null;
                e.rangeEnd = null;
                e.rangeStart = null;
              }
            });
            return newState;
          });
        })
        .catch((e) => {
          handleError(e);
        });
    },
    [dispatch, onUpdatePriceAlertsList, handleError]
  );

  const updateAlert = useCallback(
    (alertId, updatedAlert) => {
      const company = tableData.find((el) => el.id === alertId);
      if (
        (updatedAlert.rangeStart === 0 && updatedAlert.rangeEnd === null) ||
        (updatedAlert.rangeEnd === 0 && updatedAlert.rangeStart === null) ||
        (updatedAlert.rangeEnd === null && updatedAlert.rangeStart === null)
      ) {
        sendDeleteRequest(company.alertId);
      } else {
        if (updatedAlert.rangeEnd === 0) {
          updatedAlert.rangeEnd = null;
        } else if (updatedAlert.rangeStart === 0) {
          updatedAlert.rangeStart = null;
        }
        const alertBySchema = {
          rangeStart: updatedAlert.rangeStart,
          rangeEnd: updatedAlert.rangeEnd
        };
        dispatch(updateAlertAsync(company.alertId, { ...alertBySchema, tickerId: company?.tickerId }))
          .then((newAlert) => {
            setTableData((prev) => {
              const newState = structuredClone(prev);
              newState.forEach((e) => {
                if (e.tickerId === newAlert.data.tickerId) {
                  e.rangeEnd = newAlert.data.rangeEnd;
                  e.rangeStart = newAlert.data.rangeStart;
                }
              });
              return newState;
            });
          })
          .catch((e) => {
            handleError(e);
          });
      }
    },
    [dispatch, tableData, sendDeleteRequest, handleError]
  );

  const handleChangePrice = useCallback(
    (newValue, dataIndex, tickerId) => {
      const newValueIsNaN = isNaN(parseFloat(newValue));
      tableDataRef.current.forEach((elem) => {
        if (elem.id === tickerId) {
          const updatedAlert = {
            ...elem,
            [dataIndex]: newValueIsNaN ? null : parseFloat(newValue)
          };
          if (elem.rangeEnd === null && elem.rangeStart === null && newValue !== '0' && !newValueIsNaN) {
            addAlert(updatedAlert);
          } else if (elem.rangeEnd !== null || elem.rangeStart !== null) {
            updateAlert(tickerId, updatedAlert);
          }
        }
      });
    },
    [updateAlert, addAlert]
  );

  useEffect(() => {
    setPriceColumns((prev) =>
      prev.map((col, i) => {
        if (col.key === 'rangeStart' || col.key === 'rangeEnd') {
          return {
            ...col,
            editableCellComponent: AppInputNumber,
            onBlurCell: handleChangePrice,
            onPressEnterCell: handleChangePrice
          };
        }
        if (i === prev.length - 1) {
          return { ...col, additionalEventsComponent: RowMenuPrice };
        }
        return col;
      })
    );
  }, [handleChangePrice]);

  const handleSort = useCallback((sort) => {
    setSort(sort);
  }, []);

  return (
    <>
      <AppSimpleTable
        sort={sort}
        listOfData={tableData}
        onSort={handleSort}
        columns={priceColumns}
        noNeedScroll={true}
      />
    </>
  );
};
