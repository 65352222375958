import React from 'react';

import { useTranslation } from 'react-i18next';

import expiredIcon from 'src/assets/svg/status-invitations/expired.svg';
import receivedIcon from 'src/assets/svg/status-invitations/received.svg';
import registeredIcon from 'src/assets/svg/status-invitations/registered.svg';

import styles from './InvitationStatus.module.css';

export const InvitationStatus = ({ activatedAt, expiredAt }) => {
  const { t } = useTranslation();
  const dateNow = new Date();
  const dateExpired = expiredAt && new Date(expiredAt);
  const linkIsActive = expiredAt ? dateExpired.getTime() > dateNow.getTime() : false;

  const getIcon = () => {
    if (activatedAt) {
      return registeredIcon;
    } else if (linkIsActive) {
      return receivedIcon;
    } else {
      return expiredIcon;
    }
  };

  const getText = () => {
    if (activatedAt) {
      return t('invitation_user_registered');
    } else if (linkIsActive) {
      return t('invitation_received');
    } else {
      return t('invitation_expired');
    }
  };

  return (
    <div className={styles.wrap}>
      <img src={getIcon()} />
      <span>{getText()}</span>
    </div>
  );
};
