import React, { useEffect, useState, useCallback } from 'react';

import { Form } from 'antd';

import AppInput from 'src/app/components/form/input';
import { useDebouse } from 'src/app/hooks/debounse';

import styles from './SearchColumnModal.module.css';

export const escapeString = (currentString) => {
  return currentString.replace('#', '%23').replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const SearchForm = ({ value, onSearch, onChange }) => {
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebouse(searchTerm, 500);

  const searchColumnInitialValues = {
    name: ''
  };

  useEffect(() => {
    form.setFields([{ name: 'name', value: value }]);
  }, [form, value]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      const value = form.getFieldsValue();
      if (value.name && value.name.length >= 3) {
        onSearch(escapeString(value.name));
      }
    }
  }, [debouncedSearchTerm, form, onSearch]);

  const handleChange = () => {
    const value = form.getFieldsValue();
    onChange(value.name);
    setSearchTerm(value.name);
  };

  const handleOnPressEnter = useCallback(
    (value) => {
      if (!value || value.length >= 3) {
        onSearch(escapeString(value));
      }
    },
    [onSearch]
  );

  return (
    <div className={styles.formWrap}>
      <Form
        name="searchColumn"
        form={form}
        initialValues={searchColumnInitialValues}
        // onFinish={onSubmit}
        autoComplete="off"
        onFieldsChange={handleChange}
        className={styles.form}
      >
        <Form.Item className={styles.formItem} name="name">
          <AppInput
            data-test="custom_screen_page_modal_search_column"
            placeholder={'Search column by name'}
            className={styles.input}
            onPressEnter={handleOnPressEnter}
          ></AppInput>
        </Form.Item>
      </Form>
    </div>
  );
};
