import React from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as MailSvg } from 'src/assets/svg/mail.svg';

import styles from './FirstStep.module.css';

export const PasswordRecoveryFirstStepSuccess = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.successWrap}>
      <MailSvg />
      {t('password_recovery_success')}
    </div>
  );
};
