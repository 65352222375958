import { axiosGet, axiosPost, axiosErrors } from '../helpers/axios';

const loadSupportTopics = axiosGet('dictionaries/support-topics');
const requestSupport = axiosPost('notification/support-request');

export const loadSupportTopicsAsync = () => (dispatch) => {
  return loadSupportTopics()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const requestSupportAsync = (topic, message) => (dispatch) => {
  return requestSupport({
    topic,
    message
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};
