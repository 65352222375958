import React from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import styles from './AccountInvitations.module.css';

export const AccountInvitations = ({ profile, totalSentInvitations }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToInvite = () => {
    navigate('/invite');
  };

  return (
    <div className={styles.invitations}>
      <h3 className="page__subtitle">{t('account_invitations_subtitle')}</h3>
      <hr />
      <div className={styles.content}>
        <p>{t('account_invitations_text')}</p>
        <div className={styles.invitationsLeft}>
          <span>{t('account_invitations_left')}</span>
          <span className={styles.highlightedText}>
            {profile.config && profile.config.inviteLimit
              ? `${profile.config.inviteLimit - totalSentInvitations} / ${profile.config.inviteLimit}`
              : ''}
          </span>
        </div>
        <Button
          data-test="profile_page_send_invitation"
          onClick={navigateToInvite}
          className="mg-btn _blue"
          style={{ width: '273px' }}
        >
          {t('btn_send_invitation')}
        </Button>
      </div>
    </div>
  );
};
