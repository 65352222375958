import React, { useState, useCallback } from 'react';

import { Steps, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { changePasswordAsync } from 'src/app/api/auth';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { CustomDot } from 'src/app/components/steps/customDot';

import styles from './ChangePassword.module.css';
import { FormStep } from './components/formStep';
import { SuccessStep } from './components/successStep';

const { Step } = Steps;

export const ChangePassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);

  const handleSubmit = useCallback(
    (val) => {
      dispatch(changePasswordAsync(val.oldPassword, val.newPassword))
        .then(() => {
          setCurrent(current + 1);
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    },
    [dispatch, current]
  );

  const steps = [
    {
      id: 'Form step',
      content: <FormStep handleSubmit={handleSubmit} />
    },
    {
      id: 'Success step',
      content: <SuccessStep />
    }
  ];

  return (
    <div className="page">
      <Row>
        <Col span={24}>
          <h1 className="page__title-uppercase">{t('title_change_password')}</h1>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Steps progressDot={CustomDot} current={current}>
            {steps.map((item) => (
              <Step key={item.id} />
            ))}
          </Steps>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className={styles.stepsContent}>{steps[current].content}</div>
        </Col>
      </Row>
    </div>
  );
};
