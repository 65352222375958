import { axiosGet, axiosErrors, axiosPost } from '../helpers/axios';
import { setIsShowErrorMessageProfile } from '../slices/profileSlice';

const getProfile = axiosGet('account', { isNeedSpinner: false });
const deleteProfile = axiosPost('account/delete-account');
export const getProfileAsync = () => (dispatch) => {
  return getProfile()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProfile(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const sendNotificationDeleteAccountAsync = () => (dispatch) => {
  return deleteProfile({})
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const deleteProfileAsync = (hash) => (dispatch) => {
  const deleteProfile = axiosPost(`account/confirm-delete/${hash}`);
  return deleteProfile()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessageProfile(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const getSentInvitationsAsync = (index, limit, sortBy, sortDirection) => (dispatch) => {
  const getSentInvitations = axiosGet(
    `account/invites?index=${index || 0}&limit=${limit || 10}${sortBy ? `&order=${sortBy}` : ''}${
      sortDirection ? `&order=${sortDirection}` : ''
    }`
  );
  return getSentInvitations()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};
