const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;
    listContainer.style.minWidth = '200px';
    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);
    items.sort((a, b) => a.datasetIndex - b.datasetIndex);
    if (items[0].text === 'Equity, fact') {
      items.reverse();
    }

    items.forEach((item, i) => {
      if (item.text === undefined || item.text === null || item.text.length === 0) {
        item.hidden = true;
        return;
      }
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      // li.style.marginBottom = '10px';
      // li.style.marginLeft = '40px';

      // li.onclick = () => {
      //   const { type } = chart.config;
      //   if (type === 'pie' || type === 'doughnut') {
      //     // Pie and doughnut charts only have a single dataset and visibility is per item
      //     chart.toggleDataVisibility(item.index);
      //   } else {
      //     chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
      //   }
      //   chart.update();
      // };
      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = (options.colors && options.colors[item.datasetIndex]) || item.strokeStyle;
      boxSpan.style.borderColor = (options.colors && options.colors[item.datasetIndex]) || item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
      boxSpan.style.borderTopLeftRadius = 4 + 'px';
      boxSpan.style.borderBottomLeftRadius = 4 + 'px';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '16px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '7px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  }
};
