import React, { useMemo } from 'react';

import { AutoComplete, Button } from 'antd';

import { ReactComponent as CloseSvg } from 'src/assets/svg/close.svg';

import './styles.css';

const { Option } = AutoComplete;
export const AppAutocomplete = ({ result, placeholder, label, value, onChange, onClear, onSelect, ...rest }) => {
  const componentId = useMemo(() => Date.now().toString(16), []);
  const colorResult = (text) => {
    const index = text?.toLowerCase().indexOf(value?.toLowerCase());
    if (index !== -1) {
      return text.replaceAll(new RegExp(value.toLowerCase(), 'ig'), (elem) => {
        return `<span class="colored-result">${elem}</span>`;
      });
    }
    return text;
  };
  return (
    <div className="mg-autocomplete__wrap">
      <label htmlFor={componentId} className="mg-autocomplete__label">
        {label}
      </label>
      <AutoComplete
        dropdownClassName="mg-autocomplete-dropdown"
        placeholder={placeholder}
        className="mg-autocomplete"
        id={componentId}
        style={{ width: 200 }}
        onSearch={onChange}
        value={value}
        {...rest}
      >
        {result.map((res) => (
          <Option key={res.label} value={`${res.value}`}>
            <div
              dangerouslySetInnerHTML={{ __html: colorResult(res.label) }}
              onClick={() => onSelect(res.value, res)}
              style={{ width: '100%' }}
            ></div>
          </Option>
        ))}
      </AutoComplete>
      {value ? (
        <Button className={`mg-btn _text mg-autocomplete-close`} onClick={onClear}>
          <CloseSvg />
        </Button>
      ) : (
        ''
      )}
    </div>
  );
};
