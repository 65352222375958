import React from 'react';

import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { ChangePasswordForm } from '../form';

import styles from './FormStep.module.css';

export const FormStep = ({ handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col xs={{ order: 2 }} lg={{ order: 1 }}>
        <ChangePasswordForm onSubmit={handleSubmit} />
      </Col>
      <Col xs={{ order: 1 }} lg={{ order: 2 }}>
        <p className={styles.formRulesText}>{t('password_rules_message')}</p>
      </Col>
    </Row>
  );
};
