import React from 'react';

import styles from '../../Footer.module.css';

export const TextBlock = ({ title, text, italic = false, setInnerHTML = false }) => {
  return (
    <div className={styles.textBlock}>
      <p>
        {italic ? (
          <i>
            <b>{title}</b>
          </i>
        ) : (
          <b>{title}</b>
        )}
      </p>
      {setInnerHTML ? <p dangerouslySetInnerHTML={{ __html: text }}></p> : <p>{text}</p>}
    </div>
  );
};
