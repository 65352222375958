import React from 'react';

export const listOfNotificationsColumns = [
  {
    title: 'notifications_text_title',
    key: 'text',
    dataIndex: 'text',
    sorted: true,
    filtered: false,
    render: (title) => <span>{title}</span>,
    width: '40%'
  },
  // {
  //   title: 'creator_title',
  //   key: 'creator',
  //   dataIndex: 'creator',
  //   sorted: true,
  //   filtered: false,
  //   render: (moderator) => <span>{moderator?.email}</span>,
  //   width: '35%'
  // },
  // {
  //   title: 'members_title',
  //   key: 'membersAmount',
  //   dataIndex: 'membersAmount',
  //   sorted: true,
  //   filtered: false,
  //   render: (membersAmout) => <span>{membersAmout}</span>
  // },
  {
    title: 'created_title',
    key: 'createdAt',
    dataIndex: 'createdAt',
    sorted: true,
    filtered: false
  }
];

export const listOfPollsColumns = [
  {
    title: 'poll_name_title',
    key: 'title',
    dataIndex: 'title',
    sorted: true,
    filtered: false,
    render: (title) => <span>{title}</span>,
    width: '30%'
  },
  // {
  //   title: 'creator_title',
  //   key: 'creator',
  //   dataIndex: 'creator',
  //   sorted: true,
  //   filtered: false,
  //   render: (creator) => <span>{creator?.email}</span>,
  //   width: '30%'
  // },
  {
    title: 'questions_title',
    key: 'questionsAmount',
    dataIndex: 'questionsAmount',
    sorted: false,
    filtered: false,
    render: (membersAmount) => <span>{membersAmount}</span>
  },
  // {
  //   title: 'members_title',
  //   key: 'membersAmount',
  //   dataIndex: 'membersAmount',
  //   sorted: true,
  //   filtered: false,
  //   render: (membersAmount) => <span>{membersAmount}</span>
  // },
  {
    title: 'created_title',
    key: 'createdAt',
    dataIndex: 'createdAt',
    sorted: true,
    filtered: false
  }
];
