import React, { useState, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  loadAlertsListByViewAsync,
  deleteAlertAsyncModal,
  searchOfficerByNameAsync,
  createAlertAsyncModal
} from 'src/app/api/alerts';
import { AppSwitch } from 'src/app/components/form/switch';
import { AppSimpleTable } from 'src/app/components/table';
import { InsiderCompanyAlertModelAll } from 'src/app/models/alerts';
import { positionColumnsModal, alertView } from 'src/app/modules/account-alerts/consts';
import { definePersonPosition, defineTitleAlertColumn } from 'src/app/modules/account-alerts/helpers';
import { selectAlerts, selectInsidersAlertsTotal } from 'src/app/slices/alertsSlice';

const AppSimpleTableMemo = React.memo(AppSimpleTable);

const scrollValue = { y: 265 };

const SetPositionFormComponent = ({ companyCik, handleUpdateAlertsList, handleError }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [sort] = useState({ column: 'companyName', direction: 'ASC' });

  const [positionColumns, setPositionColumns] = useState(positionColumnsModal);
  const [companyInsidersByCik, setCompanyInsidersByCik] = useState([]);
  const [tableData, setTableData] = useState();
  const [accountInsidersAlerts, setAccountInsidersAlerts] = useState([]);
  const alerts = useSelector(selectAlerts);
  const accountInsidersAlertsTotal = useSelector(selectInsidersAlertsTotal);

  useEffect(() => {
    if (companyCik) {
      dispatch(
        searchOfficerByNameAsync(null, companyCik, {
          column: 'companyName',
          direction: 'ASC'
        })
      )
        .then((data) => {
          setCompanyInsidersByCik(data.items);
        })
        .catch((e) => {
          handleError(e);
        });
    }
  }, [companyCik, dispatch, handleError]);
  useEffect(() => {
    if (companyInsidersByCik.length) {
      dispatch(
        loadAlertsListByViewAsync(alertView.insider, 0, accountInsidersAlertsTotal, {
          column: 'companyName',
          direction: 'ASC'
        })
      )
        .then((data) => {
          const res = companyInsidersByCik.map(
            (elem) =>
              new InsiderCompanyAlertModelAll({
                ...elem,
                personPosition: i18n.t(definePersonPosition(elem.is_director, elem.is_officer))
              })
          );
          res.forEach((elem) => {
            const exist = data.items.find((e) => e.companyCik === elem.companyCik && e.personId === elem.personId);
            if (exist) {
              elem.alerts = [...exist.alerts];
            }
          });
          setAccountInsidersAlerts(res);
        })
        .catch((e) => {
          handleError(e);
        });
    }
  }, [accountInsidersAlertsTotal, companyInsidersByCik, dispatch, i18n, handleError]);

  const createPreparedData = useCallback(() => {
    const preparedData = companyInsidersByCik.map((el) => {
      const newEl = {
        id: companyCik + '_' + el.person_id || '0',
        companyCik: companyCik,
        personName: el.person?.person_name,
        personId: el.person_id,
        personPosition: t(definePersonPosition(el.is_director, el.is_officer)),
        events: 'text'
      };
      const isHasAccountAlert = accountInsidersAlerts.find(
        (accAlert) => accAlert.companyCik === companyCik && newEl.personId === accAlert.personId
      );
      if (isHasAccountAlert) {
        newEl.alerts = isHasAccountAlert.alerts;
        alerts.forEach((alert) => {
          const isHasAlert = isHasAccountAlert.alerts.find((accAlert) => accAlert.type === alert);
          if (isHasAlert) {
            newEl[alert] = true;
          } else {
            newEl[alert] = false;
          }
        });
      } else {
        alerts.forEach((alert) => {
          newEl[alert] = false;
        });
      }
      return newEl;
    });
    return preparedData;
  }, [companyInsidersByCik, accountInsidersAlerts, alerts, companyCik, t]);

  useEffect(() => {
    const preparedData = createPreparedData();
    setTableData(preparedData);
  }, [createPreparedData]);

  const sendDeleteRequest = useCallback(
    (id, alert) => {
      dispatch(deleteAlertAsyncModal(id))
        .then(() => {
          handleUpdateAlertsList('delete');
          setAccountInsidersAlerts((prev) => {
            const newState = structuredClone(prev);
            newState.forEach((e) => {
              if (e.personId === alert.personId) {
                const filter = e.alerts.filter((alerts) => alerts.id !== id);
                e.alerts = filter;
              }
            });
            return newState;
          });
        })
        .catch((e) => {
          handleError(e);
          setAccountInsidersAlerts((prev) => structuredClone(prev));
        });
    },
    [dispatch, handleUpdateAlertsList, handleError]
  );

  const deleteAlert = useCallback(
    (alert, alertType) => {
      const deletedAlertId = alert?.alerts.find((alert) => alert.type === alertType)?.id;
      sendDeleteRequest(deletedAlertId, alert);
    },
    [sendDeleteRequest]
  );

  const addAlert = useCallback(
    (alertType, alert) => {
      const schema = {
        companyCik: companyCik,
        personId: alert.personId
      };
      dispatch(createAlertAsyncModal(alertType, schema))
        .then((newAlert) => {
          handleUpdateAlertsList('add');
          setAccountInsidersAlerts((prev) => {
            const newState = structuredClone(prev);
            newState.forEach((e) => {
              if (e.personId === newAlert.data.personId) {
                e.alerts.push({ id: newAlert.id, type: newAlert.type });
              }
            });
            return newState;
          });
        })
        .catch((e) => {
          handleError(e);
          setAccountInsidersAlerts((prev) => structuredClone(prev));
        });
    },
    [dispatch, handleUpdateAlertsList, companyCik, handleError]
  );

  const handleChangeCheckboxEvent = useCallback(
    (val, alertType, alert) => {
      if (val) {
        addAlert(alertType, alert);
      } else {
        deleteAlert(alert, alertType);
      }
    },
    [addAlert, deleteAlert]
  );

  const switchCol = useCallback(
    (alert) => {
      return {
        title: t(defineTitleAlertColumn(alert)),
        key: alert,
        dataIndex: alert,
        sorted: false,
        filtered: false,
        sortTitle: 'alertStatus',
        editingDefault: true,
        sortValue: alert,
        editableCellComponent: AppSwitch,
        onChangeCell: handleChangeCheckboxEvent,
        width: '25%'
      };
    },
    [handleChangeCheckboxEvent, t]
  );

  useEffect(() => {
    if (alerts.length && positionColumnsModal.length >= positionColumns.length) {
      const switchCols = [];
      for (let i = 1; i < alerts.length; i++) {
        switchCols.push(switchCol(alerts[i]));
      }
      setPositionColumns((prev) => [...prev, ...switchCols]);
    }
  }, [alerts, switchCol, positionColumns]);
  return (
    <div className="set-alert _position">
      {positionColumns ? (
        // @ts-ignore
        <AppSimpleTableMemo sort={sort} listOfData={tableData} columns={positionColumns} scroll={scrollValue} />
      ) : null}
    </div>
  );
};

export const SetPositionForm = React.memo(SetPositionFormComponent);
