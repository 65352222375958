import React from 'react';

import { Divider, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { colorResult } from '../../helpers';
import { ReactComponent as ClearSvg } from 'src/assets/svg/clean.svg';

import styles from './SearchResults.module.css';

export const SearchResults = ({ selectedResults, onClean, value, onShowModal, selectedTabKey }) => {
  const { t } = useTranslation();

  if (selectedResults.length) {
    return (
      <div className={styles.wrap}>
        <div className={styles.titleWrap}>
          <h3 className={styles.title}>{t('searsh_results_title')}</h3>
          <Button data-test="alerts_page_clean_up" className={`mg-btn ${styles.cleanBtn}`} onClick={onClean}>
            <ClearSvg />
            {t('clear_alerts_btn')}
          </Button>
        </div>
        <p className={styles.yellowText}> {t('search_global')}</p>
        <Divider className={styles.divider} />
        <div className={styles.companies}>
          {selectedResults.map((comp) => {
            return (
              <div key={comp?.value} className={styles.company}>
                <span dangerouslySetInnerHTML={{ __html: colorResult(comp?.label) }}></span>
                {comp.isPerson && selectedTabKey !== '1' ? (
                  ''
                ) : (
                  <Button
                    data-test="alerts_page_set_alert"
                    className={`mg-btn _text ${styles.setBtn}`}
                    onClick={() => onShowModal(comp.label, comp.companyCik)}
                  >
                    {t('set_alert_btn')}
                  </Button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
