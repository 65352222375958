import React, { useMemo } from 'react';

import styles from '../../Footer.module.css';

export const TextListBlock = ({
  title,
  beforeText,
  afterText,
  listText,
  listItems,
  nestedItemIndex,
  nestedListText,
  nestedListItems,
  setInnerHTML = false,
  letterList = false
}) => {
  const list = useMemo(
    () =>
      listItems.map((item, i) =>
        nestedItemIndex === i ? (
          <li key={i}>
            {item}
            <TextListBlock listText={nestedListText} listItems={nestedListItems} />
          </li>
        ) : setInnerHTML ? (
          <li key={i} dangerouslySetInnerHTML={{ __html: item }}></li>
        ) : (
          <li key={i}>{item}</li>
        )
      ),
    [listItems, nestedItemIndex, nestedListItems, nestedListText, setInnerHTML]
  );

  return (
    <div className={styles.textBlock}>
      {title && (
        <p>
          <b>{title}</b>
        </p>
      )}
      {beforeText && (setInnerHTML ? <p dangerouslySetInnerHTML={{ __html: beforeText }}></p> : <p>{beforeText}</p>)}
      {listText && <p style={{ marginBottom: '0' }}>{listText}</p>}
      {letterList ? <ol>{list}</ol> : <ul>{list}</ul>}
      {afterText && (setInnerHTML ? <p dangerouslySetInnerHTML={{ __html: afterText }}></p> : <p>{afterText}</p>)}
    </div>
  );
};
