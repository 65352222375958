import React, { useCallback, useEffect, useState, useRef } from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { AppInputNumber } from 'src/app/components/form/input-number';
import { AppSelect } from 'src/app/components/form/select';
import { ReactComponent as CheckIcon } from 'src/assets/svg/company/check.svg';

import { unitOptions } from './consts.js';
import styles from './PointInput.module.css';

export const PointInput = ({ visible, onClose, onOk, pointInfo }) => {
  const { t } = useTranslation();

  const [pointValue, setPointValue] = useState();
  const [pointRatio, setPointRatio] = useState();
  const [isError, setIsError] = useState(false);

  const componentRef = useRef();

  useEffect(() => {
    setPointValue(
      pointInfo?.point.valueType === 'percent'
        ? pointInfo?.point.y.toFixed(4)
        : (pointInfo?.point.initialValue / pointInfo?.point.ratio).toFixed(3)
    );
    setPointRatio(pointInfo?.point.ratio);
  }, [pointInfo]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClose]);

  const handleChangeValue = useCallback(
    (value) => {
      setPointValue(value);
      if (
        (pointInfo?.point.minValue && value < pointInfo?.point.minValue[0]) ||
        (pointInfo?.point.maxValue && value > pointInfo?.point.maxValue[0])
      ) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    },
    [pointInfo?.point.maxValue, pointInfo?.point.minValue]
  );

  const handleSaveValue = useCallback(() => {
    if (isError) {
      return;
    }
    if (pointInfo?.point.valueType === 'currency') {
      const unitPoint = pointValue * pointRatio;
      onOk(pointInfo?.point, unitPoint, pointInfo?.datasetIndex);
    } else {
      onOk(pointInfo?.point, pointValue, pointInfo?.datasetIndex);
    }

    onClose();
  }, [isError, onClose, onOk, pointValue, pointInfo, pointRatio]);

  useEffect(() => {
    // 240 - element width
    const rightPosition = pointInfo?.screenXPosition + 30 + 240;
    const windowWidth = window.innerWidth;

    if (rightPosition >= windowWidth) {
      componentRef.current.style.left = pointInfo?.positionLeft - (rightPosition - windowWidth) - 50 + 'px';
    } else {
      componentRef.current.style.left = pointInfo?.positionLeft + 0 + 'px';
    }
  }, [pointInfo?.positionLeft, pointInfo?.screenXPosition]);

  return (
    <div
      ref={componentRef}
      className={`${styles.pointInput} ${!visible ? styles.hidden : ''}`}
      style={{ top: pointInfo?.positionTop + 18 + 'px' }}
    >
      <p className={styles.title}>{pointInfo?.point.graphicTitle}</p>
      <div className={styles.inputWrapper}>
        <AppInputNumber
          controls={false}
          min={null}
          value={pointValue}
          onChange={handleChangeValue}
          onPressEnter={handleSaveValue}
          isInvalid={isError}
        ></AppInputNumber>
        {pointInfo?.point.valueType === 'currency' ? (
          <AppSelect
            value={pointRatio}
            placeholder="Unit"
            options={unitOptions}
            onChange={(val) => {
              setPointRatio(val);
            }}
          />
        ) : (
          '%'
        )}
        <Button className="mg-btn" onClick={handleSaveValue} disabled={isError}>
          <CheckIcon />
        </Button>
      </div>
      <p className={`${styles.note} ${isError && styles.invalid}`}>{t('input_restriction_label')}</p>
    </div>
  );
};
